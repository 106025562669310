import { ContentItem } from '../../api/Content';
import { GlobalAppsettings } from '../../backbone/GlobalSettings';
import { GetAddressResult } from '../types/Booking';
import { SearchAddresses } from '../types/SearchAddresses';
import { TaxinetBookingValidation, TaxinetBookingValidationResponse } from '../types/TaxinetBookingValidation';

export class BookingApi
{
    public static async getSearchAddresses(
        searchAddresses : string
    )
    {
        const response = await fetch(GlobalAppsettings.BackEndBaseUrl + 'api/booking/SearchAddressesAtTaxinet',
             GlobalAppsettings.GetPostInit(searchAddresses))

        const data = (await response.json()) as SearchAddresses

        return data
    }

    public static async getAddressNumber(
        request : string
    )
    {
        const response = await fetch(GlobalAppsettings.BackEndBaseUrl + 'api/booking/GetAdressesFromTaxinet',
             GlobalAppsettings.GetPostInit(request))
        const data = (await response.json()) as GetAddressResult

        return data
    }

    public static async cancelUnpaidBooking(
        request : string
    )
    {
        const response = await fetch(GlobalAppsettings.BackEndBaseUrl + 'api/booking/CancelUnpaidBooking',
             GlobalAppsettings.GetPostInit(request))

        const data = (await response.json()) as boolean

        return data
    }

    public static async cancelPaidBooking(
        request : string
    )
    {
        const response = await fetch(GlobalAppsettings.BackEndBaseUrl + 'api/booking/CancelPaidBooking',
             GlobalAppsettings.GetPostInit(request))

        const data = (await response.json()) as boolean

        return data
    }




    public static async CheckTaxinetBookingStatus(
        request : TaxinetBookingValidation
    )
    {
        const response = await fetch(GlobalAppsettings.BackEndBaseUrl + 'api/booking/TaxinetBookingStatus',
             GlobalAppsettings.GetPostInit(request))
        const data = (await response.json()) as TaxinetBookingValidationResponse

        return data
    }
    





    // public static async CheckBookingValidationStatus(
    //     pickup : string
    // )
    // {
    //     const response = await fetch(GlobalAppsettings.BackEndBaseUrl + 'api/booking/CheckBookingValidationStatus',
    //          GlobalAppsettings.GetPostInit(pickup))

    //     const data = (await response.json()) as boolean

    //     return data
    // }

    // public static async CheckBookingApplicationStatus()
    // {
    //     const response = await fetch(GlobalAppsettings.BackEndBaseUrl + 'api/booking/CheckBookingApplicationStatus',
    //          GlobalAppsettings.GetRequestInit())

    //     const data = (await response.json()) as boolean

    //     return data

    // }

}

