import * as React from "react";
import { Link } from "react-router-dom";
import * as App from "pubsub-js";
import { UserApi } from "../webparts/api/GetUserApi";
import { BookingApi } from "../webparts/api/BookingApi";
import ModalBackButton from "../components/ModalBackButton";

import "../webparts/styles/InfoPanel.css";
import "./panel.css";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export interface InfoPanelState {
  modal: boolean;
  showBackButton: boolean;
}

export interface InfoPanelProps {
  title?: string;
  description?: string;
  header?: React.ReactNode;
  color?: string;
  cancelButtonLabel?: string;
  modalPanelOpen?: string;
  showHeader?: boolean;
  position?: string;
  onClosed?: () => void;
  showCancelButton?: boolean;
  showCancelPaymentButton?: boolean;
  showBackWithoutCancelBooking?: boolean;
  showBackAndRefresh?: boolean;
}

export class InfoPanel extends React.Component<InfoPanelProps, InfoPanelState> {
  constructor(props: any) {
    super(props);

    this.state = {
      modal: false,
      showBackButton: false,
    };

    this.close = this.close.bind(this);
  }

  public async componentDidMount() {
    if (this.props.modalPanelOpen != undefined)
      App.subscribe(this.props.modalPanelOpen, this.open.bind(this));
  }

  public componentWillUnmount() {
    App.unsubscribe(this.open.bind(this));
  }

  open() {
    //disabel scroll on body
    document.body.style.position = "fixed";
    document.body.style.top = `-${window.scrollY}px`;
    //Load data
    this.setState({
      modal: true,
    });
  }

  close() {
    this.setState({
      modal: false,
    });
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }

  async cancelBookingHandler(paid : boolean) {
    const user = await UserApi.getCurrentProfile();
    const booking = user.userProfile.bookings[0];
    if(!booking) {
    this.close();
    return;
    }
    const bookingId = booking.bookingId;


    if(!paid)
    {
      const success = await BookingApi.cancelUnpaidBooking(bookingId);
      if(success)
      {
      }
      else{
      }
      this.close();
    }
    else
    {
      const success = await BookingApi.cancelPaidBooking(bookingId);
      if(success)
      {
      }
      else{
      }
      this.close();
    }
  
  }

  render() {
    let showHeader = true;

    if (this.props.showHeader != undefined) {
      showHeader = this.props.showHeader;
    }

    const childrenWithProp = React.Children.map(
      this.props.children,
      (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { onClick: this.close });
        }
      }
    );

    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          // toggle={this.closeModal}
          contentClassName="modal-panel-content yc-panel-dynamic"
          modalClassName={this.props.position}
          onClosed={this.props.onClosed}
        >
          {showHeader ? (
            <div className="yc-navbar-panel modal-header">
              <div
                className="p-2 flex-grow-1 bd-highlight"
                style={
                  this.props.color
                    ? {
                        borderLeft: "10px solid " + this.props.color,
                        marginLeft: "-16px",
                      }
                    : {}
                }
              >
                {this.props.header ? (
                  this.props.header
                ) : (
                  <div>
                    <h1>{this.props.title}</h1>
                    <div>{this.props.description}</div>
                  </div>
                )}
              </div>
              <div className="p-2 bd-highlight">
                <button
                  type="button"
                  onClick={this.close}
                  className="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true"></span>
                </button>
              </div>
            </div>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <ModalBody style={{ overflowY: "auto", overflowX: "hidden" }}>
            {/* {childrenWithProp} */}
            {this.props.children}
          </ModalBody>
          <div>
          {(this.props.showCancelButton === undefined
            ? true
            : this.props.showCancelButton) && (
            <ModalFooter>
              <ModalBackButton
                onClick={() => this.cancelBookingHandler(false)}
                name="Tillbaka"
              />

              <div style={{ display: "flex", flex: 1 }} />
              {/* <button className="btn btn-link" onClick={() => this.cancelBookingHandler(false)}>
                Tillbaka
              </button> */}
            </ModalFooter>
          )}
          </div>
          <div>
          {(this.props.showCancelPaymentButton === undefined
            ? true
            : this.props.showCancelPaymentButton) && (
            <ModalFooter>
              <ModalBackButton
                onClick={() => this.cancelBookingHandler(true)}
                name="Tillbaka"
              />

              <div style={{ display: "flex", flex: 1 }} />
              {/* <button className="btn btn-link" onClick={() => this.cancelBookingHandler(true)}>
                Avbryt Bokning
              </button> */}
            </ModalFooter>
          )}
          </div>
          <div>
          {(this.props.showBackWithoutCancelBooking === undefined
            ? true
            : this.props.showBackWithoutCancelBooking) && (
            <ModalFooter>
              <ModalBackButton
                onClick={() => this.close()}
                name="Tillbaka"
              />

              <div style={{ display: "flex", flex: 1 }} />
              {/* <button className="btn btn-link" onClick={() => this.close()}>
                Tillbaka
              </button> */}
            </ModalFooter>
          )}
          </div>
          {/* <div>
          {(this.props.showBackAndRefresh === undefined
            ? true
            : this.props.showBackAndRefresh) && (
            <ModalFooter>
              <button className="btn btn-link" onClick={() => this.closeAndRefreshWebsite()}>
                Tillbaka(återställ sida)
              </button>
            </ModalFooter>
          )}
          </div> */}
        </Modal>
      </div>
    );
  }
}
