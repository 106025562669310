import * as base64 from "base-64";
import * as React from "react";

export class GlobalAppsettings {
  protected static api_key: string = "*l1^-;L^p1|G%956n_*Q520b";
  static LanguageId: string = "sv";
  static AppId: string = "tag.web.parts";
  static tokenHeader: string = "tx-ref-id";

  static GetApiHeaders(): HeadersInit {
    return new Headers({
      Authorization: `Basic ${base64.encode(
        `API-TOKEN:${GlobalAppsettings.api_key}`
      )}`,
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    });
  }

  static GetRequestInit(): RequestInit {
    return {
      // credentials: "same-origin",
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
  }

  static GetPostInit(data: any, reCaptchaToken?: string): RequestInit {
    let token = "";
    let t = sessionStorage.getItem(GlobalAppsettings.tokenHeader);
    // //console.log("token: " + t);
    if (t != undefined) {
      token = t;
      let theader = GlobalAppsettings.tokenHeader;
      return {
        // credentials: "same-origin",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tx-ref-id": token,
          "re-captcha-token": reCaptchaToken || ""
        },
        body: JSON.stringify(data),
      };
    } else {
      console.info("get-post-init " + GlobalAppsettings.BackEndBaseUrl);
      return {
        // credentials: "same-origin",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Request-Method": "POST",
          "Access-Control-Request-Headers": "tx-ref-id",
          "re-captcha-token": reCaptchaToken || ""
        },
        body: JSON.stringify(data),
      };
    }
  }

  static SetToken(token: string) {
    if (token.length > 0) {
      sessionStorage.setItem(GlobalAppsettings.tokenHeader, token);
    }
  }

  /**
   * Configuration
   *
   *
   */

  static IsDevelopment: boolean = false;

  // static BackEndBaseUrl: string = "/";

  //static BackEndBaseUrl: string = "https://taxi-gbg-webparts-prod-stage.azurewebsites.net/";

  static BackEndBaseUrl: string = "https://taxi-gbg-webparts-prod.azurewebsites.net/";
  
  // static BackEndBaseUrl: string = "https://taxi-gbg-webparts-dev.azurewebsites.net/";
}
