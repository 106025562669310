import React, { Component, useReducer } from "react";
import {
  Container,
  Button,
  Fade,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  ButtonGroup,
} from "reactstrap";

import * as App from "pubsub-js";
import { LanguageContext, Language } from "../backbone/Language";
import { GlobalAppsettings } from "../backbone/GlobalSettings";
import { Loading } from "../components/base/Loading";
import { InfoPanel } from "../panels/InfoPanel";
import { ModalPanel } from "../panels/ModalPanel";
import { BookingApi } from "./api/BookingApi";
import { GetPriceApi } from "./api/GetPriceApi";
import momenttz from "moment-timezone";

import { BookingComponent } from "./components/BookingComponent";

import {
  SearchAddresses,
  AddressFromDetails,
  FromAddressWithPlaceId,
  SearchResult,
  AddressToDetails,
  ToAddressWithPlaceId,
} from "./types/SearchAddresses";
import { TripValidateResponse } from "./types/TripValidate";
import {
  GetPrice,
  GetPriceRequestModel,
  PickupAddressInfo,
} from "./types/GetPrice";
// import { debounce, split } from "lodash";
import { CreateBookingResponse } from "./types/CreateBookingResponse";
import { CreateBookingRequest } from "./types/CreateBookingRequest";

import moment, { relativeTimeRounding } from "moment";

import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";

import "./styles/Booking.css";
import { BaseResultResponse } from "./types/BaseResultResponse";
import { ValidationUtils } from "./utils/ValidationUtils";

export interface BookingProps {
  customData: string;
}

export interface BookingState {
  loading: boolean;
  loadingBooking: boolean;
  isDirty: boolean;
  fromValid: boolean;
  fromInValid: boolean;
  toValid: boolean;
  toInValid: boolean;
  from: string;
  to: string;
  time: string;
  timeMin: string;
  timeMax: string;
  date: string;
  dateMin: string;
  dateMax: string;
  priceLoading: boolean;
  showPrice: boolean;
  price: number;
  showBookBtn: boolean;
  showToResult: boolean;
  showFromResult: boolean;
  searchFrom: boolean;
  searchTo: boolean;
  travelNow: boolean;
  travelLater: boolean;
  validUser: boolean;
  verifyMobile: boolean;
  mobileValid: boolean;
  mobileNum: string;
  code: string;
  searchResult: SearchResult;
  addressResult: SearchAddresses;
  addressFromDetails: AddressFromDetails;
  fromAddressWithPlaceId: FromAddressWithPlaceId;
  addressToDetails: AddressToDetails;
  toAddressWithPlaceId: ToAddressWithPlaceId;
  cursor: number;
  getPriceRequestModel: GetPriceRequestModel;
  errorMessage: string;
  codeFirstDigit: string;
  codeSecondDigit: string;
  codeThirdDigit: string;
  codeFourthDigit: string;
  isAirport: boolean;
  inValidFromMessage: string;
  inValidToMessage: string;
  administrationFee: number;
  bookingTypes: string[];
  airportFee: number;
  isDateOpen: boolean;
  isTimeOpen: boolean;
  dateValid: boolean;
  dateInvalid: boolean;
  timeValid: boolean;
  timeInvalid: boolean;
}

export class Booking extends React.Component<BookingProps, BookingState> {
  static displayName = Booking.name;
  static contextType = LanguageContext;

  constructor(props: BookingProps) {
    super(props);

    this.state = {
      loading: false,
      isDirty: false,
      fromValid: false,
      fromInValid: false,
      toValid: false,
      toInValid: false,
      from: "",
      to: "",
      date: "",
      dateMin: "",
      dateMax: "",
      time: "",
      timeMin: "",
      timeMax: "",
      price: 0,
      priceLoading: false,
      showPrice: false,
      showBookBtn: true,
      showFromResult: false,
      showToResult: false,
      searchFrom: false,
      searchTo: false,
      travelNow: true,
      travelLater: false,
      loadingBooking: false,
      validUser: false,
      verifyMobile: false,
      mobileValid: false,
      mobileNum: "",
      code: "",
      searchResult: {} as SearchResult,
      addressResult: {
        success: false,
        errorMessage: "",
        results: [],
      } as SearchAddresses,
      addressFromDetails: { fromAddressWithPlaceId: [] } as AddressFromDetails,
      fromAddressWithPlaceId: {
        id: "",
        googlePlaceId: "",
        addressDetails: [],
      } as FromAddressWithPlaceId,
      addressToDetails: { toAddressWithPlaceId: [] } as AddressToDetails,
      toAddressWithPlaceId: {
        id: "",
        googlePlaceId: "",
        addressDetails: [],
      } as ToAddressWithPlaceId,
      cursor: 0,
      getPriceRequestModel: {
        userId: "",
        pickUpTime: "",
        pickup: { title: "", lat: "", long: "", GooglePlaceId: "" },
        dropOff: { title: "", lat: "", long: "", GooglePlaceId: "" },
        numberOfPassengers: 0,
        via: null,
        carType: 0,
        extraServices: null,
        message: null,
        pickupTimeASAP: true,
        offeringId: "",
      } as GetPriceRequestModel,
      errorMessage: "",
      codeFirstDigit: "",
      codeSecondDigit: "",
      codeThirdDigit: "",
      codeFourthDigit: "",
      isAirport: false,
      inValidFromMessage: "",
      inValidToMessage: "",
      administrationFee: 12,
      airportFee: 0,
      bookingTypes: [],
      isDateOpen: false,
      isTimeOpen: false,
      dateValid: false,
      dateInvalid: true,
      timeValid: false,
      timeInvalid: true,
    };
  }

  

  componentDidMount() {
    // Validate date
    const dateNow = new Date();
    const newDateNow = moment(dateNow).format("YYYY-MM-DD");
    const dateNowPlusOneYear: Date = new Date(
      dateNow.setFullYear(dateNow.getFullYear() + 1)
    );
    const newDateNowPlusOneYear =
      moment(dateNowPlusOneYear).format("YYYY-MM-DD");
    this.setState({ dateMin: newDateNow, dateMax: newDateNowPlusOneYear });

    let newDateTime = new Date();

    const newDateTimeLocaleString = newDateTime.toLocaleString();
    const newDateTimeISOString = newDateTime.toISOString();

    const newDate = newDateTime.toLocaleString().split(" ")[0];
    const newTime = newDateTime.toLocaleString().split(" ")[1];
    const newTimeSplitA = newTime.split(".")[0];
    const newTimeSplitB = newTimeSplitA.split(":");
    const newTimeString = newTimeSplitB[0] + ":" + newTimeSplitB[1];

    this.forceUpdate();

  }

  async onFromChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    this.setState({
      from: value,
      fromInValid: false,
      inValidFromMessage: "",
    });

    if (value.length > 2) {
      const response = await BookingApi.getSearchAddresses(value);
      let updatedAddressDetails = {} as AddressFromDetails;

      response.results.forEach((element) => {
        const myArray = element.streetName.split(",");
        let fromAddressWithPlaceId = {
          id: new Date().valueOf().toString(),
          googlePlaceId: element.googlePlaceId,
          addressDetails: myArray,
        } as FromAddressWithPlaceId;
        if (!updatedAddressDetails.fromAddressWithPlaceId) {
          updatedAddressDetails.fromAddressWithPlaceId = [];
        }
        updatedAddressDetails.fromAddressWithPlaceId.push(
          fromAddressWithPlaceId
        );
      });
      this.setState({
        addressFromDetails: updatedAddressDetails,
      });

      this.setState({
        showFromResult: true,
        fromValid: false,
      });
    }

    if (value.length < 3) {
      this.setState({
        showFromResult: false,
        fromValid: false,
      });
    }
  }

  async onToChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    this.setState({
      to: value,
      toInValid: false,
      inValidToMessage: "",
    });

    if (value.length > 2) {
      const response = await BookingApi.getSearchAddresses(value);
      let updatedAddressDetails = {} as AddressToDetails;

      response.results.forEach((element) => {
        const myArray = element.streetName.split(",");
        let toAddressWithPlaceId = {
          id: new Date().valueOf().toString(),
          googlePlaceId: element.googlePlaceId,
          addressDetails: myArray,
        } as ToAddressWithPlaceId;
        if (!updatedAddressDetails.toAddressWithPlaceId) {
          updatedAddressDetails.toAddressWithPlaceId = [];
        }
        updatedAddressDetails.toAddressWithPlaceId.push(toAddressWithPlaceId);
      });
      this.setState({
        addressToDetails: updatedAddressDetails,
      });

      this.setState({
        showToResult: true,
        toValid: false,
      });
    }

    if (value.length < 3) {
      this.setState({
        showToResult: false,
        toValid: false,
      });
    }
  }

  onDateChange(event: any) {
    this.setState({ date: event.target.value });

    const dateNow = new Date();
    const newDateNow = moment(dateNow).format("YYYY-MM-DD");
    const dateNowPlusOneYear: Date = new Date(
      dateNow.setFullYear(dateNow.getFullYear() + 1)
    );
    const newDateNowPlusOneYear =
      moment(dateNowPlusOneYear).format("YYYY-MM-DD");
    this.setState({ dateMin: newDateNow, dateMax: newDateNowPlusOneYear, dateValid: true, dateInvalid: false });

    this.getPrice(
      this.state.fromValid,
      true,
      this.state.getPriceRequestModel.pickup.GooglePlaceId,
      this.state.getPriceRequestModel.dropOff.GooglePlaceId,
      event.target.value,
      this.state.time,
      this.state.travelNow,
      true,
      this.state.timeValid
    );
  }

  onTimeChange(event: any) {
    this.setState({ time: event.target.value, timeValid: true, timeInvalid: false });

    this.getPrice(
      this.state.fromValid,
      true,
      this.state.getPriceRequestModel.pickup.GooglePlaceId,
      this.state.getPriceRequestModel.dropOff.GooglePlaceId,
      this.state.date,
      event.target.value,
      this.state.travelNow,
      this.state.dateValid,
      true
    );
  }

  onMobileChange(event: any) {
    this.setState({ mobileNum: event.target.value, mobileValid: true });
  }

  onFirstDigitChange(e: any) {
    this.setState({ codeFirstDigit: e.target.value });
  }
  onSecondDigitChange(e: any) {
    this.setState({ codeSecondDigit: e.target.value });
  }
  onThirdDigitChange(e: any) {
    this.setState({ codeThirdDigit: e.target.value });
  }
  onFourthDigitChange(e: any) {
    this.setState({ codeFourthDigit: e.target.value });
  }

  async onAddFromAddress(address: string, addressInfo: FromAddressWithPlaceId) {
    this.setState({ from: address, showFromResult: false });

    const addressDetails = await BookingApi.getAddressNumber(
      addressInfo.googlePlaceId
    );

    let data = this.state.getPriceRequestModel;
    data.pickup.GooglePlaceId = addressInfo.googlePlaceId;
    data.pickup.title = address;
    this.setState({ getPriceRequestModel: data });

    if (addressDetails.type !== "Street") {
      this.setState({ fromValid: true, fromInValid: false });
    } else if (addressDetails.type === "Street") {
      this.setState({
        inValidFromMessage: "Vänligen ange gatunummer.",
        fromInValid: true,
      });
    }

    this.getPrice(
      true,
      this.state.toValid,
      addressInfo.googlePlaceId,
      this.state.getPriceRequestModel.dropOff.GooglePlaceId,
      this.state.date,
      this.state.time,
      this.state.travelNow,
      this.state.dateValid,
      this.state.timeValid
    );

    if (
      addressInfo.googlePlaceId === "ChIJa4Q3VAH6T0YRs4SWJS82YCQ" ||
      addressInfo.googlePlaceId === "ChIJHUFIwyGMT0YRm1LHNlZoCnE" ||
      addressInfo.googlePlaceId === "ChIJvQ0uGk6KT0YRmxmx-827XhA"
    ) {
      this.setState({ isAirport: true });
    } else {
      this.setState({ isAirport: false });
    }
  }

  async onAddToAddress(address: string, addressInfo: ToAddressWithPlaceId) {
    this.setState({ to: address, showToResult: false });

    const addressDetails = await BookingApi.getAddressNumber(
      addressInfo.googlePlaceId
    );

    let data = this.state.getPriceRequestModel;
    data.dropOff.GooglePlaceId = addressInfo.googlePlaceId;
    data.dropOff.title = address;
    this.setState({ getPriceRequestModel: data });

    if (addressDetails.type !== "Street") {
      this.setState({ toValid: true });
    } else if (addressDetails.type === "Street") {
      this.setState({
        inValidToMessage: "Vänligen ange gatunummer.",
        toInValid: true,
      });
    }

    this.getPrice(
      this.state.fromValid,
      true,
      this.state.getPriceRequestModel.pickup.GooglePlaceId,
      addressInfo.googlePlaceId,
      this.state.date,
      this.state.time,
      this.state.travelNow,
      this.state.dateValid,
      this.state.timeValid
    );
  }

  async onFromKeyDownHandler(e: any) {
    const { cursor, addressFromDetails } = this.state;

    if (!addressFromDetails?.fromAddressWithPlaceId) return;
    else {
      if (e.keyCode === 38 && cursor > 0) {
        this.setState((prevState) => ({
          cursor: prevState.cursor - 1,
        }));
      } else if (
        e.keyCode === 40 &&
        cursor < addressFromDetails.fromAddressWithPlaceId.length - 1
      ) {
        this.setState((prevState) => ({
          cursor: prevState.cursor + 1,
        }));
      } else if (e.keyCode === 13) {
        const address = addressFromDetails.fromAddressWithPlaceId[cursor];

        const addressDetails = await BookingApi.getAddressNumber(
          address.googlePlaceId
        );
        this.setState({
          showFromResult: false,
          from: address.addressDetails[0] + "," + address.addressDetails[1],
        });

        let data = this.state.getPriceRequestModel;
        data.pickup.GooglePlaceId = address.googlePlaceId;
        data.pickup.title =
          address.addressDetails[0] + "," + address.addressDetails[1];
        this.setState({ getPriceRequestModel: data });

        if (addressDetails.type !== "Street") {
          this.setState({ fromValid: true });
        } else if (addressDetails.type === "Street") {
          this.setState({
            inValidFromMessage: "Vänligen ange gatunummer.",
            fromInValid: true,
          });
        }
        this.getPrice(
          true,
          this.state.toValid,
          address.googlePlaceId,
          this.state.getPriceRequestModel.dropOff.GooglePlaceId,
          this.state.date,
          this.state.time,
          this.state.travelNow,
          this.state.dateValid,
          this.state.timeValid
        );

        if (
          address.googlePlaceId === "ChIJa4Q3VAH6T0YRs4SWJS82YCQ" ||
          address.googlePlaceId === "ChIJHUFIwyGMT0YRm1LHNlZoCnE" ||
          address.googlePlaceId === "ChIJvQ0uGk6KT0YRmxmx-827XhA"
        ) {
          this.setState({ isAirport: true });
        } else {
          this.setState({ isAirport: false });
        }
      }
    }
  }

  async onToKeyDownHandler(e: any) {
    const { cursor, addressToDetails } = this.state;

    if (!addressToDetails?.toAddressWithPlaceId) return;
    else {
      if (e.keyCode === 38 && cursor > 0) {
        this.setState((prevState) => ({
          cursor: prevState.cursor - 1,
        }));
      } else if (
        e.keyCode === 40 &&
        cursor < addressToDetails.toAddressWithPlaceId.length - 1
      ) {
        this.setState((prevState) => ({
          cursor: prevState.cursor + 1,
        }));
      } else if (e.keyCode === 13) {
        const address = addressToDetails.toAddressWithPlaceId[cursor];

        const addressDetails = await BookingApi.getAddressNumber(
          address.googlePlaceId
        );
        this.setState({
          showToResult: false,
          to: address.addressDetails[0] + "," + address.addressDetails[1],
        });

        let data = this.state.getPriceRequestModel;
        data.dropOff.GooglePlaceId = address.googlePlaceId;
        data.dropOff.title =
          address.addressDetails[0] + "," + address.addressDetails[1];
        this.setState({ getPriceRequestModel: data });

        if (addressDetails.type !== "Street") {
          this.setState({ toValid: true });
        } else if (addressDetails.type === "Street") {
          this.setState({
            inValidToMessage: "Vänligen ange gatunummer.",
            toInValid: true,
          });
        }
        this.getPrice(
          this.state.fromValid,
          true,
          this.state.getPriceRequestModel.pickup.GooglePlaceId,
          address.googlePlaceId,
          this.state.date,
          this.state.time,
          this.state.travelNow,
          this.state.dateValid,
          this.state.timeValid
        );
      }
    }
  }

  onClickShowFromAddresses(e: boolean) {
    this.setState({
      showFromResult: e,
    });
  }

  onClickShowToAddresses(e: boolean) {
    this.setState({
      showToResult: e,
    });
  }


  onClickTravelNow(){
    this.setState({ travelNow: true, travelLater: false })

    this.getPrice(
      this.state.fromValid,
      this.state.toValid,
      this.state.getPriceRequestModel.pickup.GooglePlaceId,
      this.state.getPriceRequestModel.dropOff.GooglePlaceId,
      this.state.date,
      this.state.time,
      true,
      true,
      true
    );
  }


  async getPrice(
    fromValid: boolean,
    toValid: boolean,
    fromPlaceId: string,
    toPlaceId: string,
    date: string,
    time: string,
    travelNow: boolean,
    dateValid: boolean,
    timeValid: boolean,
  ) {
    if (this.state.fromValid && this.state.toValid) {
      this.setState({ priceLoading: true });

      if (fromValid === true && toValid === true) {
        if (travelNow === false) {
          if (date !== "" && time !== "" && dateValid === true && timeValid === true) {

            const dateTimeString = date + "T" + time;
            const dateTimeFormated = momenttz(dateTimeString)
              .tz("Europe/Stockholm", true)
              .format();
            const dateTimeFormatedString = dateTimeFormated.toString();
            const pickup = {
              title: null,
              lat: null,
              long: null,
              GooglePlaceId: fromPlaceId,
            };
            const dropoff = {
              title: null,
              lat: null,
              long: null,
              GooglePlaceId: toPlaceId,
            };
            const response = await GetPriceApi.getPriceRequest(
              null,
              dateTimeFormatedString,
              pickup,
              dropoff,
              3,
              null,
              0,
              null,
              null,
              false,
              null
            );

            const priceRequest = this.state.getPriceRequestModel;
            priceRequest.pickUpTime = dateTimeFormatedString;

            if (!response) return;
            this.setState({
              price: response.fixedPrice,
              priceLoading: false,
              getPriceRequestModel: priceRequest,
            });
            const responsePriceInfo = response.price;
            if (responsePriceInfo.additionalFee != null) {
              if (this.state.isAirport) {
                this.setState({ airportFee: response.additionalFee });
              } else {
                this.setState({ airportFee: 0 });
              }
            }

            if (this.state.bookingTypes.length == 0) {
              if (responsePriceInfo.bookingTypes != undefined)
                this.setState({
                  bookingTypes: responsePriceInfo.bookingTypes,
                });
            }
          } else {
            return;
          }
        } else if (travelNow === true) {
          this.setState({ priceLoading: true });
          const dateTime = new Date();
          const dateTimeFormated = momenttz(dateTime)
            .tz("Europe/Stockholm", true)
            .format();
          const dateTimeString = dateTimeFormated.toString();
          const pickup = {
            title: null,
            lat: null,
            long: null,
            GooglePlaceId: fromPlaceId,
          };
          const dropoff = {
            title: null,
            lat: null,
            long: null,
            GooglePlaceId: toPlaceId,
          };
          const response = await GetPriceApi.getPriceRequest(
            null,
            dateTimeString,
            pickup,
            dropoff,
            3,
            null,
            0,
            null,
            null,
            true,
            null
          );
          const priceRequest = this.state.getPriceRequestModel;
          priceRequest.pickUpTime = dateTimeString;

          if (!response) return;
          this.setState({
            price: response.fixedPrice,
            priceLoading: false,
            getPriceRequestModel: priceRequest,
          });
          const responsePriceInfo = response.price;
          if (responsePriceInfo.additionalFee != null) {
            if (this.state.isAirport) {
              this.setState({ airportFee: response.additionalFee });
            } else {
              this.setState({ airportFee: 0 });
            }
          }

          if (this.state.bookingTypes.length == 0) {
            if (responsePriceInfo.bookingTypes != undefined)
              this.setState({
                bookingTypes: responsePriceInfo.bookingTypes,
              });
          }
        }
      } else {
        return;
      }
    }
  }

  checkIfUndefined(text: string) {
    if (text !== undefined) {
      return ", " + text;
    }
  }

  onSendMobileNumber() {
    fetch(
      GlobalAppsettings.BackEndBaseUrl + "api/Trip/Login",
      GlobalAppsettings.GetPostInit({ mobile: this.state.mobileNum })
    )
      .then((response) => response.json() as Promise<BaseResultResponse>)
      .then((data) => {
        this.setState({ errorMessage: data.errorMessage });
      })
      .catch((reason) => {});
  }

  onValidateLogin() {
    const code =
      this.state.codeFirstDigit +
      this.state.codeSecondDigit +
      this.state.codeThirdDigit +
      this.state.codeFourthDigit;

    fetch(
      GlobalAppsettings.BackEndBaseUrl + "api/Trip/Validate",
      GlobalAppsettings.GetPostInit({ code: code })
    )
      .then((response) => response.json() as Promise<TripValidateResponse>)
      .then((data) => {
        this.setState({ errorMessage: data.errorMessage });
      })
      .catch((reason) => {});
  }

  render() {
    const { cursor } = this.state;

    return (
      <LanguageContext.Consumer>
        {(lang) => (
          <React.Fragment>
            <Container style={{ backgroundColor: "#ffcb05" }}>
              <Form>
                <Row>
                  <Col md="4" className="p-3 align-content-center" style={{}}>
                    <h2
                      className="heading textunderline m"
                      style={{
                        borderBottom: "0.07em solid #fff",
                        maxWidth: "137px",
                      }}
                    >
                      Boka taxi
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col className="m-2">
                    <Dropdown
                      toggle={() => this.selectTo(false)}
                      isOpen={this.state.showFromResult}
                    >
                      <DropdownToggle data-toggle="dropdown" tag="span">
                        <Input
                          id="fromInput"
                          name="from"
                          placeholder="Från"
                          type="text"
                          valid={this.state.fromValid}
                          invalid={this.state.fromInValid}
                          value={this.state.from}
                          onChange={(event) => this.onFromChange(event)}
                          autoComplete="off"
                          // onFocus={() => this.onClickShowFromAddresses(true)}
                          onKeyDown={this.onFromKeyDownHandler.bind(this)}
                        />
                      </DropdownToggle>
                      <DropdownMenu
                        container="body"
                        className="search-address-dropdown-menu search-from-address-list"
                        style={{
                          paddingLeft: 0,
                          paddingRight: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                          minWidth: "280px",
                          minHeight: "180px",
                          top: "100%",
                        }}
                        positionFixed={true}
                      >
                        {this.state.addressFromDetails.fromAddressWithPlaceId?.map(
                          (item, i) => {
                            return (
                              <div key={i}>
                                <Row
                                  onClick={() =>
                                    this.onAddFromAddress(
                                      item.addressDetails[0] +
                                        "," +
                                        item.addressDetails[1],
                                      item
                                    )
                                  }
                                  className={
                                    cursor === i
                                      ? "active search-address-dropdown-menu-row"
                                      : "not-active search-address-dropdown-menu-row"
                                  }
                                >
                                  <Col className="search-address-col-icon">
                                    <i
                                      className="fa-thin fa-location-pin"
                                      style={{
                                        fontSize: "1.4em",
                                        marginRight: "0",
                                      }}
                                    ></i>
                                  </Col>
                                  <Col className="search-address-col-textbox">
                                    <div className="search-address-streetname">
                                      {item.addressDetails[0]}
                                    </div>
                                    <div className="search-address-city">
                                      {item.addressDetails[1]}
                                      {this.checkIfUndefined(
                                        item.addressDetails[2]
                                      )}
                                      {this.checkIfUndefined(
                                        item.addressDetails[3]
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            );
                          }
                        )}
                      </DropdownMenu>
                    </Dropdown>
                    <div className="booking-error-message">
                      {this.state.inValidFromMessage}
                    </div>
                  </Col>
                  <Col className="m-2">
                    <Dropdown
                      toggle={() => this.selectTo(false)}
                      isOpen={this.state.showToResult}
                    >
                      <DropdownToggle data-toggle="dropdown" tag="span">
                        <Input
                          id="toInput"
                          name="to"
                          placeholder="Till"
                          type="text"
                          valid={this.state.toValid}
                          invalid={this.state.toInValid}
                          value={this.state.to}
                          onChange={(event) => this.onToChange(event)}
                          autoComplete="off"
                          // onFocus={() => this.onClickShowToAddresses(true)}
                          onKeyDown={this.onToKeyDownHandler.bind(this)}
                        />
                      </DropdownToggle>
                      <DropdownMenu
                        container="body"
                        className="search-address-dropdown-menu search-to-address-list"
                        style={{
                          paddingLeft: 0,
                          paddingRight: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                          minWidth: "280px",
                          minHeight: "180px",
                          top: "100%",
                        }}
                        positionFixed={true}
                      >
                        {this.state.addressToDetails.toAddressWithPlaceId?.map(
                          (item, i) => {
                            return (
                              <div key={i}>
                                <Row
                                  onClick={() =>
                                    this.onAddToAddress(
                                      item.addressDetails[0] +
                                        "," +
                                        item.addressDetails[1],
                                      item
                                    )
                                  }
                                  className={
                                    cursor === i
                                      ? "active search-address-dropdown-menu-row"
                                      : "not-active search-address-dropdown-menu-row"
                                  }
                                >
                                  <Col className="search-address-col-icon">
                                    <i
                                      className="fa-thin fa-location-pin"
                                      style={{
                                        fontSize: "1.4em",
                                        marginRight: "0",
                                      }}
                                    ></i>
                                  </Col>
                                  <Col className="search-address-col-textbox">
                                    <div className="search-address-streetname">
                                      {item.addressDetails[0]}
                                    </div>
                                    <div className="search-address-city">
                                      {item.addressDetails[1]}
                                      {this.checkIfUndefined(
                                        item.addressDetails[2]
                                      )}
                                      {this.checkIfUndefined(
                                        item.addressDetails[3]
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            );
                          }
                        )}
                      </DropdownMenu>
                    </Dropdown>
                    <div className="booking-error-message">
                      {this.state.inValidToMessage}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="m-2">
                    <ButtonGroup className="p-2" radioGroup="travel-time">
                      <Button
                        color={this.state.travelNow ? "dark" : "secondary"}
                        radioGroup="travel-time"
                        checked={this.state.travelNow}
                        style={{ width: "150px", height: "60px" }}
                        onClick={() => this.onClickTravelNow()}
                        // onClick={() =>
                        //   this.setState({ travelNow: true, travelLater: false })
                        // }
                      >
                        Nu
                      </Button>
                      <Button
                        color={this.state.travelLater ? "dark" : "secondary"}
                        radioGroup="travel-time"
                        checked={this.state.travelLater}
                        style={{ width: "150px", height: "60px" }}
                        onClick={() =>
                          this.setState({
                            travelNow: false,
                            travelLater: true,
                            isDateOpen: true,
                          })
                        }
                      >
                        Senare
                      </Button>
                    </ButtonGroup>
                  </Col>
                  <Col className="m-2">
                    {this.state.travelLater ? (
                      <Row>
                        <Col>
                          <FormGroup floating>
                            <Label for="travelDate" className="p-2">
                              Datum
                            </Label>
                            <Input
                              id="travelDate"
                              valid={this.state.dateValid}
                              invalid={this.state.dateInvalid}
                              name="date"
                              placeholder="date placeholder"
                              type="date"
                              min={this.state.dateMin}
                              max={this.state.dateMax}
                              value={this.state.date}
                              onChange={this.onDateChange.bind(this)}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup floating>
                            <Label for="travelTime" className="p-2">
                              Tid
                            </Label>
                            <Input
                              id="travelTime"
                              valid={this.state.timeValid}
                              invalid={this.state.timeInvalid}
                              name="time"
                              placeholder="time placeholder"
                              type="time"
                              min="08:35"
                              value={this.state.time}
                              onChange={this.onTimeChange.bind(this)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                    <Row>
                      <Col className="d-flex flex-row-reverse">
                        {this.state.showBookBtn ? (
                          <div className="">
                            <Button
                              color="primary"
                              style={{
                                width: "250px",
                                height: "80px",
                                backgroundColor: "#06225b",
                                borderColor: "#06225b",
                              }}
                              onClick={() => this.openBooking()}
                            >
                              {this.state.priceLoading ? (
                                <small>Hämtar pris</small>
                              ) : (
                                <></>
                              )}
                              <Loading loading={this.state.priceLoading} white>
                                Boka till fastpris
                                <h3
                                  className={
                                    this.state.price === 0
                                      ? "hide-booking-price"
                                      : "show-booking-price"
                                  }
                                >
                                  {this.state.price +
                                    this.state.administrationFee +
                                    this.state.airportFee}{" "}
                                  kr
                                </h3>
                              </Loading>
                            </Button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Container>

            <BookingComponent />
          </React.Fragment>
        )}
      </LanguageContext.Consumer>
    );
  }

  openBooking(): void {
    let isValidDateTime : boolean = true;
    if(this.state.travelLater === true)
    {
      if(this.state.dateValid !== true || this.state.timeValid !== true)
      {
        isValidDateTime = false;
      }
    }

    if (
      this.state.fromValid === true &&
      this.state.toValid === true &&
      this.state.priceLoading === false &&
      this.state.price > 0 &&
      isValidDateTime === true
    ) {
      let booking = {
        to: this.state.toAddressWithPlaceId,
        from: this.state.fromAddressWithPlaceId,
        date: this.state.date,
        time: this.state.time,
        travelNow: this.state.travelNow,
        travelLater: this.state.travelLater,
        price: this.state.price,
        priceRequest: this.state.getPriceRequestModel,
        isAirport: this.state.isAirport,
        administrationFee: this.state.administrationFee,
        airportFee: this.state.airportFee,
      };

      this.setState({
        to: "",
        from: "",
        date: "",
        time: "",
        travelNow: true,
        travelLater: false,
        fromValid: false,
        toValid: false,
        price: 0,
        airportFee: 0,
      });

      App.publish("open.booking", booking);
    }
  }

  selectTo(e: boolean): void {
    this.setState({ showToResult: e });
    this.setState({ showFromResult: e });
  }
}
