import * as App from 'pubsub-js';
import * as React from 'react';
import { GlobalAppsettings } from './GlobalSettings';

/* Import Local for Sweden */
import 'moment/locale/se';
import moment from "moment";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import lang_sv from '../theme/img/sweden.png'
import lang_eng from '../theme/img/united-kingdom.png'
import lang_usa from '../theme/img/united-states-of-america.png'
import lang_den from '../theme/img/denmark.png'
import lang_nor from '../theme/img/norway.png'
import lang_fin from '../theme/img/finland.png'
import lang_fr from '../theme/img/france.png'
import lang_sp from '../theme/img/spain.png'


export interface LanguageInfo {
    list:   KeyWord[];
    id:     string;
    langid: string;
}

export interface KeyWord {
    key:   string;
    value: string;
}

export interface LanguageState {
    current?: LanguageInfo;
}

export class Language {

    constructor() {
        var cache = localStorage.getItem(GlobalAppsettings.LanguageId);
        if (cache!=null)
        {
           this.text = JSON.parse(cache);
           console.info('Loaded Language from Cache');
        }
    }

    public static GetLocale(): string {

        let storedLocale = localStorage.getItem(GlobalAppsettings.AppId + "-current-lang");
        if (storedLocale!=null)
        {
            return storedLocale;
        }

        let locale = moment.locale();
        if (locale==='en') locale = 'en-US';
        if (locale==='se') locale = 'sv';
        
        localStorage.setItem(GlobalAppsettings.AppId + "-current-lang", locale);
        
        console.info('Get Locale' + locale);

        return locale;
    }

    private text?: KeyWord[];
    private id?: string;
    private langid?: string;

    public loadData(data: KeyWord[]) {
        this.text = data;
        localStorage.setItem(GlobalAppsettings.LanguageId,JSON.stringify(this.text));
        console.info('Saved Language to Cache');
    }

    private find(data: KeyWord[],search: string) {
      
        let val: string= search;
        if (search!==undefined && search!==null) {
            
            let found = false;
            
            data.forEach((element) => {
                if (element.key.toLowerCase()===search.toLowerCase()) {
                    val = element.value;
                    found=true;
                    return val;
                } 
            });

            if (!found) {
                // if (GlobalAppsettings.IsDevelopment) {
                //     return <Text id={search}/>;
                // }
                // else {
                //   return search;
                // }
                //his.AddMissingText(search);
            }
        }
        else
        {
           return 'empty.value';
        }
        return val;
    }

    private AddMissingText(text: string) {
        if (GlobalAppsettings.IsDevelopment) {
            fetch(GlobalAppsettings.BackEndBaseUrl + 'api/app/AddMissingKeyWord?text='+ text + '&lang=' + Language.GetLocale(),
                {credentials: "same-origin", method: 'post'})
                .then(data => {
                    console.info("added keyword " + text);
                });
        }
    }

    public Text(key: string): string {
        if (this.text!=undefined)
        {
            return this.find(this.text, key);
        }
        return "-"; //Default
    }

    public _text(key: string): React.ReactElement | string {
        if (this.text!=undefined)
        {
            return this.find(this.text, key);
        }
        return "-"; //Default
    }
}

export const LanguageContext = React.createContext(new Language());

export const LanguageConsumer = LanguageContext.Consumer;


export interface LanguageProviderState {
    current: Language,
    edit_lang: boolean
}

export interface LanguageProviderProps {
}

export class LanguageProvider extends React.Component<LanguageProviderProps, LanguageProviderState> {
    constructor(props:LanguageProviderProps) {
        super(props);
        let cl: Language = new Language();
        this.state = { current:  cl, edit_lang: false};
    }

    componentDidMount() {
        this.updateLanguage(Language.GetLocale());
        App.subscribe("language.update",this.update.bind(this));
        App.subscribe('change-lang',this.changeLang.bind(this));
    }

    componentWillUnmount() {
        App.unsubscribe("language.update");
        App.unsubscribe('change-lang');
    }

    private changeLang(msg:string, data?: any)
    {
        if (data!=undefined && data.lang!=undefined)
        {
            localStorage.setItem(GlobalAppsettings.AppId + "-current-lang", data.lang);
            
            fetch(GlobalAppsettings.BackEndBaseUrl + 'api/user/UpdateCurrentLang?lang='+ data.lang,
            {credentials: "same-origin"})
            .then(response => response.json() as Promise<any>)
            .then(data => {
                //OK
                console.info(data);
            });
            this.updateLanguage(data.lang, true);
        }
    }

    private update(msg: string, lang: string) {
        var textdata = new Array<KeyWord>();

        textdata.push({key: "start.message",value: "element.value"});
        textdata.push({key: "engage.buttontext",value: "Test"});
        textdata.push({key: "button.close",value: "Stäng"});

        let cl: Language = new Language();
        cl.loadData(textdata);
        
        this.setState({current: cl});
    }

    private updateLanguage(lang: string, reload: boolean = false) {
        console.info(" updateLanguage -> " + lang);
        fetch(GlobalAppsettings.BackEndBaseUrl + 'api/app/currentlanguage?lang='+ lang,
            {credentials: "same-origin"})
            .then(response => response.json() as Promise<LanguageInfo>)
            .then(data => {
                var textdata = new Array<KeyWord>();
                if (data.list !=undefined && data.list.length > 0)
                {
                    data.list.map(element => {
                        textdata.push({key: element.key,value: element.value});
                    });
                    let cl: Language = new Language();
                    cl.loadData(textdata);
                    this.setState({current: cl});
                    
                    if (reload) {
                        window.location.reload(); 
                    }
                }
                else
                {
                    console.info("No language entries returned for language " + data.langid);
                }
        }).catch(reason => {
            // //console.log(reason);
        });
    }

    render() {
    let currentLang = lang_sv;
    
    switch (Language.GetLocale()) {
      case 'sv':
        currentLang = lang_sv;
        break;
      case 'en-US':
        currentLang = lang_eng;
        break;
      case 'fr':
        currentLang = lang_fr;
        break;
    }
      return (
        <LanguageContext.Provider value={this.state.current} >
          {this.props.children}
           {/* {this.renderMenu(currentLang)} */}
        </LanguageContext.Provider>
    );
    }

    private renderMenu(currentLang: string ) {
        return (
            <UncontrolledButtonDropdown >
            <DropdownToggle className="btn--pill shadow" 
              style={{backgroundColor: 'whitesmoke', borderColor: 'lightgray',
              // position: 'fixed', top: '90px', right: '-15px', 
              position: 'fixed', bottom: '-18px', height: '60px', width: '25px', left: '5px',
              borderRadius: 20,
              zIndex: 800
              }}>
              <img style={{width: '25px',position: 'relative',right: '12px',
                  bottom: '9px'}} src={currentLang} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => App.publish('change-lang',{lang:'sv'})}><img style={{width: '20px'}}  src={lang_sv} />{' '}Svenska</DropdownItem>
              <DropdownItem onClick={() => App.publish('change-lang',{lang:'en-US'})}><img style={{width: '20px'}}  src={lang_eng} />{' '}English</DropdownItem>
              <DropdownItem onClick={() => App.publish('change-lang',{lang:'fr'})}><img style={{width: '20px'}}  src={lang_fr} />{' '}Francais</DropdownItem>
              
              {GlobalAppsettings.IsDevelopment ? 
              <>
              <DropdownItem ><hr/></DropdownItem>
              {this.state.edit_lang ?<>
                  <DropdownItem onClick={() => {
                          this.setState({edit_lang: false});
                          App.publish('edit-lang',false);
                          }}>
                  {' '}Save Language</DropdownItem>
              </>: <>
                  <DropdownItem onClick={() => {
                          this.setState({edit_lang: true});
                          App.publish('edit-lang',true);
                          }}>
                  {' '}Edit Language</DropdownItem>
              </>}
              </>
              :<></>}
            </DropdownMenu>
          </UncontrolledButtonDropdown>

        );
    }
}

export interface TextState {
    edit: boolean
    text: string
}

export interface TextProps {
    id: string
}

export class Text extends React.Component<TextProps, TextState> {
    static displayName = 'lang';
    static contextType = LanguageContext;
    constructor(props: any) {
        super(props);
        
        this.state = {
            edit: false,
            text: this.props.id
        };
    }

    componentDidMount() {
        App.subscribe('edit-lang',this.editLang.bind(this));
    }

    componentWillUnmount() {
        App.unsubscribe('edit-lang');
    }

    private editLang(msg: string, data: boolean) {
        console.info('edit-lang' + data);
        this.setState({edit: data});
    }

    private AddMissingText(text: string) {
        if (GlobalAppsettings.IsDevelopment) {
            fetch(GlobalAppsettings.BackEndBaseUrl + 'api/app/AddMissingKeyWord?text='+ text + '&lang=' + Language.GetLocale(),
                {credentials: "same-origin", method: 'post'})
                .then(data => {
                    console.info("added keyword " + text);
                });
        }
    }

    render() {
        return <>
        <LanguageContext.Consumer>
        { lang => ( <>
            {this.state.edit ? <>
                {lang.Text(this.state.text)} - edit
            </>:<>
                {lang.Text(this.state.text)}
            </>}
        </>)}
        </LanguageContext.Consumer>
        </>;
    }

}




