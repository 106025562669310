import React, { useCallback, useEffect, useState } from "react";
import App from "pubsub-js";
import { LanguageContext } from "../../backbone/Language";
import { LoginComponent } from "./LoginComponent";
import "../styles/Payment.css";
import { stringify } from "querystring";
import BookingReceiptComponent from "./BookingReceiptComponent";


export type NetsResponsePayload<T = any> = {
  event : string,
  data : T,
}

interface Props {
  netsId: string;
}

const NetsPaymentComponent = ({ netsId }: Props) => {

  // const netsUrl : string = "https://taxigoteborgwebpayment.z16.web.core.windows.net"; //DEV
  const netsUrl : string = "https://taxigbgpaymentprod.z16.web.core.windows.net"; //PROD

  const [showBackButton, setShowBackBottom] = useState<boolean>(true);

  const init = () => {
    App.subscribe("netsPayment", (message: any, data: string) =>
      open(message, data)
    );
  };

  const open = (message: "", data: string) => {
    if (data != undefined) {
      App.publish("netsPaymentModal", null);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const handleMessageEvent = (message: MessageEvent) => {
    if (
      message.origin !==
      netsUrl
      //"https://taxigbgpaymentprod.z16.web.core.windows.net"
    )
      return;

    const data = JSON.parse(message.data) as NetsResponsePayload


    switch (data.event) {
      case "payment-completed":
        setShowBackBottom(false);
        setTimeout(() => {
          // window.location = "https://taxi-gbg-homepage.webflow.io/?screen=mypages" as any; //DEV
          window.location = "https://www.taxigoteborg.se/?screen=mypages" as any; //PROD
        }, 3000);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessageEvent);
  }, []);

  //"https://taxigbgpaymentprod.z16.web.core.windows.net/?paymentId=" + netsId
  return (
    <div>
      <LoginComponent modalOpen="netsPaymentModal">
        <div data-back_state={showBackButton ? "back_only" : "hidden" }>  
          <div className="payment-nets-container">
            <iframe
              className="payment-nets-object"
              src={
                netsUrl + "/?paymentId=" + netsId  
              }
              ></iframe>
          </div>
        </div>
      </LoginComponent>
    </div>
  );
};

export default NetsPaymentComponent;
