import React, { Component, useReducer } from "react";
import {
  Container,
  Button,
  Fade,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import * as App from "pubsub-js";
import { LanguageContext, Language } from "../backbone/Language";
import { GlobalAppsettings } from "../backbone/GlobalSettings";
import { Loading } from "../components/base/Loading";
import { InfoPanel } from "../panels/InfoPanel";
import { ContentPanel } from "../panels/ContentPanel";

import { User } from "./api/User";
import { SaveButton } from "../components/base/SaveButton";
import { UrlHelper } from "../backbone/UrlHelper";
import "./styles/UserProfileStyle.css";

export interface UserProfileProps {
  customData?: string;
}

export interface UserProfileState {
  loading: boolean;
  dirty: boolean;
  saving: boolean;
  currentUser?: User.UserProfile;
  showLogoutButton: boolean;
}



export class UserProfile extends React.Component<
  UserProfileProps,
  UserProfileState
> {
  static displayName = UserProfile.name;
  static contextType = LanguageContext;

  constructor(props: UserProfileProps) {
    super(props);

    this.state = {
      loading: true,
      saving: false,
      dirty: false,
      currentUser: {
        id: "",
        bookings: [],
        email: "",
        mobile: "",
        name: "",
        allowMarketing: true,
        contact: {
          consent: true,
        },
        offerings: [],
        isError: false,
      },
      showLogoutButton: false,
    };
  }

  componentDidMount() {
    this.loadUserProfile(this.props.customData);
    if(!window.ReactNativeWebView){
     this.setState({showLogoutButton: true}) 
    }
  }

  loadUserProfile(customData?: string) {
    let id = undefined;

    if (customData!=undefined)
    {
      id = customData;
    }
    else
    {
      id = this.getParamsFromCurrentURL();
    }
    
    if (id == undefined) return;

    const request = {
      userId: id,
    };

    fetch(GlobalAppsettings.BackEndBaseUrl + "api/profile/UserProfile", {
      method: "POST",
      headers: GlobalAppsettings.GetApiHeaders(),
      body: JSON.stringify(request),
      credentials: "same-origin",
    })
      .then((response) => response.json() as Promise<any>)
      .then((data) => {
        this.setState({ loading: false, currentUser: data.userProfile });
      })
      .catch((reason) => {
        console.error(reason);
      });
  }

  getParamsFromCurrentURL(): string | undefined {
    try {
      var path = window.location.href.split("?userId=");

      if (path.length > 1) {
        var id = path[path.length - 1];
        return id;
      } else {
        return undefined;
      }
    } catch (error) {
      console.error(error);
    }
  }

  saveUserprofile() {
    var user = this.state.currentUser;
    if (user != undefined) {
      this.setState({ saving: true });

      const request = {
        //userId: "80c95bce-2993-44ef-98d4-ffeefc3b0153",
        userId: this.state.currentUser?.id,
        name: this.state.currentUser?.name,
        email: this.state.currentUser?.email,
        consentToPolicy: this.state.currentUser?.contact.consent,
        consentToMarketing: this.state.currentUser?.allowMarketing,
      };

      fetch(
        GlobalAppsettings.BackEndBaseUrl + "api/profile/UpdateUserProfile",
        {
          method: "POST",
          headers: GlobalAppsettings.GetApiHeaders(),
          body: JSON.stringify(request),
          credentials: "same-origin",
        }
      )
        .then((response) => response.json() as Promise<any>)
        .then((data) => {
          this.setState({ loading: false, saving: false, dirty: false });
        })
        .catch((reason) => {
          console.error(reason);
          this.setState({ loading: false, saving: false });
        });
    }
  }

  changeFirstName(event: any) {
    var user = this.state.currentUser;
    if (user != undefined) {
      user.name = event.target.value;
      this.setState({ dirty: true, currentUser: user });
    }
  }

  changeEmail(event: any) {
    var user = this.state.currentUser;
    if (user != undefined) {
      user.email = event.target.value;
      this.setState({ dirty: true, currentUser: user });
    }
  }

  changeMarketing(event: any) {
    var user = this.state.currentUser;
    if (user != undefined) {
      user.allowMarketing = event.target.checked;
      this.setState({ dirty: true, currentUser: user });
    }
  }

  changePolicy(event: any) {
    var user = this.state.currentUser;
    if (user != undefined) {
      user.contact.consent = event.target.checked;
      this.setState({ dirty: true, currentUser: user });
    }
  }

  LogoutUser() {
    sessionStorage.removeItem('tx-ref-id');
    window.location = "https://www.taxigoteborg.se/" as any;
  }

  render() {
    return (
      <LanguageContext.Consumer>
        {(lang) => (
          <React.Fragment>
            <Loading
              style={{ minHeight: "400px" }}
              loading={this.state.loading}
            >
              <Fade>
                <Container>
                  <Row>
                    <Col>
                      <Form>
                        <FormGroup className="p-2">
                          <Label for="examplePassword">Namn:</Label>
                          <Input
                            type="text"
                            name="password"
                            id="examplePassword"
                            placeholder="Förnamn"
                            onChange={this.changeFirstName.bind(this)}
                            value={this.state.currentUser?.name}
                            disabled={this.state.saving}
                          />
                        </FormGroup>
                        <FormGroup className="p-2">
                          <Label for="exampleEmail">Epost:</Label>
                          <Input
                            type="email"
                            name="email"
                            id="exampleEmail"
                            placeholder="epost"
                            onChange={this.changeEmail.bind(this)}
                            value={this.state.currentUser?.email}
                            disabled={this.state.saving}
                          />
                        </FormGroup>
                        <FormGroup className="p-2">
                          <Label for="examplePassword">Mobil:</Label>
                          <Input
                            type="text"
                            name="password"
                            id="mobile"
                            placeholder=""
                            //onChange={this.changeFirstName.bind(this)}
                            value={this.state.currentUser?.mobile}
                            disabled={true}
                          />
                        </FormGroup>
                        <FormGroup className="p-2">
                          <FormGroup className="form-switch">
                            <Input
                              type="checkbox"
                              id="exampleCustomSwitch"
                              name="customSwitch"
                              role="switch"
                              checked={this.state.currentUser?.allowMarketing}
                              onChange={this.changeMarketing.bind(this)}
                              disabled={this.state.saving}
                            ></Input>
                            <Label for="exampleCustomSwitch" className="p-1">
                              Jag vill ta del av kampanjer och erbjudanden
                            </Label>
                          </FormGroup>

                          {/* <FormGroup className="form-switch">
                            <Input
                              type="checkbox"
                              id="exampleCustomSwitch2"
                              name="customSwitch2"
                              className="pt-2"
                              role="switch"
                              //  value={this.state.currentUser?.contact.consent ? 'true': 'false'}
                              checked={this.state.currentUser?.contact.consent}
                              onChange={this.changePolicy.bind(this)}
                              disabled={this.state.saving}
                            ></Input>
                            <Label for="exampleCustomSwitch2" className="p-1">
                              Jag godkänner hantering av data enligt gällande
                              datahanteringspolicy
                            </Label>
                          </FormGroup> */}
                        </FormGroup>
                      </Form>
                    </Col>
                  </Row>

                  <Row className="pt-3">
                    <Col>
                      {this.state.dirty ? (
                        <Fade>
                          <SaveButton
                            text="Spara"
                            saving={this.state.saving}
                            onSave={() => this.saveUserprofile()}
                          />
                        </Fade>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col>
                    <div className={this.state.showLogoutButton ? "user-profile-logout-button-container" : "user-profile-logout-button-container-hide" }>
                      <Button
                        className="user-profile-logout-button"
                        onClick={() => this.LogoutUser()}
                      >
                        Logga ut
                      </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Fade>
            </Loading>
          </React.Fragment>
        )}
      </LanguageContext.Consumer>
    );
  }
}
