import React, { useEffect } from "react";

interface IProps {
  onMount: () => void;
}

const ComponentDidMount: React.FC<IProps> = ({ onMount }) => {
  useEffect(() => {
    onMount();
  }, []);

  return null;
};

export default ComponentDidMount;
