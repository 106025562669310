import { GlobalAppsettings } from "../../backbone/GlobalSettings";
import {
  ExtraService,
  GetActiveExtraServicesResponse,
} from "../types/ExtraService";
import { GetPrice } from "../types/GetPrice";

export class ExtraServicesApi {
  public static async getActiveExtraServices(): Promise<ExtraService[]> {
    const request = {};

    const response = await fetch(
      GlobalAppsettings.BackEndBaseUrl +
        "api/extraService/GetActiveExtraServices",
      GlobalAppsettings.GetPostInit(request)
    );

    const data = (await response.json()) as GetActiveExtraServicesResponse;

    if (!data.success) throw new Error(data.errorMessage);

    return data.extraServices;
  }
}
