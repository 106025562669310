import * as App from 'pubsub-js';
import * as React from 'react';
import { GlobalAppsettings } from './GlobalSettings';


export class AppCore {

    constructor() {


    }

}