import * as React from "react";

import "./spinner.css";

interface LoadingProps {
  loading?: boolean;
  style?: any;
  white?: boolean;
}

export class Loading extends React.Component<LoadingProps, {}> {
  constructor(props: LoadingProps) {
    super(props);
  }

  public render() {
    return (
      <React.Fragment>
        {this.props.loading ? (
          <div
            style={this.props.style}
            className="d-flex justify-content-center"
          >
            <div className="d-flex align-items-center">
              <div
                className={
                  this.props.white
                    ? "spinner-grow-p1 text-light"
                    : "spinner-grow-p1 text-dark"
                }
                role="status"
              ></div>
              <div
                className={
                  this.props.white
                    ? "spinner-grow-p1 text-light"
                    : "spinner-grow-p1 text-dark"
                }
                role="status"
              ></div>
              <div
                className={
                  this.props.white
                    ? "spinner-grow-p1 text-light"
                    : "spinner-grow-p1 text-dark"
                }
                role="status"
              ></div>
            </div>
          </div>
        ) : (
          <React.Fragment>{this.props.children}</React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
