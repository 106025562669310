import React, { Component, useReducer } from "react";
import {
  Container,
  Button,
  Fade,
  Row,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import * as App from "pubsub-js";
import { LanguageContext, Language } from "../backbone/Language";
import { GlobalAppsettings } from "../backbone/GlobalSettings";
import { Loading } from "../components/base/Loading";
import { InfoPanel } from "../panels/InfoPanel";
import { ContentPanel } from "../panels/ContentPanel";
import { User } from "./api/User";

import { BaseResultResponse } from "./types/BaseResultResponse";
import { LoginComponent } from "./components/LoginComponent";
import { UserProfile } from "./UserProfile";
import { History } from "./History";
import "./styles/MyPages.css";
import "./styles/Bonus.css";
import { nonWhiteSpace } from "html2canvas/dist/types/css/syntax/parser";
import { UserApi } from "./api/GetUserApi";
import { BookingInfo, UserProfileResult } from "./types/UserProfile";
import ComponentDidMount from "../components/ComponentDidMount";
import OfferTile from "../components/OfferTile";
import moment from "moment";
import { BonusApi } from "./api/BonusApi";
import { mdiConsoleNetworkOutline, mdiThermometerMinus } from "@mdi/js";
import { BonusRequest, BonusResponse } from "./types/Bonus";
// import { NumericDictionaryIterator } from "lodash";

export interface MyPagesProps {
  customData: string;
}

export interface MyPagesState {
  loading: boolean;
  locale: string;
  isDirty: boolean;
  validUser: boolean;
  saving: boolean;
  userId?: string;
  currentUser?: User.UserProfile;
  errorMessage?: string;
  activeTab: string;
  userProfile: UserProfileResult | null;
  userProfileBooking: BookingInfo | null;
  userProfileBookingExist: boolean;
  bonusPointsToUse: number;
  bonusPointsLongTerm: number;
  showLongTermBonus: boolean;
  avaiblePoints: number;
  bonusExpirationDate: string;
  bonusBarStringValue: string;
  showUserBonuses: boolean;
  expirationDate: string;
}

export interface ValidationInfo extends BaseResultResponse {
  token: string;
}

export class MyPages extends React.Component<MyPagesProps, MyPagesState> {
  static displayName = MyPages.name;
  static contextType = LanguageContext;

  constructor(props: MyPagesProps) {
    super(props);

    this.state = {
      loading: false,
      isDirty: false,
      saving: false,
      validUser: false,
      activeTab: "1",
      locale: Language.GetLocale(),
      userProfile: null,
      userProfileBooking: null,
      userProfileBookingExist: false,
      bonusPointsToUse: 0,
      bonusPointsLongTerm: 0,
      showLongTermBonus: false,
      avaiblePoints: 0,
      bonusExpirationDate: "",
      bonusBarStringValue: "",
      showUserBonuses: false,
      expirationDate: "",
    };
    this.loadUserProfile = this.loadUserProfile.bind(this);
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);

    let queryStartScreen = params.get("screen");
    if (queryStartScreen !== null) {
      queryStartScreen = queryStartScreen.toLocaleLowerCase();
    }

    if (queryStartScreen === "mypages") {
      setTimeout(() => {
        App.publish("open.mypages", null);
        this.setState({ activeTab: "2" });
      }, 500);
    }
  }

  render() {
    return (
      <LanguageContext.Consumer>
        {(lang) => (
          <>
            <Button
              color=""
              className="NavButttonBackground NavButton shadow-none"
              onClick={() => App.publish("open.mypages", null)}
            >
              Mina sidor
            </Button>

            <LoginComponent
              modalOpen="open.mypages"
              onChange={(message) => this.onLogin(message)}
            >
              {this.renderProfile()}
            </LoginComponent>
          </>
        )}
      </LanguageContext.Consumer>
    );
  }

  onLogin(message: any): void {
    console.info(message);
    this.loadUserProfile();
  }
  renderProfile() {
    return (
      <div data-back_state="back_only">
        <Row style={{ backgroundColor: "#ffcb05", height: "150px" }}>
          <Col className="p-3 align-content-center" style={{}}>
            <h2
              className="heading textunderline m-3"
              style={{
                borderBottom: "0.07em solid #fff",
                maxWidth: "180px",
              }}
            >
              Mina sidor
            </h2>
          </Col>
        </Row>
        <Form className="">
          <Row className="p-2">
            <Col md="4">
              <h4
                onClick={() => this.setState({ activeTab: "1" })}
                className="heading textunderline m-3"
                style={{
                  borderBottom:
                    this.state.activeTab === "1" ? "3px solid #ffcb05" : "none",
                  maxWidth: "96px",
                  cursor: "pointer",
                }}
              >
                Min profil
              </h4>
            </Col>
            <Col>
              <h4
                onClick={() => this.setState({ activeTab: "2" })}
                // onClick={() => {
                //   window.open("https://taxigoteborg.se/kundservice", "_blank");
                // }}
                className="heading textunderline m-3"
                style={{
                  borderBottom:
                    this.state.activeTab === "2" ? "3px solid #ffcb05" : "none",
                  maxWidth: "148px",
                  cursor: "pointer",
                }}
              >
                Mina resor
              </h4>
            </Col>
            <Col>
              <h4
                onClick={() => this.setState({ activeTab: "3" })}
                className="heading textunderline m-3"
                style={{
                  borderBottom:
                    this.state.activeTab === "3" ? "3px solid #ffcb05" : "none",
                  maxWidth: "148px",
                  cursor: "pointer",
                }}
              >
                Kampanjer
              </h4>
            </Col>
          </Row>
          <Row className="p-2">
            <Col>
              <TabContent className="m-2" activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {this.state.userId ? (
                    <UserProfile customData={this.state.userId} />
                  ) : (
                    <></>
                  )}
                </TabPane>
                <TabPane tabId="2">
                  {/* <>
                  {this.renderBookingExist()}
                  </>
                  <> */}
                  {this.state.userId ? (
                    <History customData={this.state.userId} />
                  ) : (
                    <></>
                  )}
                  {/* </> */}
                </TabPane>
                <TabPane tabId="3">
                  {this.renderOfferings()}
                  {/* {this.state.userId ? (
                    <UserProfile customData={this.state.userId} />
                  ) : (
                    <></>
                  )} */}
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }

  private renderOfferings(): React.ReactNode {
    return (
      <div>
        <ComponentDidMount
          onMount={() => {
            this.setUser();
          }}
        />
        <>{this.renderOfferingList()}</>
        <>{this.renderBonuses()}</>
      </div>
    );
  }

  private renderOfferingList() {
    return (
      <div className="booking-component-offering-list-container">
        <div className="booking-component-offering-list">
          {/* Offerings */}
          {this.state.userProfile?.userProfile.offerings && (
            <ul className="booking-component-offering-list-ul">
              {this.state.userProfile?.userProfile.offerings.map((offering) => {
                return (
                  <li className="my-pages-offering-container">
                    <Row className="offering-row">
                    <div className="">
                      <div className="offering-text_area">
                        <h1 className="offering-title">{offering.title}</h1>
                        <div className="offering-description">
                          {offering.description}
                        </div>
                        <div className="offering-selected_container">
                        </div>
                        <div className="offering-avaible-date">
                          {"Giltigt t.o.m. den "+this.cleanDateTime(offering.endDate)}
                        </div>
                      </div>
                    </div>
                    </Row>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    );
  }

  private renderBonuses() {
    if(this.state.showUserBonuses == true)
    {
    return (
      <div className="mypages-bonuses-container">
        <h3>Bonus</h3>
        <div className="mypages-bonuses-card">
          <div>
            <div className="bonus-details-title-container">
              <div className="bonus-details-title">Bonus att använda fram till</div>
              <div className="bonus-details-title-date">{this.cleanBonusDateTime(this.state.expirationDate)}</div>
            </div>
            <div className="bonus-details-cash">{this.state.bonusPointsToUse} kr</div>
            <div className={ this.state.showLongTermBonus ? "bonus-details-cash-thereafter" : "bonus-details-cash-thereafter-hide"}>Därefter {this.state.bonusPointsLongTerm} kr</div>
          </div>
        </div>
        <div className="mypages-bonuses-card">
          <div>
            <div className="bonus-progress-title">Kommande bonus</div>
            <div className="bonus-progress-title-cash">50 kr</div>
            <div className="bonus-progress-points-container">
              <div className="bonus-progress-points-collected">{this.state.avaiblePoints}</div>
              <div className="bonus-progress-points-goal">/1000 poäng</div>
            </div>
            <div className="bonus-progressbar">
              <div
                className="bonus-progressbar-meter"
                style={{ width: this.state.bonusBarStringValue }}
              ></div>
            </div>
            <div className="bonus-progress-points-needed">
              {1000 - this.state.avaiblePoints} poäng till nästa bonus
            </div>
          </div>
        </div>
      </div>
    );
    }
    else if(this.state.showUserBonuses == false)
    {
    return (
      <div className="mypages-bonuses-container">
        <h3>Bonus</h3>
        <div className="mypages-bonuses-card">
          <div>
            <div className="bonus-progress-title">Kommande bonus</div>
            <div className="bonus-progress-title-cash">50 kr</div>
            <div className="bonus-progress-points-container">
              <div className="bonus-progress-points-collected">{this.state.avaiblePoints}</div>
              <div className="bonus-progress-points-goal">/1000 poäng</div>
            </div>
            <div className="bonus-progressbar">
              <div
                className="bonus-progressbar-meter"
                style={{ width: this.state.bonusBarStringValue }}
              ></div>
            </div>
            <div className="bonus-progress-points-needed">
              {1000 - this.state.avaiblePoints} poäng till nästa bonus
            </div>
          </div>
        </div>
      </div>
    );
    }

    else return;
  }

  private async setUser() {
    var userId : string = "";
    if(this.state.userProfile?.userProfile != undefined)
    {
      userId  = this.state.userProfile?.userProfile.id;
    }

    if (!window.ReactNativeWebView) {
      if (this.state.userProfile === null) {
        const user = await UserApi.getCurrentProfile();
        if (!user) return;
        const updatedUser = await UserApi.getUpdateCurrentProfile(
          user.userProfile.id
        );
        this.setState({ userProfile: updatedUser });
        userId = user.userProfile.id;
      }

      // load in in bonuses
      var request : BonusRequest = {
        userId : userId,
        showAll: false
      };
      var bonus = await BonusApi.getBonusData(request);

      if(bonus != null)
      {
        this.CalculateBonusPoints(bonus);

        if(bonus.data.bonusTickets.length === 0 && bonus.data.availablePoints === 0)
        {
          var meterLength : number = (0/1000) * 100;
    
          var bonusBarStringValue : string = meterLength.toString() + "%";
          this.setState({ bonusBarStringValue : bonusBarStringValue });
        }
      }
    } else return;
  }

  private CalculateBonusPoints = (bonus : BonusResponse) => {
    var bonusPointsTotal: number = 0;
    var bonusPointsOnExpirationDate: number = 0;
    var bonusPointsAfterExpirationDate: number = 0;
    var avaiblePoints: number = 0;
    var bonusExpirationDate: string = "";

    if (bonus.data.bonusTickets != null && bonus.data.bonusTickets.length > 0) {

      this.setState({ showUserBonuses : true });

      const topTicket = bonus.data.bonusTickets[0];

      const closetExpirationDate = moment(
        topTicket.expirationDate,
        "YYYY-MM-DD"
      );
      const expirationDateFormated = moment(closetExpirationDate)
        .subtract(0, "days")
        .calendar();


      bonusExpirationDate = expirationDateFormated.toString();
      bonus.data.bonusTickets.forEach((ticket) => {
        const expirationDate = moment(ticket.expirationDate, "YYYY-MM-DD");
        const expirationDateString = expirationDate.toString() + "T00:00+02:00"

        if (expirationDate > closetExpirationDate) {
          bonusPointsAfterExpirationDate +=
            ticket.bonusPoints - ticket.consumedPoints;
        } else {
          bonusPointsOnExpirationDate +=
            ticket.bonusPoints - ticket.consumedPoints;
        }
      });

      avaiblePoints = bonus.data.availablePoints;
      bonusPointsTotal =
        bonusPointsAfterExpirationDate + bonusPointsOnExpirationDate;

      if(bonusPointsAfterExpirationDate == 0)
      {
        this.setState({ showLongTermBonus : false });
      }
      else
      {
        this.setState({ showLongTermBonus : true });
      }

      var expirationDate : string = "";
      if(topTicket.expirationDate != undefined)
      {
        expirationDate = topTicket.expirationDate?.toString();
        var array = expirationDate.split("T");
        expirationDate = array[0]+"T00:00:00+02:00"    
      }


      this.calcBonusMeter(avaiblePoints);
      this.setState({
        bonusPointsLongTerm: bonusPointsAfterExpirationDate,
        bonusPointsToUse: bonusPointsTotal,
        avaiblePoints: avaiblePoints,
        bonusExpirationDate: bonusExpirationDate,
        expirationDate: expirationDate,
      });
    }
    
    else{
      this.setState({
        avaiblePoints: bonus.data.availablePoints
      })
    }

  }

  private calcBonusMeter = (points : number) => {

    var meterLength : number = (points/1000) * 100;

    var bonusBarStringValue : string = meterLength.toString() + "%";
    this.setState({ bonusBarStringValue : bonusBarStringValue });

  }

  private translateToSwe = (month: string) => {
    if (month === "Jan") return "januari";
    if (month === "Feb") return "februari";
    if (month === "Mar") return "mars";
    if (month === "Apr") return "april";
    if (month === "May") return "maj";
    if (month === "Jun") return "juni";
    if (month === "Jul") return "juli";
    if (month === "Aug") return "augusti";
    if (month === "Sep") return "september";
    if (month === "Oct") return "oktober";
    if (month === "Nov") return "november";
    if (month === "Dec") return "december";
    else {
      return month;
    }
  };

  private cleanDateTime = (endDate: string | undefined) => {
    if(endDate === undefined) return;

    var dateTime = new Date(Date.parse(endDate));
    dateTime.setDate(dateTime.getDate() - 1);

    const timeString = dateTime.toString();
    var timeSplit = timeString.split(" ");

    var month = timeSplit[1];
    const translatedMonth = this.translateToSwe(month);

    const day = timeSplit[2];

    const year = timeSplit[3];

    const dateTimeString =
      day + " " + translatedMonth + " " + year;

    return dateTimeString;
  };

  private cleanBonusDateTime = (endDate: string | undefined) => {
    if(endDate === undefined) return;

    var dateTime = new Date(Date.parse(endDate));
    dateTime.setDate(dateTime.getDate());

    const timeString = dateTime.toString();
    var timeSplit = timeString.split(" ");

    var month = timeSplit[1];
    const translatedMonth = this.translateToSwe(month);

    const day = timeSplit[2];

    const year = timeSplit[3];

    const dateTimeString =
      day + " " + translatedMonth + " " + year;

    return dateTimeString;
  };

  loadUserProfile() {
    this.setState({ loading: true });

    fetch(
      GlobalAppsettings.BackEndBaseUrl + "api/profile/CurrentProfile",
      GlobalAppsettings.GetPostInit({})
    )
      .then((response) => response.json() as Promise<any>)
      .then((data) => {
        console.info("currentProfile ->" + data.userProfile.id);
        this.setState({
          loading: false,
          currentUser: data.userProfile,
          userId: data.userProfile.id,
        });
      })
      .catch((reason) => {
        console.error(reason);
        this.setState({ loading: false });
      });
  }
}
