import { BonusRequest, BonusResponse } from "../types/Bonus";
import { GlobalAppsettings } from "../../backbone/GlobalSettings";

export class BonusApi
{
    public static async getBonusData(
        request : BonusRequest
    )
    {
        const response = await fetch(GlobalAppsettings.BackEndBaseUrl + "api/booking/RetrieveBonusesForUser", GlobalAppsettings.GetPostInit(request));

        const data = (await response.json()) as BonusResponse;

        return data;
    }
}