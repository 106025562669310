import { mdiBaby, mdiBabyCarriage, mdiCarChildSeat } from "@mdi/js";
import { Icon as MdiIcon } from "@mdi/react";
import classNames from "classnames";
import React from "react";
import { Icon as IcoMoonIcon } from "../../components/IcoMoon";

export enum ExtraServiceIcons {
  Stroller = "Stroller",
  CarSeat = "CarSeat",
  Pets = "Pets",
  BabySeat = "BabySeat",
}

export enum CarOptionIcons {
  Normal = "Standard",
  Combi = "Kombi",
  Minibus = "Större",
}

const iconDictionary: {
  [id in ExtraServiceIcons]: string | undefined;
} = {
  [ExtraServiceIcons.Stroller]: mdiBabyCarriage,
  [ExtraServiceIcons.CarSeat]: mdiCarChildSeat,
  [ExtraServiceIcons.BabySeat]: mdiBaby,
  [ExtraServiceIcons.Pets]: undefined,
};

const carIconDictionary: {
  [id in CarOptionIcons]: string | undefined;
} = {
  [CarOptionIcons.Normal]: "taxi",
  [CarOptionIcons.Combi]: "taxi",
  [CarOptionIcons.Minibus]: "bus",
};

const _getIconSize = (size: number, baseSize: number = 1) => {
  return size / baseSize;
};

const ExtraServiceIcon: React.FC<{ iconId: ExtraServiceIcons }> = ({
  iconId,
}) => {
  const ICON_SIZE = 48;

  const icon = iconDictionary[iconId];

  if (icon) {
    return <MdiIcon path={icon} size={_getIconSize(ICON_SIZE, 24)} />;
  }

  return <IcoMoonIcon icon="dog" size={_getIconSize(ICON_SIZE)} />;
};

const CarIcon: React.FC<{ iconId: CarOptionIcons }> = ({ iconId }) => {
  const ICON_SIZE = 48;

  return (
    <IcoMoonIcon
      icon={carIconDictionary[iconId] ?? "car"}
      size={_getIconSize(ICON_SIZE)}
    />
  );
};

type Props =
  | {
      iconId: ExtraServiceIcons;
      label: string;
      isCarOption?: boolean;
      price: number;
      selected: boolean;
      description: string;
      onPress: () => void;
    }
  | {
      iconId: CarOptionIcons;
      label: string;
      isCarOption?: true;
      price?: undefined;
      selected: boolean;
      description: string;
      onPress: () => void;
    };

const ExtraServiceOption: React.FC<Props> = ({
  iconId,
  label,
  price,
  isCarOption = false,
  selected,
  description,
  onPress,
}) => {
  return (
    <div
      className={classNames("extra-service-option", { selected })}
      onClick={onPress}
    >
      <div className="extra-service-option-icon">
        {isCarOption ? (
          <CarIcon iconId={iconId as CarOptionIcons} />
        ) : (
          <ExtraServiceIcon iconId={iconId as ExtraServiceIcons} />
        )}
      </div>
      <div className="extra-service-label">{label}</div>
      <div className="extra-service-description">{description}</div>
      <div className="extra-service-option-label"></div>
      {price !== undefined && (
        <div className="extra-service-option-price">{price} kr</div>
      )}
    </div>
  );
};

export default ExtraServiceOption;
