import React, { Component, useReducer } from "react";
import {
  Container,
  Button,
  Fade,
  Row,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import * as App from "pubsub-js";
import { LanguageContext, Language } from "../backbone/Language";
import { GlobalAppsettings } from "../backbone/GlobalSettings";
import { Loading } from "../components/base/Loading";
import { InfoPanel } from "../panels/InfoPanel";
import { ContentPanel } from "../panels/ContentPanel";

import { User } from "./api/User";
import { SaveButton } from "../components/base/SaveButton";
import { UrlHelper } from "../backbone/UrlHelper";
import { CollapseList, CollapseListItem } from "../components/CollapseList";

import moment from "moment";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

import "./styles/History.css";
import { UserApi } from "./api/GetUserApi";
import { BookingInfo, UserProfileResult } from "./types/UserProfile";
import ComponentDidMount from "../components/ComponentDidMount";
import { BookingApi } from "./api/BookingApi";
import { mdiTransitSkip } from "@mdi/js";
// import { result } from "lodash";

export interface HistoryProps {
  customData?: string;
}

export interface HistoryState {
  loading: boolean;
  currentUser?: User.UserProfile;
  history?: User.Booking[];
  currentBooking?: User.Booking;
  userProfile: UserProfileResult | null;
  userProfileBooking: BookingInfo | null;
  userProfileBookingExist: boolean;
  cancelButtonLoading: boolean;
  cancelDisabled: boolean;
  hideListItem: boolean;
  isReceiptSentModalOpen: boolean;
  receiptModalText: string;
  userEmail: string;
  isSendReceiptModalDisabled: boolean;
  taxinetBookingId: string;
}

export class History extends React.Component<HistoryProps, HistoryState> {
  static displayName = History.name;
  static contextType = LanguageContext;

  constructor(props: HistoryProps) {
    super(props);

    this.state = {
      loading: true,
      currentUser: {
        id: "",
        bookings: [],
        email: "",
        mobile: "",
        name: "",
        allowMarketing: true,
        contact: {
          consent: true,
        },
        offerings: [],
        isError: false,
      },
      userProfile: null,
      userProfileBooking: null,
      userProfileBookingExist: false,
      cancelButtonLoading: false,
      cancelDisabled: false,
      hideListItem: false,
      isReceiptSentModalOpen: false,
      receiptModalText: "Kollar efter befintlig e-epostadress...",
      userEmail: "",
      isSendReceiptModalDisabled: false,
      taxinetBookingId: ""
    };
  }

  componentDidMount() {
    this.loadUserProfile(this.props.customData);
  }

  loadUserProfile(customData?: string) {
    let id = undefined;

    if (customData != undefined) {
      id = customData;
    } else {
      id = this.getParamsFromCurrentURL();
    }

    const request = {
      userId: id,
    };

    fetch(GlobalAppsettings.BackEndBaseUrl + "api/profile/UserProfile", {
      method: "POST",
      headers: GlobalAppsettings.GetApiHeaders(),
      body: JSON.stringify(request),
      credentials: "same-origin",
    })
      .then((response) => response.json() as Promise<any>)
      .then((data) => {
        this.setState({
          loading: false,
          currentUser: data.userProfile,
          history: data.bookingHistory,
        });
      })
      .catch((reason) => {
      });
  }

  getParamsFromCurrentURL(): string | undefined {
    try {
      var path = window.location.href.split("?userId=");

      if (path.length > 1) {
        var id = path[path.length - 1];
        return id;
      } else {
        return undefined;
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div>
        <div>{this.renderBookingExist()}</div>
        <div>
          <h4>
            För information om tidigare bokningar hänvisar vi till kundservice.
          </h4>
          <Button
          onClick={() => {window.open("https://taxigoteborg.se/kundservice", "_blank");}}
          >
            Kundservice
          </Button>
        </div>
        {/* <LanguageContext.Consumer>
          {(lang) => (
            <React.Fragment>
              <Loading
                style={{ minHeight: "400px" }}
                loading={this.state.loading}
              >
                <Fade>
                  <Container>
                    <Row>
                      <Col>
                        <CollapseList
                          id="itemlist"
                          items={this.getBookings(this.state.history, lang)}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Fade>
              </Loading>

              <InfoPanel
                title="Kvitto"
                cancelButtonLabel="Stäng"
                modalPanelOpen="view.reciept"
                position="modal-panel-right"
                showHeader={false}
                showBackAndRefresh={false}
                showBackWithoutCancelBooking={true}
                showCancelButton={false}
                showCancelPaymentButton={false}
              >
                <div className="yc-panel-xl">
                  {this.renderReciept(this.state.currentBooking)}
                </div>
              </InfoPanel>
            </React.Fragment>
          )}
        </LanguageContext.Consumer> */}
      </div>
    );
  }

  share() {
    //this.printDocument();
    // if (navigator.share) {
    //   navigator.share({
    //     title: 'Taxi Göteborg Kvitto',
    //     // files:
    //   }).then(() => {
    //   })
    //   .catch(console.error);
    // } else {
    //   // fallback
    // }
  }

  printDocument() {
    const input = document.getElementById("divToPrint");
    const logo = document.getElementById("taxi-logo") as HTMLImageElement;
    if (input != null)
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "px", "A4", true);

        pdf.addImage(logo, 20, 10, 100, 100);

        pdf.addImage(
          imgData,
          "PNG",
          20,
          120,
          input.getBoundingClientRect().width / 2,
          input.getBoundingClientRect().height / 2
        );
        pdf.save("download.pdf");
      });
  }

  checkIfDisabled = () => {
    if (this.state.isReceiptSentModalOpen) {
      return true;
    } else {
      return false;
    }
  };

  checkIfButtonDisabled = () => {
    if (this.state.isSendReceiptModalDisabled) {
      return true;
    } else {
      return false;
    }
  };

  private renderReciept(item: User.Booking | undefined) {
    const isSendReceiptButtonDisabled = this.checkIfDisabled();
    const isSendReceiptModalDisabled = this.checkIfButtonDisabled();
    //lukas
    if (item != undefined) {
      let displayPrice = item.priceInfo.price + 12; // item.payment.administationFeeInfo.administrationFee;
      if (item.priceInfo.price === 0) {
        displayPrice = item.priceInfo.price;
      }
      return (
        <div>
          <Container fluid>
            <Row>
              <Col className="d-flex justify-content-center">
                <img
                  width="120px"
                  className="mt-4"
                  id="taxi-logo"
                  src="https://taxi-gbg-webparts-prod.azurewebsites.net/logo.png"
                />
              </Col>
            </Row>
            <div id="divToPrint">
              <Row className="pt-3">
                <Col>
                  <h2>Kvitto</h2>
                </Col>
                <Col className="p-2 d-flex justify-content-end">
                  <Button
                    color="primary"
                    className="send-receipt-button"
                    onClick={
                      isSendReceiptButtonDisabled
                        ? undefined
                        : () => this.SendReceipt(item.id, this.state.currentUser?.id)
                    }
                    disabled={isSendReceiptButtonDisabled}
                    // onClick={() => this.share()}
                  >
                    Skicka kvitto
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="list-group">
                    <div className="list-group-item">
                      <p className="fw-bold">
                        Från: {item.pickupPos.title} <br />
                        <small>
                          {moment(item.pickUpTime).format("YYYY-MM-DD HH:mm")}
                        </small>
                      </p>
                      <p className="fw-bold">
                        Till: {item.dropOfPos.title} <br />
                      </p>
                    </div>

                    <div className="list-group-item fw-bold">
                      <h4>Pris: {displayPrice} kr</h4>
                      <small>
                        Varav moms: {item.payment.paymentDetails.taxAmount + 0.68} kr (
                        {item.payment.paymentDetails.taxRate}%)
                      </small>
                      <br />
                      <small>
                        Netto: {(item.payment.paymentDetails.netTotalAmount - 0.68 + 12).toFixed(2) /* item.payment.administationFeeInfo.administrationTaxAmount + item.payment.administationFeeInfo.administrationFee*/} kr
                      </small>
                      <br />
                      <small>
                        Betalningsmedel:{" "}
                        {item.payment.paymentDetails.paymentMethod}
                      </small>
                      <br />
                    </div>
                    <div>
                      {this.CalculateDiscount(
                        item.priceInfo.basePrice,
                        item.priceInfo.price
                      )}
                    </div>
                    <div className="list-group-item">
                      <small className="kvitto-text-bold">
                        Registreringsnummer: {item.carRegNumber}
                      </small>
                    </div>
                    <div className="list-group-item">
                      <small className="kvitto-text-bold">
                        Bokningsnummer: {item.taxinetBooking.taxinetBookingId}
                      </small>
                      <br />
                    </div>
                    {/* <div className="list-group-item">
                      <small>
                        Betalningsreferens:{" "}
                        {item.payment.paymentProviderInfo.paymentId}
                      </small>
                    </div>
                    <div className="list-group-item">
                      <small>Bokningsreferens: {item.id}</small>
                      <br />
                    </div> */}
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="fw-bolder text-center">
                  <h4>Tack för att du reser med oss!</h4>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="text-center">
                  <b>
                    <small>
                      Taxi Göteborg Ekonomisk Förening, företagsnummer
                      757200-4351
                    </small>
                    <br />
                  </b>
                  <small>kundservice@taxigoteborg.se +46 (0)31-650000</small>
                  <br />
                </Col>
              </Row>
            </div>
          </Container>
          <Modal
            className="send-receipt-modal"
            isOpen={this.state.isReceiptSentModalOpen}
          >
            <ModalBody className="send-receipt-modal-body">
              {this.state.receiptModalText}
            </ModalBody>
            <ModalFooter className="send-receipt-modal-footer">
              <Button
                className="send-receipt-modal-button"
                onClick={
                  isSendReceiptModalDisabled
                    ? undefined
                    : () => this.setState({ isReceiptSentModalOpen: false })
                }
                disabled={isSendReceiptModalDisabled}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }

  private getBookings(
    bookings: User.Booking[] | undefined,
    lang: Language
  ): CollapseListItem[] {
    var list: CollapseListItem[] = new Array<CollapseListItem>();

    if (bookings != undefined) {
      bookings.forEach((item) => {
        if (item.isInCarPayment == undefined || !item.isInCarPayment) {
      let displayPrice = item.priceInfo.price + 12; // item.payment.administationFeeInfo.administrationFee;
      if (item.priceInfo.price === 0) {
        displayPrice = item.priceInfo.price;
      }
      var count = 0;
        count++;
        list.push({
          title: (
            <React.Fragment>
              <strong>{item.dropOfPos.title}</strong>
              <small>
                {moment(item.pickUpTime).format("YYYY-MM-DD HH:mm")}
              </small>
            </React.Fragment>
          ),
          content: (
            <React.Fragment>
              <p className="mb-1">Från: {item.pickupPos.title}</p>
              <h3>Pris: {displayPrice} kr</h3>
              <Row>
                <Col>
                  <small>
                    Betalat med {item.payment.paymentDetails.paymentMethod}
                  </small>
                </Col>
                <Col sm="3">
                  <Button
                    className="ml-5 float-end receipt-show-receipt-button"
                    onClick={() => this.view(item)}
                  >
                    Visa kvitto
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          ),
          isOpen: count == 1 ? true : false,
        });
      }
      });
    }

    return list;
  }

  view(item: User.Booking) {
    this.setState({ currentBooking: item });

    App.publish("view.reciept", null);
  }

  private renderBookingExist(): React.ReactNode {
    return (
      <div>
        <ComponentDidMount
          onMount={() => {
            this.setUserBooking();
          }}
        />
        <>{this.ExistingBooking()}</>
      </div>
    );
  }

  private async setUserBooking() {

    if (!window.ReactNativeWebView) {
      if (this.state.userProfile === null) {
        const user = await UserApi.getCurrentProfile();
        if (!user) return;
        const updatedUser = await UserApi.getUpdateCurrentProfile(
          user.userProfile.id
        );
        this.setState({ userEmail: updatedUser.userProfile.email });
        if (updatedUser.userProfile.bookings.length > 0) {
          const currentUserBooking = updatedUser.userProfile.bookings[0];

          // if(this.state.currentBooking != undefined)
          // {
          //   const currentBooking = this.state.currentBooking;
          //   const taxinetBookingInfo = currentBooking.taxinetBooking;
          //   this.setState({ taxinetBookingId: taxinetBookingInfo.taxinetBookingId })
          // }

          // const taxinetBookingId : string = "";

          var buttonDisabled = this.cancelButtonDisabled(
            currentUserBooking.pickUpTime
          );
          this.setState({
            userProfileBooking: currentUserBooking,
            userProfile: updatedUser,
            cancelDisabled: buttonDisabled,
          });
          if (
            currentUserBooking !== null &&
            currentUserBooking.isPaid === true &&
            currentUserBooking.isCanceled === false &&
            currentUserBooking.isAfterTrip === false
          ) {
            this.setState({ userProfileBookingExist: true });
          }
        }
      }
    } else return;
  }

  private ExistingBooking() {
    // const userBookings = this.state.userProfile?.userProfile.bookings[0];
    return (
      <div>
        <div
          className={
            this.state.userProfileBookingExist
              ? "history-existing-booking"
              : "history-existing-booking-hide"
          }
        >
          <div className="booking-receipt-container">
            <div
              className="my-pages-title"
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <h4 style={{}}>Aktuell bokning</h4>
            </div>
            <div>
              <div>
                <h5 className="receipt-information-title">Tid för avresa</h5>
                <h4
                  className="receipt-information-details"
                  style={{ fontWeight: "normal" }}
                >
                  {this.cleanDateTime(
                    this.state.userProfileBooking?.pickUpTime
                  )}
                </h4>
              </div>
              <div>
                <h5 className="receipt-information-title">Från</h5>
                <h4
                  className="receipt-information-details"
                  style={{ fontWeight: "normal" }}
                >
                  {this.state.userProfileBooking?.pickupPos.title}
                </h4>
              </div>
              <div>
                <h5 className="receipt-information-title">Till</h5>
                <h4
                  className="receipt-information-details"
                  style={{ fontWeight: "normal" }}
                >
                  {this.state.userProfileBooking?.dropOfPos.title}
                </h4>
              </div>
              <div>
                <h5 className="receipt-information-title">Bokningsnummer</h5>
                <h4
                  className="receipt-information-details"
                  style={{ fontWeight: "normal" }}
                >
                  {this.state.userProfileBooking?.bookingNr}
                </h4>
              </div>
              <div className="history-cancel-paid-booking-container">
                <Button
                  // className="history-cancel-paid-booking"
                  className={
                    this.state.cancelDisabled
                      ? "history-cancel-paid-booking-disabled"
                      : "history-cancel-paid-booking"
                  }
                  color="dark"
                  size="lg"
                  onClick={() =>
                    this.cancelPaidBooking(
                      this.state.userProfileBooking?.bookingId
                    )
                  }
                >
                  <Loading loading={this.state.cancelButtonLoading} white>
                    Avboka
                  </Loading>
                  {/* Betalning */}
                </Button>
              </div>
              {/* <div>
                <h5 className="receipt-information-title">Antal passagerare</h5>
                <h4
                  className="receipt-information-details"
                  style={{ fontWeight: "normal" }}
                >
                  1-4 personer
                </h4>
              </div> */}
            </div>
          </div>
          <hr className="receipt-line-seperator" />
        </div>
      </div>
    );
  }

  cancelButtonDisabled(pickupTime: string) {
    var puTime = moment(pickupTime);
    var validTime = moment(puTime).subtract(31, "minutes");
    var newDate = moment(new Date());

    if (newDate > validTime) {
      return true;
    } else {
      return false;
    }
  }

  async cancelPaidBooking(id: string | undefined) {
    this.setState({
      cancelButtonLoading: true,
      userProfileBookingExist: false,
    });

    if (id === undefined) return;
    const success = await BookingApi.cancelPaidBooking(id);
    if (success) {
    } else {
    }

    setTimeout(() => {
      this.setState({ cancelButtonLoading: false });

      window.location = "https://www.taxigoteborg.se/?screen=mypages" as any;
    }, 1000);
  }

  async SendReceipt(id: string | undefined, appUserId: string | undefined) {
    this.setState({ isReceiptSentModalOpen: true });
    
    let newUserId = "";
    
    if (id === undefined) return;
    this.setState({
      isSendReceiptModalDisabled: true,
    });

    if(appUserId != undefined) {newUserId = appUserId};
    if(appUserId == undefined)
    {
      const user = await UserApi.getCurrentProfile();
      if (!user) return;
      newUserId = user.userProfile.id;
    }
    
    const updatedUser = await UserApi.getUpdateCurrentProfile(
      newUserId
    );
    if (
      updatedUser.userProfile.email == "" ||
      updatedUser.userProfile.email == undefined
    ) {
      this.setState({
        receiptModalText:
          "Vänligen kontrollera din e-postadress och försök igen",
        isSendReceiptModalDisabled: false,
      });
    } else {
      this.setState({
        receiptModalText: "Kvitto skickas",
        isSendReceiptModalDisabled: false,
        userEmail: updatedUser.userProfile.email,
      });
        const success = await UserApi.SendReceipt(id);
        if(success){
          this.setState({receiptModalText : "Kvittot har skickats"})
          setTimeout(() => {
            this.setState({isReceiptSentModalOpen: false});
          }, 5000);
        }
        else{
          this.setState({receiptModalText : "Kvittot kunde inte skickats"})
        }
    }
  }

  CalculateDiscount = (priceBeforeOffering: number, price: number) => {
    let sum = priceBeforeOffering - price;
    let result = sum.toString();
    if (sum === 0) {
      return;
    }

    return (
      <div className="list-group-item">
        <p className="fw-bold">
          Rabatt <br />
        </p>
        <small className="kvitto-text-bold">
          {"Avdraget: " + result + "kr"}
        </small>
        <br />
      </div>
    );
  };

  cleanDateTime = (pickupTime: string | undefined) => {
    if (!pickupTime) return;

    const dateTime = new Date(Date.parse(pickupTime));

    const timeString = dateTime.toString();
    var timeSplit = timeString.split(" ");

    var month = timeSplit[1];
    const translatedMonth = this.translateToSwe(month);

    const day = timeSplit[2];

    var hourMin = timeSplit[4].split(":");
    const hourMinString = hourMin[0] + ":" + hourMin[1];

    const dateTimeString = day + " " + translatedMonth + " " + hourMinString;

    return dateTimeString;
  };

  translateToSwe = (month: string) => {
    if (month === "Jan") return "januari";
    if (month === "Feb") return "februari";
    if (month === "Mar") return "mars";
    if (month === "Apr") return "april";
    if (month === "May") return "maj";
    if (month === "Jun") return "juni";
    if (month === "Jul") return "juli";
    if (month === "Aug") return "augusti";
    if (month === "Sep") return "september";
    if (month === "Oct") return "oktober";
    if (month === "Nov") return "november";
    if (month === "Dec") return "december";
    else {
      return month;
    }
  };
}
