import * as App from "pubsub-js";
import * as React from "react";
import { UncontrolledCollapse } from "reactstrap";

export interface CollapseListProps {
  id: string;
  loading?: boolean;
  style?: any;
  items: CollapseListItem[];
}

export interface CollapseListItem {
  title?: React.ReactNode;
  content?: React.ReactNode;
  command?: string;
  data?: any;
  isOpen?: boolean;
}

export class ModifiedCollapseList extends React.Component<
  CollapseListProps,
  {}
> {
  constructor(props: CollapseListProps) {
    super(props);
  }
  public render() {
    return (
      <React.Fragment>
        <div className="list-group" style={this.props.style}>
          {this.props.items.map((item, i) => {
            return (
              <div
                key={i}
                style={{ cursor: "pointer" }}
                className=""
                onClick={() => {
                  if (item.command != undefined)
                    App.publish(item.command, item.data);
                }}
              >
                <div id={this.props.id + "cl" + i}>{item.title}</div>
                <UncontrolledCollapse
                  toggler={"#" + this.props.id + "cl" + i}
                  defaultOpen={item.isOpen}
                >
                  {item.content}
                </UncontrolledCollapse>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}
