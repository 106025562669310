/* Polyfills for ie-11 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

//import 'bootstrap/dist/css/bootstrap.css';
import "./theme/bootstrap-wo-typograph.css"; //typograph removed
// * Fonts */
import "./theme/fonts.css";
//** Local App css */
import "./theme/site.css";

import React from "react";
import ReactDOM from "react-dom";
import { AppCore } from "./backbone/AppCore";
import { Global } from "./webparts/Global";
import { UserProfile } from "./webparts/UserProfile";
import { History } from "./webparts/History";
import { Booking } from "./webparts/Booking";

import { LanguageProvider } from "./backbone/Language";
import { MyPages } from "./webparts/MyPages";

const appCore = new AppCore();

const global = document.getElementById("global");
if (global != undefined) ReactDOM.render(<Global />, global);

const userprofile = document.getElementById("userprofile");
if (userprofile != undefined) ReactDOM.render(<UserProfile />, userprofile);

const userprofile_history = document.getElementById("userprofile-history");
if (userprofile_history != undefined)
  ReactDOM.render(<History />, userprofile_history);



const booking = document.getElementById("booking-widget");
if (booking != undefined) ReactDOM.render(<Booking />, booking);

const mypages = document.getElementById("my-pages");
if (mypages != undefined) ReactDOM.render(<MyPages />, mypages);


