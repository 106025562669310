import { first, keyBy } from "lodash";
import phone from "phone";
import * as App from "pubsub-js";
import * as React from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Container,
  Fade,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { ContentItem } from "../../api/Content";
import { GlobalAppsettings } from "../../backbone/GlobalSettings";
import { LanguageContext } from "../../backbone/Language";
import { Loading } from "../../components/base/Loading";
import { InfoPanel } from "../../panels/InfoPanel";
import { ValidationInfo } from "../MyPages";
import { BaseResultResponse } from "../types/BaseResultResponse";
import "../styles/Login.css";
import ComponentDidMount from "../../components/ComponentDidMount";
import Recaptcha from 'react-recaptcha-that-works';

export interface LoginComponentState {
  loading: boolean;
  mobileValid: boolean;
  mobileNum: string;
  errorMessage?: string;
  codePart1: string;
  codePart2: string;
  codePart3: string;
  codePart4: string;
  isCodeSent: boolean;
  validUser: boolean;
  token?: string;
  regexp: any;
  refsName: string;
  codeInput?: string;
  formEnter: boolean;
  showBackButton: boolean;
  showCancelBookingButton: boolean;
  showBackWithoutCancelBooking: boolean;
  showBackAndRefresh: boolean;
}

export interface ComponentOnChangeEvent {
  message: string;
}

export interface LoginComponentProps {
  modalOpen: string;
  onChange?: (state: ComponentOnChangeEvent) => void;
}

export class LoginComponent extends React.Component<
  LoginComponentProps,
  LoginComponentState
> {
  static displayName = "LoginComponent";
  static contextType = LanguageContext;
  public recaptcha;

  refA: React.RefObject<HTMLInputElement>;
  refB: React.RefObject<HTMLInputElement>;
  refC: React.RefObject<HTMLInputElement>;
  refD: React.RefObject<HTMLInputElement>;

  constructor(props: LoginComponentProps) {
    super(props);

    this.state = {
      loading: false,
      mobileNum: "",
      mobileValid: false,
      codePart1: "",
      codePart2: "",
      codePart3: "",
      codePart4: "",
      isCodeSent: false,
      validUser: false,
      regexp: /^[0-9\b]+$/,
      refsName: "",
      formEnter: true,
      showBackButton: false,
      showCancelBookingButton: false,
      showBackWithoutCancelBooking: false,
      showBackAndRefresh: false,
    };

    this.refA = React.createRef();
    this.refB = React.createRef();
    this.refC = React.createRef();
    this.refD = React.createRef();

    this.checkLoginStatus = this.checkLoginStatus.bind(this);
  }

  componentDidMount() {
    this.checkLoginStatus();
  }

  checkLoginStatus() {
    fetch(
      GlobalAppsettings.BackEndBaseUrl + "api/profile/checkloginstatus",
      GlobalAppsettings.GetPostInit({})
    )
      .then((response) => response.json() as Promise<ValidationInfo>)
      .then((data) => {
        if (data.success) {
          this.setState({
            loading: false,
            validUser: true,
            token: data.token,
            errorMessage: undefined,
          });
          if (this.props.onChange != undefined) {
            this.props.onChange({ message: data.token });
          }
        } else {
          this.setState({
            loading: false,
            validUser: false,
            errorMessage: data.errorMessage,
          });
        }
      })
      .catch((reason) => {
        this.setState({
          loading: false,
          validUser: false,
          errorMessage: "Ett fel uppstod",
        });
      });
  }

  handleChildrenMount = () => {
    React.Children.forEach(this.props.children, (child) => {
      if (!child) return;
      if (typeof child !== "object") return;

      if (
        typeof (
          child as
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | React.ReactPortal
        ).type !== "string"
      )
        return;

      const state = (
        child as
          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
          | React.ReactPortal
      ).props["data-back_state"];
      switch (state) {
        case "hidden":
          this.setState({
            showBackButton: false,
            showCancelBookingButton: false,
            showBackWithoutCancelBooking: false,
            showBackAndRefresh: false,
          });
          break;
        case "cancel_booking":
          this.setState({
            showBackButton: true,
            showCancelBookingButton: false,
            showBackWithoutCancelBooking: false,
            showBackAndRefresh: false,
          });
          break;
        case "cancel_paid_booking":
          this.setState({
            showBackButton: false,
            showCancelBookingButton: true,
            showBackWithoutCancelBooking: false,
            showBackAndRefresh: false,
          });
          break;
        case "back_only":
          this.setState({
            showBackButton: false,
            showCancelBookingButton: false,
            showBackWithoutCancelBooking: true,
            showBackAndRefresh: false,
          });
          break;
        case "back_and_refresh":
          this.setState({
            showBackButton: false,
            showCancelBookingButton: false,
            showBackWithoutCancelBooking: false,
            showBackAndRefresh: true,
          });
          break;
        default:
          break;
      }
    });
  };

  render() {
    // const childrenWithProp = React.Children.map(
    //   this.props.children,
    //   (child) => {
    //     if (React.isValidElement(child)) {
    //       return React.cloneElement(child);§
    //     }
    //   }
    // );
    return (
      <React.Fragment>
        <LanguageContext.Consumer>
          {(lang) => (
            <>
              <InfoPanel
                title="title"
                cancelButtonLabel="Tillbaka"
                showCancelButton={this.state.showBackButton}
                showCancelPaymentButton={this.state.showCancelBookingButton}
                showBackWithoutCancelBooking={
                  this.state.showBackWithoutCancelBooking
                }
                showBackAndRefresh={this.state.showBackAndRefresh}
                modalPanelOpen={this.props.modalOpen}
                position="modal-panel-right"
                showHeader={false}
                onClosed={undefined}
              >
                <div
                  className="yc-panel"
                  style={{ minWidth: "550px", width: "550px", margin: "-20px" }}
                >
                  <Fade>
                    <ComponentDidMount onMount={this.checkLoginStatus} />
                    <Container
                      className="m-0"
                      style={{ minWidth: "550px", width: "550px" }}
                    >
                      <ComponentDidMount
                            onMount={this.handleChildrenMount}
                          />
                      {this.state.validUser === false ? (
                        this.renderLogin()
                      ) : (
                        <>
                          {this.props.children}
                        </>
                      )}
                    </Container>
                  </Fade>
                </div>
              </InfoPanel>
            </>
          )}
        </LanguageContext.Consumer>
      </React.Fragment>
    );
  }

  private renderLogin() {
    return (
      <>
        <Row style={{ backgroundColor: "#ffcb05", height: "150px" }}>
          <Col md="6" className="p-3 align-content-center" style={{}}>
            <h2
              className="heading textunderline m-3"
              style={{
                borderBottom: "0.07em solid #fff",
                maxWidth: "167px",
              }}
            >
              Logga in
            </h2>
          </Col>
        </Row>
        <Loading style={{ minHeight: "400px" }} loading={this.state.loading}>
          <Recaptcha
              ref={(ref: any) => this.recaptcha = ref}
              siteKey={"6LeXtHomAAAAALIQM9NNC1CRd5wvEVayQJ8SfNT-"}
              onVerify={(token: string) => {
                this.sendCode(token);
              }}
              onError={(error: string) => {
                alert("reCAPTCHA misslyckades, vänligen försök igen");
              }}
              size="invisible"
          />
          <Form className="ms-5 me-5" onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            this.recaptcha?.execute();
          }}>
            <Row className="m-4">
              <Col xs="10">
                <h4>Ange mobilnummer för att logga in:</h4>
              </Col>
            </Row>
            <Row className="login-send-code-container">
              {/* <Row className="p-2 d-flex justify-content-center"> */}
              <Col className="login-phone-col">
                <div>
                  <FormGroup floating className="col-8">
                    <Input
                      className="login-phone-input"
                      id="mobileInput"
                      name="mobile"
                      placeholder="mobilnummer"
                      type="text"
                      valid={this.state.mobileValid}
                      value={this.state.mobileNum}
                      onChange={(event) => this.onMobileChange(event)}
                      style={{ fontSize: "1.5em" }}
                      onKeyDown={(e) => {
                        this.setState({ formEnter: true })
                      }}
                      // onKeyDown={() => this.setState({ formEnter: true })}

                    />
                    <Label for="mobileInput">
                      <div style={{ marginLeft: "10px" }}>Mobilnummer</div>
                    </Label>
                  </FormGroup>
                </div>
              </Col>
              {/* </Row>
            <Row className="m-4"> */}
              <Col className="login-send-code-col">
                <div>
                  {/* <Col className="p-3 d-flex flex-row-reverse"> */}
                  <Button
                    className="login-send-code-button"
                    color="dark"
                    size="lg"
                    
                  >
                    Skicka kod
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
          <Form className="ms-5 me-5" onSubmit={e => {
            e.preventDefault()

            this.validateCode()
          }}>
            {this.state.isCodeSent ? (
              <>
                <Row className="m-4">
                  <Col xs="10">
                    <h4>Ange koden som skickades till din mobil:</h4>
                  </Col>
                </Row>
                <Row className="login-send-code-row">
                  <Col className="mobile-input-code-digit-col">
                    {/* <Row className="m-4 d-flex justify-content-center"> */}
                    <Row className="mobile-input-code-digit-row">
                      <Col className="mobile-input-code-digit-box" xs="2">
                        <Input
                          className="mobile-input-code-digit"
                          id="mobileInput1"
                          name="mobile"
                          type="text"
                          maxLength={1}
                          style={{
                            borderBottom: "0.3em solid black",
                            textAlign: "center",
                          }}
                          value={this.state.codePart1}
                          onChange={(event) => this.onCodeChange(1, event)}
                          onKeyDown={(e) => this.backspaceHandler(e, 1)}
                          innerRef={this.refA}
                        ></Input>
                      </Col>
                      <Col className="mobile-input-code-digit-box" xs="2">
                        <Input
                          className="mobile-input-code-digit"
                          id="mobileInput2"
                          name="mobile"
                          type="text"
                          maxLength={1}
                          style={{
                            borderBottom: "0.3em solid black",
                            textAlign: "center",
                          }}
                          value={this.state.codePart2}
                          onChange={(event) => this.onCodeChange(2, event)}
                          onKeyDown={(e) => this.backspaceHandler(e, 2)}
                          innerRef={this.refB}
                        ></Input>
                      </Col>
                      <Col className="mobile-input-code-digit-box" xs="2">
                        <Input
                          className="mobile-input-code-digit"
                          id="mobileInput3"
                          name="mobile"
                          type="text"
                          maxLength={1}
                          style={{
                            borderBottom: "0.3em solid black",
                            textAlign: "center",
                          }}
                          value={this.state.codePart3}
                          onChange={(event) => this.onCodeChange(3, event)}
                          onKeyDown={(e) => this.backspaceHandler(e, 3)}
                          innerRef={this.refC}
                        ></Input>
                      </Col>
                      <Col className="mobile-input-code-digit-box" xs="2">
                        <Input
                          className="mobile-input-code-digit"
                          id="mobileInput4"
                          name="mobile"
                          type="text"
                          maxLength={1}
                          style={{
                            borderBottom: "0.3em solid black",
                            textAlign: "center",
                          }}
                          value={this.state.codePart4}
                          onChange={(event) => this.onCodeChange(4, event)}
                          onKeyDown={(e) => this.backspaceHandler(e, 4)}
                          innerRef={this.refD}
                        ></Input>
                      </Col>
                    </Row>
                  </Col>
                  {/* </Row> */}
                  {/* {this.state.errorMessage ? (
                  <Row className="m-4">
                    <Col className="p-3 ">
                      <Alert color="danger">{this.state.errorMessage}</Alert>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )} */}
                  {/* <Row className="m-4"> */}
                  <Col className="p-3 d-flex flex-row-reverse">
                    <Button
                      className="login-with-code-button"
                      color="dark"
                      size="lg"
                      onClick={() => this.validateCode()}
                    >
                      Logga in
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
          </Form>
        </Loading>
      </>
    );
  }

  private validatePhoneNumber() {
    var isValid: boolean = false;

    var firstDigit = this.state.mobileNum.charAt(0);
    var numLength = this.state.mobileNum.length;

    if (firstDigit === "0" && numLength === 10) {
      isValid = true;
    }

    return isValid;
  }

  private sendCode(reCaptchaToken: string): void {
    this.setState({ loading: true });

    const { isValid, phoneNumber } = phone(this.state.mobileNum, {
      country: "se",
    });

    var isValidPhoneNumber: boolean = false;

    isValidPhoneNumber = this.validatePhoneNumber();

    if (!isValidPhoneNumber) {
      this.setState({ loading: false });
      return;
    }

    fetch(
      GlobalAppsettings.BackEndBaseUrl + "api/profile/login",
      GlobalAppsettings.GetPostInit({
          mobile: phoneNumber,
        }, 
        reCaptchaToken
      )
    ).then((response) => {
      console.info("Login ->");
      response.headers.forEach(console.log);

      if (response.headers.has(GlobalAppsettings.tokenHeader)) {
        let token = response.headers.get(GlobalAppsettings.tokenHeader);
        console.info(token);
        if (token != undefined) GlobalAppsettings.SetToken(token);
      }

      var data = response.json() as Promise<BaseResultResponse>;
      data
        .then((data) => {
          if (data.success) {
            this.setState({
              loading: false,
              isCodeSent: true,
              errorMessage: undefined,
            });
          } else {
            this.setState({
              loading: false,
              isCodeSent: false,
              errorMessage: data.errorMessage,
            });
          }
        })
        .catch((reason) => {
          this.setState({
            loading: false,
            isCodeSent: false,
            errorMessage: "Ett fel uppstod",
          });
        });
    });
  }

  private validateCode(): void {
    this.setState({ loading: true });

    let currentCode =
      this.state.codePart1 +
      this.state.codePart2 +
      this.state.codePart3 +
      this.state.codePart4;

    fetch(
      GlobalAppsettings.BackEndBaseUrl + "api/profile/validate",
      GlobalAppsettings.GetPostInit({
        code: currentCode,
      })
    )
      .then((response) => response.json() as Promise<ValidationInfo>)
      .then((data) => {
        if (data.success) {
          this.setState({
            loading: false,
            validUser: true,
            token: data.token,
            errorMessage: undefined,
          });
          if (this.props.onChange != undefined) {
            this.props.onChange({ message: data.token });
          }
        } else {
          this.setState({
            loading: false,
            validUser: false,
            errorMessage: data.errorMessage,
          });
        }
      })
      .catch((reason) => {
        this.setState({
          loading: false,
          validUser: false,
          errorMessage: "Ett fel uppstod",
        });
      });
  }

  backspaceHandler = (e: any, part: number) => {
    if (e.keyCode === 8)
      switch (part) {
        case 1:
          if (this.state.codePart1.length < 1) {
            this.focusCodeInput(this.refA);
          }
          break;
        case 2:
          if (this.state.codePart2.length < 1) {
            this.focusCodeInput(this.refA);
          }
          break;
        case 3:
          if (this.state.codePart3.length < 1) {
            this.focusCodeInput(this.refB);
          }
          break;
        case 4:
          if (this.state.codePart4.length < 1) {
            this.focusCodeInput(this.refC);
          }
          break;
      }


    else if(e.keyCode === 13){
      this.validateCode()
    }


  };

  setCodeInputRef = (e: any) => {
    if (!e) return;
    this.setState({ codeInput: e });
  };

  focusCodeInput = (s: React.RefObject<HTMLInputElement>) => {
    // const input = this.state.codeInput;
    if (!s.current) return;
    // input.focus();
    s.current.focus();
  };

  private onCodeChange(
    part: number,
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    let codeDigit = event.target.value;

    if (codeDigit === "" || this.state.regexp.test(codeDigit)) {
      switch (part) {
        case 1:
          this.setState({ codePart1: event.target.value });
          if (event.target.value.length > 0) {
            this.focusCodeInput(this.refB);
          }
          break;
        case 2:
          this.setState({ codePart2: event.target.value });
          if (event.target.value.length > 0) {
            this.focusCodeInput(this.refC);
          }
          break;
        case 3:
          this.setState({ codePart3: event.target.value });
          if (event.target.value.length > 0) {
            this.focusCodeInput(this.refD);
          }
          break;
        case 4:
          this.setState({ codePart4: event.target.value });
          if (event.target.value.length > 0) {
            this.focusCodeInput(this.refD);
          }
          break;
      }
    }
  }

  private onMobileChange(event: React.ChangeEvent<HTMLInputElement>): void {
    let mobileNumber = event.target.value;
    if (
      mobileNumber === "" ||
      (this.state.regexp.test(mobileNumber) && mobileNumber.length < 11)
    ) {
      this.setState({ mobileNum: mobileNumber });
    }
  }
}
