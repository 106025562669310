import { mdiConsoleLine, mdiThermometerMinus } from "@mdi/js";
import { time } from "console";
// import { delay, identity } from "lodash";
import moment from "moment";
import momenttz from "moment-timezone";
import * as App from "pubsub-js";
import * as React from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  Row,
} from "reactstrap";
import { runInThisContext } from "vm";
import { GlobalAppsettings } from "../../backbone/GlobalSettings";
import { LanguageContext } from "../../backbone/Language";
import { Loading } from "../../components/base/Loading";
import { CollapseListItem } from "../../components/CollapseList";
import ComponentDidMount from "../../components/ComponentDidMount";
import { ModifiedCollapseList } from "../../components/ModifiedCollapseList";
import OfferTile from "../../components/OfferTile";
import { BookingApi } from "../api/BookingApi";
import { ExtraServicesApi } from "../api/GetExtraServices";
import { GetPriceApi } from "../api/GetPriceApi";
import { UserApi } from "../api/GetUserApi";
import "../styles/ExtraServices.css";
import "../styles/FlightNumber.css";
import {
  BookingInfo,
  BookingRequest,
  Place,
  ValidationInfo,
} from "../types/Booking";
import {
  BookingReceiptData,
  CreateBookingRequest,
} from "../types/CreateBookingRequest";
import { CreateBookingResponse } from "../types/CreateBookingResponse";
import { ExtraService } from "../types/ExtraService";
import { GetPriceRequestModel } from "../types/GetPrice";
import {
  AddressFromDetails,
  AddressToDetails,
  FromAddressWithPlaceId,
  ToAddressWithPlaceId,
} from "../types/SearchAddresses";
import { OfferingType, UserProfileResult } from "../types/UserProfile";
import { UserProfile } from "../UserProfile";
// import BookingReceiptComponent from "./BookingReceiptComponent";
import BookingReceiptInCarPaymentComponent from "./BookingReceiptInCarPaymentComponent";
import ExtraServiceOption, {
  CarOptionIcons,
  ExtraServiceIcons,
} from "./ExtraServiceOption";
import { LoginComponent } from "./LoginComponent";

const addonsCarType = [
  {
    carTypeName: CarOptionIcons.Normal,
    priceType: "FixedPrice",
    description: "1 - 4 personer",
    checked: true,
  },
  {
    carTypeName: CarOptionIcons.Combi,
    priceType: "FixedPrice",
    description: "1 - 4 personer",
    checked: false,
  },
  {
    carTypeName: CarOptionIcons.Minibus,
    priceType: "FixedPriceLargeVehicle",
    description: "1 - 8 personer",
    checked: false,
  },
];

const addonsChildren = [
  {
    childItemName: "carSeat",
    price: 100,
    checked: false,
  },
  {
    childItemName: "babySeat",
    price: 100,
    checked: false,
  },
];

const addonsPet = [
  {
    petItemName: "Pet",
    checked: false,
  },
];

export interface BookingComponentState {
  cursor: number;
  date: string;
  dateMin: string;
  dateMax: string;
  time: string;
  current?: BookingRequest;
  loading: boolean;
  token?: string;
  isDirty: boolean;
  validUser: boolean;
  travelNow: boolean;
  travelLater: boolean;
  priceLoading: boolean;
  errorMessage?: string;
  price?: number;
  getPriceRequestModel: GetPriceRequestModel;
  fromValid: boolean;
  toValid: boolean;
  showToResult: boolean;
  showFromResult: boolean;
  priceStatus: string;
  // from?: string;
  // to?: string;
  fromAddressWithPlaceId: FromAddressWithPlaceId;
  addressFromDetails: AddressFromDetails;
  toAddressWithPlaceId: ToAddressWithPlaceId;
  addressToDetails: AddressToDetails;
  carType: number;
  carTypeName: CarOptionIcons;
  childTypeName: string;
  petTypeName: string;
  createBookingResponse: CreateBookingResponse;
  createBookingRequest: CreateBookingRequest;
  extraServices: ExtraService[];
  extraServicesCost: number;
  selectedExtraServices: string[];
  flightNumber: string;
  messageToDriver: string;
  currentUser: UserProfileResult;
  offering: string;
  existingBooking: boolean;
  isAirport: boolean;
  numberOfPassengers: number;
  childSeatSet: boolean;
  childSeatCost: number;
  babySeatSet: boolean;
  babySeatCost: number;
  userProfile: UserProfileResult | null;
  extraServiceNames: string[];
  offeringType: number;
  offeringPercent: number;
  offeringFixed: number;
  offeringTitle: string;
  carTypeIndex: number;
  numberOfPersons: string;
  userId: string;
  componentInitiated: boolean;
  getPriceRunning: boolean;
  inValidFromMessage: string;
  inValidToMessage: string;
  fromInValid: boolean;
  toInValid: boolean;
  buttonIsDisabled: boolean;
  lastSelectedDate: string;
  lastSelectedTime: string;
  inValidFlightMessage: string;
  originalPrice: number;
  showOriginalPrice: boolean;
  administrationFee: number;
  airportFee: number;
}

export interface BookingComponentProps {}

export class BookingComponent extends React.Component<
  BookingComponentProps,
  BookingComponentState
> {
  static displayName = "BookingComponent";
  static contextType = LanguageContext;

  constructor(props: BookingComponentProps) {
    super(props);

    this.state = {
      cursor: 0,
      date: "",
      dateMin: "",
      dateMax: "",
      time: "",
      loading: false,
      isDirty: false,

      travelNow: true,
      travelLater: false,
      validUser: false,
      priceLoading: false,
    
      showFromResult: false,
      showToResult: false,
      fromValid: true,
      toValid: true,
      priceStatus: "",
      // from: "", //this.state.current?.pickup.title,
      // to: "", //this.state.current?.dropOff.title,
      price: 0, //this.state.current?.price,
      getPriceRequestModel: {
        userId: "",
        pickUpTime: "",
        pickup: {
          title: "",
          lat: "",
          long: "",
          GooglePlaceId: "",
        },
        dropOff: {
          title: "",
          lat: "",
          long: "",
          GooglePlaceId: "",
        },
        numberOfPassengers: 0,
        via: null,
        carType: 0,
        extraServices: null,
        message: null,
        pickupTimeASAP: true,
        offeringId: "",
      } as GetPriceRequestModel,
      fromAddressWithPlaceId: {
        id: "",
        googlePlaceId: "", //this.state.current?.pickup.GooglePlaceId,
        addressDetails: [],
      } as FromAddressWithPlaceId,
      addressFromDetails: { fromAddressWithPlaceId: [] } as AddressFromDetails,
      toAddressWithPlaceId: {
        id: "",
        googlePlaceId: "",
        addressDetails: [],
      } as ToAddressWithPlaceId,
      addressToDetails: { toAddressWithPlaceId: [] } as AddressToDetails,
      carType: 0,
      carTypeName: CarOptionIcons.Normal,
      childTypeName: "Inget valt",
      petTypeName: "Inget valt",
      createBookingResponse: { paymentId: "" } as CreateBookingResponse,
      createBookingRequest: {} as CreateBookingRequest,
      extraServices: [],
      selectedExtraServices: [],
      flightNumber: "",
      messageToDriver: "",
      currentUser: {} as UserProfileResult,
      offering: "",
      existingBooking: false,
      extraServicesCost: 0,
      isAirport: false,
      numberOfPassengers: 1,
      childSeatSet: false,
      childSeatCost: 0,
      babySeatSet: false,
      babySeatCost: 0,
      userProfile: null,
      extraServiceNames: [],
      offeringType: 3,
      offeringPercent: 0,
      offeringFixed: 0,
      offeringTitle: "",
      carTypeIndex: 0,
      numberOfPersons: "1-4 personer",
      userId: "",
      componentInitiated: false,
      getPriceRunning: false,
      fromInValid: false,
      toInValid: false,
      inValidFromMessage: "",
      inValidToMessage: "",
      buttonIsDisabled: false,
      lastSelectedDate: "",
      lastSelectedTime: "",
      inValidFlightMessage: "",
      originalPrice: 0,
      showOriginalPrice: false,
      administrationFee: 0,
      airportFee: 0,
    };

    this.open = this.open.bind(this);
    this.createBooking = this.createBooking.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();

    App.subscribe("open.booking", this.open);

    App.subscribe("bookingReceipt", (message: any, data: any) =>
      setTimeout(() => {
        this.setState({
          priceLoading: false,
          buttonIsDisabled: false,
        });
      }, 500)
    );
  }

  open(msg: string, data: BookingInfo) {
    if (data != undefined) {
      let booking: BookingRequest = {
        pickUpTime: data.priceRequest.pickUpTime,
        pickup: {
          title: data.priceRequest.pickup.title,
          GooglePlaceId: data.priceRequest.pickup.GooglePlaceId,
        } as Place,
        dropOff: {
          title: data.priceRequest.dropOff.title,
          GooglePlaceId: data.priceRequest.dropOff.GooglePlaceId,
        } as Place,
        numberOfPassengers: 1,
        carType: 0,
        extraServices: [],
        message: "",
        pickupTimeASAP: data.travelNow,
        price: data.price + data.administrationFee + data.airportFee,
        administrationFee: data.administrationFee,
        airportFee: data.airportFee
      };


      var dateTimeString: string = "";
      var dateTimeStringFormated: string = "";
      if (!data.travelNow) {
        dateTimeString = data.priceRequest.pickUpTime;
        const dateTimeSplit = data.priceRequest.pickUpTime.split("T");
        const date = dateTimeSplit[0];
        const time = dateTimeSplit[1];

        dateTimeString = date + "T" + time;
        dateTimeStringFormated = momenttz(dateTimeString).tz("Europe/Stockholm", true).format();

        this.setState({
          date : data.date,
          time : data.time,
          travelNow: false,
          travelLater: true,
          extraServices: [],
          selectedExtraServices: [],
          offering: "",
          lastSelectedDate: data.date,
          lastSelectedTime: data.time,
          administrationFee: data.administrationFee
        });
      } else {
        let newDateTime = new Date();
        const newDate = newDateTime.toLocaleString().split(" ")[0];
        const newTime = newDateTime.toLocaleString().split(" ")[1];
        const newTimeSplitA = newTime.split(".")[0];
        const newTimeSplitB = newTimeSplitA.split(":");
        const newTimeString = newTimeSplitB[0] + ":" + newTimeSplitB[1];

        dateTimeString = newDate + "T" + newTime;
        dateTimeStringFormated = momenttz(dateTimeString).tz("Europe/Stockholm", true).format();

        this.setState({
          date: newDate,
          time: newTimeString,
          lastSelectedDate: newDate,
          lastSelectedTime: newTimeString,
          travelNow: true,
          travelLater: false,
          extraServices: [],
          selectedExtraServices: [],
          offering: "",
          extraServiceNames: [],
          messageToDriver: "",
          flightNumber: "",
          administrationFee: data.administrationFee
        });

        // Validate date
        const dateNow = new Date();
        const newDateNow = moment(dateNow).format("YYYY-MM-DD");
        const dateNowPlusOneYear: Date = new Date(
          dateNow.setFullYear(dateNow.getFullYear() + 1)
        );
        const newDateNowPlusOneYear =
          moment(dateNowPlusOneYear).format("YYYY-MM-DD");
        this.setState({
          dateMin: newDateNow,
          dateMax: newDateNowPlusOneYear,
          inValidFlightMessage: (data.isAirport) ? "Vänligen ange flightnummer." : ""
        });
        //
      }

      

      booking.pickUpTime = dateTimeStringFormated;

      let googlePlaceIds = this.state.getPriceRequestModel;
      if (googlePlaceIds === undefined) return;
      googlePlaceIds.pickup.GooglePlaceId = booking.pickup.GooglePlaceId;
      googlePlaceIds.dropOff.GooglePlaceId = booking.dropOff.GooglePlaceId;
      googlePlaceIds.pickup.title = booking.pickup.title;
      googlePlaceIds.dropOff.title = booking.dropOff.title;

      this.setState({
        current: booking,
        getPriceRequestModel: googlePlaceIds,
        isAirport: data.isAirport,
        childSeatCost: 0,
        babySeatCost: 0,
        administrationFee: booking.administrationFee
      });

      this.setState({ componentInitiated: true });

      App.publish("open.booking.modal", null);

      setTimeout(() => {
        this.setCarType("Standard");
      }, 1);
    }
  }

  getExtraServices = async () => {
    const [services, user] = await Promise.all([
      ExtraServicesApi.getActiveExtraServices(),
      UserApi.getCurrentProfile(),
    ]);

    this.setState({ currentUser: user, extraServices: services });
  };

  createBooking() {
    fetch(
      GlobalAppsettings.BackEndBaseUrl + "api/booking/CreateBooking",
      GlobalAppsettings.GetPostInit(this.state.current)
    )
      .then((response) => response.json() as Promise<ValidationInfo>)
      .then((data) => {
        if (data.success) {
          this.setState({
            loading: false,
            validUser: true,
            isDirty: true,
            token: data.token,
            errorMessage: undefined,
          });
        } else {
          this.setState({
            loading: false,
            validUser: false,
            errorMessage: data.errorMessage,
          });
        }

        App.publish("open.booking.modal", null);
      })
      .catch((reason) => {
        this.setState({
          loading: false,
          validUser: false,
          errorMessage: "Ett fel uppstod",
        });
      });
  }

  selectTo(e: boolean): void {
    this.setState({ showToResult: e });
    this.setState({ showFromResult: e });
  }

  async onFromChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    let data = this.state.current;
    if (data === undefined) return;
    data.pickup.title = value;

    this.setState({
      current: data,
      fromInValid: false,
      inValidFromMessage: "",
    });

    if (value.length > 2) {
      const response = await BookingApi.getSearchAddresses(value);
      let updatedAddressDetails = {} as AddressFromDetails;

      response.results.forEach((element) => {
        const myArray = element.streetName.split(",");
        let fromAddressWithPlaceId = {
          id: new Date().valueOf().toString(),
          googlePlaceId: element.googlePlaceId,
          addressDetails: myArray,
        } as FromAddressWithPlaceId;
        if (!updatedAddressDetails.fromAddressWithPlaceId) {
          updatedAddressDetails.fromAddressWithPlaceId = [];
        }
        updatedAddressDetails.fromAddressWithPlaceId.push(
          fromAddressWithPlaceId
        );
      });
      this.setState({
        addressFromDetails: updatedAddressDetails,
      });

      this.setState({
        showFromResult: true,
        fromValid: false,
      });
    }

    if (value.length < 3) {
      this.setState({
        showFromResult: false,
        fromValid: false,
      });
    }
  }

  async onToChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    let data = this.state.current;
    if (data === undefined) return;
    data.dropOff.title = value;

    this.setState({
      current: data,
      toInValid: false,
      inValidToMessage: "",
    });

    if (value.length > 2) {
      const response = await BookingApi.getSearchAddresses(value);
      let updatedAddressDetails = {} as AddressToDetails;

      response.results.forEach((element) => {
        const myArray = element.streetName.split(",");
        let toAddressWithPlaceId = {
          id: new Date().valueOf().toString(),
          googlePlaceId: element.googlePlaceId,
          addressDetails: myArray,
        } as ToAddressWithPlaceId;
        if (!updatedAddressDetails.toAddressWithPlaceId) {
          updatedAddressDetails.toAddressWithPlaceId = [];
        }
        updatedAddressDetails.toAddressWithPlaceId.push(toAddressWithPlaceId);
      });
      this.setState({
        addressToDetails: updatedAddressDetails,
      });

      this.setState({
        showToResult: true,
        toValid: false,
      });
    }

    if (value.length < 3) {
      this.setState({
        showToResult: false,
        toValid: false,
      });
    }
  }

  async onAddFromAddress(address: string, addressInfo: FromAddressWithPlaceId) {
    const addressDetails = await BookingApi.getAddressNumber(
      addressInfo.googlePlaceId
    );

    let currentData = this.state.current;
    if (currentData === undefined) return;
    currentData.pickup.title = address;
    this.setState({ current: currentData, showFromResult: false });

    let data = this.state.getPriceRequestModel;
    data.pickup.GooglePlaceId = addressInfo.googlePlaceId;
    data.pickup.title = address;
    this.setState({ getPriceRequestModel: data });

    if (addressDetails.type !== "Street") {
      this.setState({ fromValid: true });
    } else if (addressDetails.type === "Street") {
      this.setState({
        inValidFromMessage: "Vänligen ange gatunummer.",
        fromInValid: true,
      });
    }

    this.getPrice(
      this.state.offering,
      true,
      this.state.toValid,
      addressInfo.googlePlaceId,
      this.state.getPriceRequestModel.dropOff.GooglePlaceId,
      this.state.date,
      this.state.time,
      this.state.numberOfPassengers,
      this.state.carType,
      this.state.travelNow
    );
    if (
      addressInfo.googlePlaceId === "ChIJa4Q3VAH6T0YRs4SWJS82YCQ" ||
      addressInfo.googlePlaceId === "ChIJHUFIwyGMT0YRm1LHNlZoCnE" ||
      addressInfo.googlePlaceId === "ChIJvQ0uGk6KT0YRmxmx-827XhA"
    ) {
      this.setState({ isAirport: true });
    } else {
      this.setState({ isAirport: false });
    }
  }

  async onAddToAddress(address: string, addressInfo: ToAddressWithPlaceId) {
    const addressDetails = await BookingApi.getAddressNumber(
      addressInfo.googlePlaceId
    );

    let currentData = this.state.current;
    if (currentData === undefined) return;
    currentData.dropOff.title = address;
    this.setState({ current: currentData, showToResult: false });

    let data = this.state.getPriceRequestModel;
    data.dropOff.GooglePlaceId = addressInfo.googlePlaceId;
    data.dropOff.title = address;
    this.setState({ getPriceRequestModel: data });

    if (addressDetails.type !== "Street") {
      this.setState({ toValid: true });
    } else if (addressDetails.type === "Street") {
      this.setState({
        inValidToMessage: "Vänligen ange gatunummer.",
        toInValid: true,
      });
    }

    this.getPrice(
      this.state.offering,
      this.state.fromValid,
      true,
      this.state.getPriceRequestModel.pickup.GooglePlaceId,
      addressInfo.googlePlaceId,
      this.state.date,
      this.state.time,
      this.state.numberOfPassengers,
      this.state.carType,
      this.state.travelNow
    );
  }

  async onFromKeyDownHandler(e: any) {
    const { cursor, addressFromDetails } = this.state;

    let currentData = this.state.current;
    if (currentData === undefined) return;

    if (!addressFromDetails?.fromAddressWithPlaceId) return;
    else {
      if (e.keyCode === 38 && cursor > 0) {
        this.setState((prevState) => ({
          cursor: prevState.cursor - 1,
        }));
      } else if (
        e.keyCode === 40 &&
        cursor < addressFromDetails.fromAddressWithPlaceId.length - 1
      ) {
        this.setState((prevState) => ({
          cursor: prevState.cursor + 1,
        }));
      } else if (e.keyCode === 13) {
        const address = addressFromDetails.fromAddressWithPlaceId[cursor];

        const addressDetails = await BookingApi.getAddressNumber(
          address.googlePlaceId
        );

        currentData.pickup.title =
          address.addressDetails[0] + "," + address.addressDetails[1];

        this.setState({
          showFromResult: false,
          current: currentData,
        });

        let data = this.state.getPriceRequestModel;
        data.pickup.GooglePlaceId = address.googlePlaceId;
        data.pickup.title =
          address.addressDetails[0] + "," + address.addressDetails[1];
        this.setState({ getPriceRequestModel: data });

        if (addressDetails.type !== "Street") {
          this.setState({ fromValid: true });
        } else if (addressDetails.type === "Street") {
          this.setState({
            inValidFromMessage: "Vänligen ange gatunummer.",
            fromInValid: true,
          });
        }

        this.getPrice(
          this.state.offering,
          true,
          this.state.toValid,
          address.googlePlaceId,
          this.state.getPriceRequestModel.dropOff.GooglePlaceId,
          this.state.date,
          this.state.time,
          this.state.numberOfPassengers,
          this.state.carType,
          this.state.travelNow
        );

        if (
          address.googlePlaceId === "ChIJa4Q3VAH6T0YRs4SWJS82YCQ" ||
          address.googlePlaceId === "ChIJHUFIwyGMT0YRm1LHNlZoCnE" ||
          address.googlePlaceId === "ChIJvQ0uGk6KT0YRmxmx-827XhA"
        ) {
          this.setState({ isAirport: true });
        } else {
          this.setState({ isAirport: false });
        }
      }
    }
    this.forceUpdate();
  }

  async onToKeyDownHandler(e: any) {
    const { cursor, addressToDetails } = this.state;

    let currentData = this.state.current;
    if (currentData === undefined) return;

    if (!addressToDetails?.toAddressWithPlaceId) return;
    else {
      if (e.keyCode === 38 && cursor > 0) {
        this.setState((prevState) => ({
          cursor: prevState.cursor - 1,
        }));
      } else if (
        e.keyCode === 40 &&
        cursor < addressToDetails.toAddressWithPlaceId.length - 1
      ) {
        this.setState((prevState) => ({
          cursor: prevState.cursor + 1,
        }));
      } else if (e.keyCode === 13) {
        const address = addressToDetails.toAddressWithPlaceId[cursor];

        const addressDetails = await BookingApi.getAddressNumber(
          address.googlePlaceId
        );

        currentData.dropOff.title =
          address.addressDetails[0] + "," + address.addressDetails[1];

        this.setState({
          showToResult: false,
          current: currentData,
        });

        let data = this.state.getPriceRequestModel;
        data.dropOff.GooglePlaceId = address.googlePlaceId;
        data.dropOff.title =
          address.addressDetails[0] + "," + address.addressDetails[1];
        this.setState({ getPriceRequestModel: data });

        if (addressDetails.type !== "Street") {
          this.setState({ toValid: true });
        } else if (addressDetails.type === "Street") {
          this.setState({
            inValidToMessage: "Vänligen ange gatunummer.",
            toInValid: true,
          });
        }

        this.getPrice(
          this.state.offering,
          this.state.fromValid,
          true,
          this.state.getPriceRequestModel.pickup.GooglePlaceId,
          address.googlePlaceId,
          this.state.date,
          this.state.time,
          this.state.numberOfPassengers,
          this.state.carType,
          this.state.travelNow
        );
      }
    }
  }

  onDateChange(event: any) {
    this.setState({ 
      date: event.target.value,
      lastSelectedDate: event.target.value
    });

    const dateNow = new Date();
    const newDateNow = moment(dateNow).format("YYYY-MM-DD");
    const dateNowPlusOneYear: Date = new Date(
      dateNow.setFullYear(dateNow.getFullYear() + 1)
    );
    const newDateNowPlusOneYear =
      moment(dateNowPlusOneYear).format("YYYY-MM-DD");
    this.setState({ dateMin: newDateNow, dateMax: newDateNowPlusOneYear, offering: "" });

    this.getPrice(
      "",
      this.state.fromValid,
      this.state.toValid,
      this.state.getPriceRequestModel.pickup.GooglePlaceId,
      this.state.getPriceRequestModel.dropOff.GooglePlaceId,
      event.target.value,
      this.state.time,
      this.state.numberOfPassengers,
      this.state.carType,
      this.state.travelNow
    );
  }

  onTimeChange(event: any) {
    this.setState({ 
      time: event.target.value,       
      lastSelectedTime: event.target.value,
      offering: "" 
    });

    this.getPrice(
      "",
      this.state.fromValid,
      this.state.toValid,
      this.state.getPriceRequestModel.pickup.GooglePlaceId,
      this.state.getPriceRequestModel.dropOff.GooglePlaceId,
      this.state.date,
      event.target.value,
      this.state.numberOfPassengers,
      this.state.carType,
      this.state.travelNow
    );
  }

  
  onChangeFlightNumber(event :  React.ChangeEvent<HTMLInputElement>)
  {
    const { value } = event.target;

    if(this.state.isAirport && value === ""){
      this.setState({flightNumber: value, inValidFlightMessage: "Vänligen ange flightnummer."})
    }
    else{
      this.setState({flightNumber: value, inValidFlightMessage: "" })
    }

    this.getPrice(
      this.state.offering,
      this.state.fromValid,
      true,
      this.state.getPriceRequestModel.pickup.GooglePlaceId,
      this.state.getPriceRequestModel.dropOff.GooglePlaceId,
      this.state.date,
      this.state.time,
      this.state.numberOfPassengers,
      this.state.carType,
      this.state.travelNow
    );
  }

  onClickTravelNow() {
    this.setState({ travelNow: true, travelLater: false, offering: "" });

    this.getPrice(
      "",
      this.state.fromValid,
      this.state.toValid,
      this.state.getPriceRequestModel.pickup.GooglePlaceId,
      this.state.getPriceRequestModel.dropOff.GooglePlaceId,
      this.state.date,
      this.state.time,
      this.state.numberOfPassengers,
      this.state.carType,
      true
    );
  }

  onClickTravelLater() {
    if (this.state.current === undefined) return;

    // if(this.state.priceLoading === true) return;

    const dateTimeString = this.state.lastSelectedDate + "T" + this.state.lastSelectedTime;
    const dateTimeFormated = momenttz(dateTimeString).tz("Europe/Stockholm", true).format();
    const dateTimeFormatedString = dateTimeFormated.toString();
    let newCurrent = this.state.current;
    newCurrent.pickUpTime = dateTimeFormatedString;    

    this.setState({ 
      travelNow: false,
      travelLater: true, 
      offering: "",
      date: this.state.lastSelectedDate,
      time: this.state.lastSelectedTime,
      current: {...newCurrent}
    });

    this.getPrice(
      "",
      this.state.fromValid,
      this.state.toValid,
      this.state.getPriceRequestModel.pickup.GooglePlaceId,
      this.state.getPriceRequestModel.dropOff.GooglePlaceId,
      this.state.lastSelectedDate,
      this.state.lastSelectedTime,
      this.state.numberOfPassengers,
      this.state.carType,
      false
    );
  }

  async getPrice(
    offeringId: string,
    fromValid: boolean,
    toValid: boolean,
    fromPlaceId: string,
    toPlaceId: string,
    date: string,
    time: string,
    nrOfPassengers: number,
    carType: number,
    travelNow: boolean
  ) {
    if (this.state.componentInitiated) {
      this.setState({ priceLoading: true, getPriceRunning: true});
    }

    let currentData = this.state.current;
    if (currentData === undefined) return;

    if (fromValid === true && toValid === true) {
      if (travelNow === false) {
        if (date !== "" && time !== "") {
          const dateTimeString = date + "T" + time;
          const dateTimeFormated = momenttz(dateTimeString).tz("Europe/Stockholm",true).format();
          const dateTimeFormatedString = dateTimeFormated.toString();

          const pickup = {
            title: null,
            lat: null,
            long: null,
            GooglePlaceId: fromPlaceId,
          };
          const dropoff = {
            title: null,
            lat: null,
            long: null,
            GooglePlaceId: toPlaceId,
          };
          const response = await GetPriceApi.getPriceRequest(
            null,
            dateTimeFormatedString,
            pickup,
            dropoff,
            nrOfPassengers,
            null,
            carType,
            null,
            null,
            false,
            null
          );

          if (!response) return;
          const responsePriceInfo = response.price;

          if (responsePriceInfo.additionalFee != null) 
          {
            if (this.state.isAirport) {
              this.setState({ airportFee: response.additionalFee });
            } else {
              this.setState({ airportFee: 0 });
            }
          }

          let data = this.state.current;
          if (data === undefined) return;
          data.price =
            response.fixedPrice +
            responsePriceInfo.additionalFee +
            this.state.childSeatCost +
            this.state.babySeatCost +
            this.state.administrationFee;

          this.setState({ originalPrice: data.price, showOriginalPrice: false })
          //offering calculation
          if (offeringId !== "") {
            if (this.state.offeringType === 0) {
              const percent = (100 - this.state.offeringPercent) / 100;
              data.price = Math.round((data.price + this.state.administrationFee) * percent);
            } else if (this.state.offeringType === 1) {
              data.price = Math.round((data.price + this.state.administrationFee) - this.state.offeringFixed);
            }
            this.setState({ showOriginalPrice: true })
          }

          if (data.price < 0) {
            data.price = 0;
          }

          data.pickUpTime = dateTimeFormatedString;
          this.setState({
            buttonIsDisabled: this.state.isAirport && this.state.flightNumber.length < 1,
            current: data,
            priceLoading: false,
            getPriceRunning: false
          });
        } else {
          return;
        }
      } else if (travelNow === true) {
        this.setState({ priceLoading: true, getPriceRunning: true });
        const dateTime = momenttz(new Date()).tz("Europe/Stockholm", true).format().toString();

        const pickup = {
          title: null,
          lat: null,
          long: null,
          GooglePlaceId: fromPlaceId, //fromPlaceId,
        };
        const dropoff = {
          title: null,
          lat: null,
          long: null,
          GooglePlaceId: toPlaceId, //toPlaceId,
        };
        const response = await GetPriceApi.getPriceRequest(
          null,
          dateTime,
          pickup,
          dropoff,
          nrOfPassengers,
          null,
          carType,
          null,
          null,
          true,
          null
        );

        if (!response) return;

        const responsePriceInfo = response.price;

        if (responsePriceInfo.additionalFee != null) 
        {
          if (this.state.isAirport) {
            this.setState({ airportFee: response.additionalFee });
          } else {
            this.setState({ airportFee: 0 });
          }
        }

        let data = this.state.current;
        if (data === undefined) return;
        data.pickUpTime = dateTime;
        data.price =
          response.fixedPrice +
          responsePriceInfo.additionalFee +
          this.state.childSeatCost +
          this.state.babySeatCost +
          this.state.administrationFee;

        this.setState({ originalPrice: data.price, showOriginalPrice: false })
        //offering calculation
        if (offeringId !== "") {
          if (this.state.offeringType === 0) {
            const percent = (100 - this.state.offeringPercent) / 100;
            data.price = Math.round(data.price * percent);
          } else if (this.state.offeringType === 1) {
            data.price = Math.round(data.price - this.state.offeringFixed);
          }
          this.setState({ showOriginalPrice: true })
        }

        if (data.price < 0) {
          data.price = 0;
        }

        this.setState({
          buttonIsDisabled: this.state.isAirport && this.state.flightNumber.length < 1,
          current: data,
          priceLoading: false,
          getPriceRunning: false,
        });
      }
    } else {
      return;
    }
  }

  setCarType(typeName: any) {
    var carType: number = 0;
    var nrOfPassengers: number = 0;
    addonsCarType.forEach((element) => {
      if (element.carTypeName === typeName) {
        element.checked = true;
        this.setState({ carTypeName: element.carTypeName });

        if (typeName === "Standard") {
          carType = 0;
          nrOfPassengers = 1;
          this.setState({
            carTypeIndex: 0,
            numberOfPersons: "1-4 personer",
            numberOfPassengers: 1,
          });
        } else if (typeName === "Kombi") {
          carType = 2;
          nrOfPassengers = 4;
          this.setState({
            carTypeIndex: 2,
            numberOfPersons: "1-4 personer",
            numberOfPassengers: 4,
          });
        } else {
          carType = 1;
          nrOfPassengers = 5;
          this.setState({
            carTypeIndex: 1,
            numberOfPersons: "1-8 personer",
            numberOfPassengers: 5,
          });
        }
      } else {
        element.checked = false;
      }
    });

    this.getPrice(
      this.state.offering,
      this.state.fromValid,
      this.state.toValid,
      this.state.getPriceRequestModel.pickup.GooglePlaceId,
      this.state.getPriceRequestModel.dropOff.GooglePlaceId,
      this.state.date,
      this.state.time,
      nrOfPassengers,
      carType,
      this.state.travelNow
    );

    this.forceUpdate();
  }

  // setChildAddon(title: string, price: number) {
  //   addonsChildren.forEach((item) => {
  //     if (item.childItemName === title) {
  //       if (item.checked === true) {
  //         item.checked = false;
  //       } else {
  //         item.checked = true;
  //       }
  //     }
  //   });

  //   this.forceUpdate();
  // }

  // setPetAddon(title: string) {
  //   addonsPet.forEach((item) => {
  //     if (item.petItemName === title) {
  //       if (item.checked === true) {
  //         item.checked = false;
  //       } else {
  //         item.checked = true;
  //       }
  //     }
  //   });

  //   this.forceUpdate();
  // }

  checkIfUndefined(text: string) {
    if (text !== undefined) {
      return ", " + text;
    }
  }

  render() {
    return (
      <>
        {/* <ComponentDidMount
          onMount={() => {
            this.checkUserBookings();
          }}
        /> */}
        <React.Fragment>
          <LanguageContext.Consumer>
            {(lang) => (
              <div>
                <LoginComponent modalOpen="open.booking.modal">
                  {this.state.existingBooking === false
                    ? this.renderBooking(this.state.current)
                    : this.renderBookingExist()}
                  {/* {this.renderBooking(this.state.current)} */}
                </LoginComponent>
              </div>
            )}
          </LanguageContext.Consumer>
        </React.Fragment>
      </>
    );
  }
  onValidUser() {
    this.setState({ validUser: true });
  }

  // offeringType 0:Percent , 1:Fixed
  onChangeCurrentOffering = (
    offeringId: string,
    offeringType: OfferingType,
    offeringPercent: number,
    offeringFixed: number,
    offeringTitle: string
  ) => {
    if (this.state.offering === offeringId) {
      offeringId = "";
      this.setState({ offering: "", offeringTitle: "" });
    } else {
      this.setState({ offering: offeringId, offeringTitle: offeringTitle });
      if (offeringType === 0) {
        this.setState({ offeringType: 0, offeringPercent: offeringPercent });
      } else if (offeringType === 1) {
        this.setState({ offeringType: 1, offeringFixed: offeringFixed });
      }
    }

    this.forceUpdate();

    this.getPrice(
      offeringId,
      this.state.fromValid,
      this.state.toValid,
      this.state.getPriceRequestModel.pickup.GooglePlaceId,
      this.state.getPriceRequestModel.dropOff.GooglePlaceId,
      this.state.date,
      this.state.time,
      this.state.numberOfPassengers,
      this.state.carType,
      this.state.travelNow
    );
  };

  async checkUserBookings() {
    const user = await UserApi.getCurrentProfile();
    if (!user) return;
    const updatedUser = await UserApi.getUpdateCurrentProfile(
      user.userProfile.id
    );
    if (!updatedUser) return;
    if (updatedUser) {
      if (
        updatedUser.userProfile.bookings &&
        updatedUser.userProfile.bookings.length > 0
      ) {
        if (updatedUser.userProfile.bookings[0].isConfirmed === false) {
          var isCanceled = await BookingApi.cancelUnpaidBooking(
            updatedUser.userProfile.bookings[0].bookingId
          );
          if (isCanceled) {
            this.setState({ existingBooking: false });
            this.forceUpdate();
            return;
          }
        }
        this.setState({ existingBooking: true, userProfile: updatedUser });
        //this.forceUpdate();
        // return;
        return this.renderBookingExist();
      } else {
        this.setState({ existingBooking: false });
        this.forceUpdate();
      }
    } else {
      this.setState({ existingBooking: false });
      this.forceUpdate();
    }
  }

  private renderBookingExist(): React.ReactNode {
    return (
      <div data-back_state="cancel_paid_booking">
        <ComponentDidMount
          onMount={() => {
            this.checkUserBookings();
          }}
        />
        <>{this.ExistingBooking()}</>
      </div>
    );
  }

  translateToSwe = (month: string) => {
    if (month === "Jan") return "januari";
    if (month === "Feb") return "februari";
    if (month === "Mar") return "mars";
    if (month === "Apr") return "april";
    if (month === "May") return "maj";
    if (month === "Jun") return "juni";
    if (month === "Jul") return "juli";
    if (month === "Aug") return "augusti";
    if (month === "Sep") return "september";
    if (month === "Oct") return "oktober";
    if (month === "Nov") return "november";
    if (month === "Dec") return "december";
    else {
      return month;
    }
  };

  cleanDateTime = () => {
    const userBookings = this.state.userProfile?.userProfile.bookings[0];
    if (!userBookings?.pickUpTime) return;

    const dateTime = new Date(Date.parse(userBookings.pickUpTime));

    const timeString = dateTime.toString();
    var timeSplit = timeString.split(" ");

    var month = timeSplit[1];
    const translatedMonth = this.translateToSwe(month);

    const day = timeSplit[2];

    var hourMin = timeSplit[4].split(":");
    const hourMinString = hourMin[0] + ":" + hourMin[1];

    const dateTimeString = day + " " + translatedMonth + " " + hourMinString;

    return dateTimeString;
  };

  offeringDatePassedNow = (offeringDate: string) => {
    const dateTime = new Date(Date.parse(offeringDate));
    const dateTimeNow = new Date();
    if (dateTimeNow > dateTime) {
      return "booking-component-offering-list-li-hide";
    } else {
      return "booking-component-offering-list-li";
    }
  };

  ExistingBooking() {
    const userBookings = this.state.userProfile?.userProfile.bookings[0];
    return (
      <div data-back_state="cancel_paid_booking">
        <div>
          <div className="booking-receipt-container">
            <div
              className="receipt-Title"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2 style={{}}>Pågående bokning</h2>
            </div>
            <div>
              <div>
                <h5 className="receipt-information-title">Tid för avresa</h5>
                <h4
                  className="receipt-information-details"
                  style={{ fontWeight: "normal" }}
                >
                  {this.cleanDateTime()}
                </h4>
              </div>
              <div>
                <h5 className="receipt-information-title">Från</h5>
                <h4
                  className="receipt-information-details"
                  style={{ fontWeight: "normal" }}
                >
                  {userBookings?.pickupPos.title}
                </h4>
              </div>
              <div>
                <h5 className="receipt-information-title">Till</h5>
                <h4
                  className="receipt-information-details"
                  style={{ fontWeight: "normal" }}
                >
                  {userBookings?.dropOfPos.title}
                </h4>
              </div>
              <div>
                <h5 className="receipt-information-title">Antal passagerare</h5>
                <h4
                  className="receipt-information-details"
                  style={{ fontWeight: "normal" }}
                >
                  1-4 personer
                </h4>
              </div>
            </div>
          </div>
          <hr className="receipt-line-seperator" />
          <Form className="m-3">
            <Row className="m-4">
              <Col className="fixed-price-col">
                <div className="fixed-price-container">
                  <p className="fixed-price-container-text">Fastpris:</p>
                  <h1 className="fixed-price-container-price">
                    {(userBookings?.priceInfo.price || 0) +  this.state.administrationFee} kr
                  </h1>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }

  private renderBooking(current?: BookingRequest): React.ReactNode {
    const { cursor } = this.state;
    if (current != undefined) {
      //console.info(current);
      return (
        <div data-back_state="back_only">
          <ComponentDidMount
            onMount={() => {
              this.checkUserBookings();
              this.getExtraServices();
              // this.getPrice(
              //   this.state.offering,
              //   this.state.fromValid,
              //   this.state.toValid,
              //   this.state.getPriceRequestModel.pickup.GooglePlaceId,
              //   this.state.getPriceRequestModel.dropOff.GooglePlaceId,
              //   this.state.date,
              //   this.state.time,
              //   this.state.numberOfPassengers,
              //   this.state.carType
              // );
            }}
          />
          <Row style={{ backgroundColor: "#ffcb05", height: "150px" }}>
            <Col md="6" className="p-3 align-content-center" style={{}}>
              <h2
                className="heading textunderline m-3"
                style={{
                  borderBottom: "0.07em solid #fff",
                  maxWidth: "167px",
                }}
              >
                Boka resa
              </h2>
            </Col>
          </Row>
          <Form className="m-3">
            <Row className="p-2">
              <Col>
                <h4>Från:</h4>
                <Dropdown
                  toggle={() => this.selectTo(false)}
                  isOpen={this.state.showFromResult}
                >
                  <DropdownToggle data-toggle="dropdown" tag="span">
                    <Input
                      id="fromInput"
                      name="from"
                      placeholder="Från"
                      type="text"
                      valid={this.state.fromValid}
                      invalid={this.state.fromInValid}
                      value={this.state.current?.pickup.title}
                      onChange={(event) => this.onFromChange(event)}
                      autoComplete="off"
                      // onFocus={() => this.onClickShowFromAddresses(true)}
                      onKeyDown={this.onFromKeyDownHandler.bind(this)}
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    container="body"
                    className="search-address-dropdown-menu search-from-address-list dropdown-searchAddress-modal"
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                      minWidth: "227px",
                      maxWidth: "227px",
                      minHeight: "180px",
                      top: "100%",
                    }}
                    positionFixed={true}
                  >
                    {this.state.addressFromDetails.fromAddressWithPlaceId?.map(
                      (item, i) => {
                        return (
                          <div key={i}>
                            <Row
                              onClick={() =>
                                this.onAddFromAddress(
                                  item.addressDetails[0] +
                                    "," +
                                    item.addressDetails[1],
                                  item
                                )
                              }
                              className={
                                cursor === i
                                  ? "active search-address-dropdown-menu-row"
                                  : "not-active search-address-dropdown-menu-row"
                              }
                            >
                              <Col className="search-address-col-icon">
                                <i
                                  className="fa-thin fa-location-pin"
                                  style={{
                                    fontSize: "1.4em",
                                    marginRight: "0",
                                  }}
                                ></i>
                              </Col>
                              <Col className="search-address-col-textbox">
                                <div className="search-address-streetname">
                                  {item.addressDetails[0]}
                                </div>
                                <div className="search-address-city">
                                  {item.addressDetails[1]}
                                  {this.checkIfUndefined(
                                    item.addressDetails[2]
                                  )}
                                  {this.checkIfUndefined(
                                    item.addressDetails[3]
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      }
                    )}
                  </DropdownMenu>
                </Dropdown>
                <div className="booking-error-message">
                  {this.state.inValidFromMessage}
                </div>
              </Col>
              <Col>
                <h4>Till:</h4>
                <Dropdown
                  toggle={() => this.selectTo(false)}
                  isOpen={this.state.showToResult}
                >
                  <DropdownToggle data-toggle="dropdown" tag="span">
                    <Input
                      id="toInput"
                      name="to"
                      placeholder="Till"
                      type="text"
                      valid={this.state.toValid}
                      invalid={this.state.toInValid}
                      value={this.state.current?.dropOff.title}
                      onChange={(event) => this.onToChange(event)}
                      autoComplete="off"
                      // onFocus={() => this.onClickShowToAddresses(true)}
                      onKeyDown={this.onToKeyDownHandler.bind(this)}
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    container="body"
                    className="search-address-dropdown-menu search-to-address-list dropdown-searchAddress-modal"
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                      minWidth: "227px",
                      maxWidth: "227px",
                      minHeight: "180px",
                      top: "100%",
                    }}
                    positionFixed={true}
                  >
                    {this.state.addressToDetails.toAddressWithPlaceId?.map(
                      (item, i) => {
                        return (
                          <div key={i}>
                            <Row
                              onClick={() =>
                                this.onAddToAddress(
                                  item.addressDetails[0] +
                                    "," +
                                    item.addressDetails[1],
                                  item
                                )
                              }
                              className={
                                cursor === i
                                  ? "active search-address-dropdown-menu-row"
                                  : "not-active search-address-dropdown-menu-row"
                              }
                            >
                              <Col className="search-address-col-icon">
                                <i
                                  className="fa-thin fa-location-pin"
                                  style={{
                                    fontSize: "1.4em",
                                    marginRight: "0",
                                  }}
                                ></i>
                              </Col>
                              <Col className="search-address-col-textbox">
                                <div className="search-address-streetname">
                                  {item.addressDetails[0]}
                                </div>
                                <div className="search-address-city">
                                  {item.addressDetails[1]}
                                  {this.checkIfUndefined(
                                    item.addressDetails[2]
                                  )}
                                  {this.checkIfUndefined(
                                    item.addressDetails[3]
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      }
                    )}
                  </DropdownMenu>
                </Dropdown>
                <div className="booking-error-message">
                  {this.state.inValidToMessage}
                </div>
              </Col>
            </Row>
            <Row
              className={
                this.state.isAirport
                  ? "booking-flightnumber"
                  : "booking-flightnumber-invis"
              }
            >
              <Col className="booking-flightnumber-col">
                <Input
                  bsSize="lg"
                  className="booking-flightnumber-input"
                  onChange={
                    (event) => this.onChangeFlightNumber(event)
                    // this.setState({ flightNumber: event.target.value, inValidFlightMessage: "" })
                  }
                  autoComplete="off"
                  minLength={0}
                  maxLength={10}
                  value={this.state.flightNumber}
                  placeholder="Flightnummer"
                  invalid={
                    this.state.inValidFlightMessage !== "" ? true : false
                  }
                />
              </Col>
              <Col />
              <div className="booking-flight-error-message">
                {this.state.inValidFlightMessage}
              </div>
            </Row>
            <Row className="mt-4">
              <Col className="date-time-button-group-container">
                <ButtonGroup
                  className="p-2 button-group-custom"
                  radioGroup="travel-time"
                >
                  <Button
                    color={this.state.travelNow ? "dark" : "secondary"}
                    radioGroup="travel-time"
                    checked={this.state.travelNow}
                    style={{ width: "113.5px", height: "40px" }}
                    onClick={() => this.onClickTravelNow()}
                    // onClick={() =>
                    //   this.setState({ travelNow: true, travelLater: false })
                    // }
                  >
                    Nu
                  </Button>
                  <Button
                    color={this.state.travelLater ? "dark" : "secondary"}
                    radioGroup="travel-time"
                    checked={this.state.travelLater}
                    style={{ width: "113.5px", height: "40px" }}
                    onClick={() => this.onClickTravelLater()}
                  >
                    Senare
                  </Button>
                </ButtonGroup>
              </Col>
              <Col className="m-2 date-time-container">
                {this.state.travelLater ? (
                  <Row className="date-time-container-row">
                    <Col className="date-time-container-col">
                      <FormGroup floating>
                        <Label for="travelDate" className="p-2">
                          Datum
                        </Label>
                        <Input
                          id="travelDate"
                          name="date"
                          placeholder="date placeholder"
                          type="date"
                          min={this.state.dateMin}
                          max={this.state.dateMax}
                          value={this.state.date}
                          onChange={this.onDateChange.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="date-time-container-col">
                      <FormGroup floating>
                        <Label for="travelTime" className="p-2">
                          Tid
                        </Label>
                        <Input
                          id="travelTime"
                          name="time"
                          placeholder="time placeholder"
                          type="time"
                          value={this.state.time}
                          onChange={this.onTimeChange.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Row className="p-2">
              <Col>
                <Row>
                  {/* <div className="fixed-price-container">
                    <p className="fixed-price-container-text">Fastpris:</p>
                    <h2 className="fixed-price-container-price">{this.state.current?.price}</h2>
                    </div> */}
                </Row>
              </Col>
              <Col>{}</Col>
            </Row>
            <Row>
              <Col className="px-3">
                <ModifiedCollapseList
                  id="options"
                  items={this.getOptions(this.state.current)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mt-4"></div>
              </Col>
            </Row>
            {/* <Row className="mt-3"> */}
            <div className="booking-component-offering-list-container">
              <div className="booking-component-offering-list">
                {/* Offerings */}
                {this.state.currentUser?.userProfile?.offerings && (
                  <ul className="booking-component-offering-list-ul">
                    {this.state.currentUser.userProfile.offerings.map(
                      (offering) => {
                        if (
                          offering.counter > 0 &&
                          moment(offering.startDate).isSameOrBefore(
                            new Date()
                          ) &&
                          moment(new Date()).isSameOrBefore(offering.endDate)
                        ) {
                          return (
                            <li
                              className={this.offeringDatePassedNow(
                                offering.endDate
                              )}
                            >
                              {/* <li className="booking-component-offering-list-li"> */}
                              <OfferTile
                                priceLoading={this.state.priceLoading}
                                pickUpTime={this.state.current?.pickUpTime}
                                offering={offering}
                                // {...{ offering }}
                                key={offering.id}
                                selected={this.state.offering === offering.id}
                                onClick={() => {
                                  this.onChangeCurrentOffering(
                                    offering.id,
                                    offering.offeringType,
                                    offering.percent,
                                    offering.fixedDiscount,
                                    offering.title
                                  );
                                }}
                              />
                            </li>
                          );
                        }
                      }
                    )}
                  </ul>
                )}
              </div>
            </div>
            {/* </Row> */}
            <Row className="mt-3">
              <Col>
                <Input
                  type="textarea"
                  bsSize="lg"
                  className="mb-3"
                  onChange={(event) =>
                    this.setState({ messageToDriver: event.target.value })
                  }
                  value={this.state.messageToDriver}
                  placeholder="Meddelande till förare"
                  maxLength={100}
                />
              </Col>
            </Row>
            <Row className="m-4">
              <Col className="fixed-price-col">
                <div>
                  <div className="fixed-price-container">
                    <p className="fixed-price-container-text">Fastpris:</p>
                    <h1 className="fixed-price-container-price">
                      {(this.state.current?.price || 0)} kr
                    </h1>
                  </div>
                  <div
                    className={
                      this.state.showOriginalPrice
                        ? "original-price-container"
                        : "original-price-container-hide"
                    }
                  >
                    <div className="original-price">
                      {this.state.originalPrice} kr
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="p-3 d-flex flex-row-reverse">
                <Button
                  // className="booking-component-pay-button"
                  className={
                    this.state.buttonIsDisabled
                      ? "booking-component-pay-button-disabled"
                      : "booking-component-pay-button"
                  }
                  color="dark"
                  size="lg"
                  onClick={() => this.openReceiptComponent()}
                  // onClick={() => this.bookAndPay()}
                >
                  <Loading loading={this.state.priceLoading} white>
                    Boka
                  </Loading>
                  {/* Betalning */}
                </Button>
              </Col>
            </Row>
            {/* <BookingReceiptComponent /> */}
            <BookingReceiptInCarPaymentComponent />
          </Form>
        </div>
      );
    }
  }

  payButtonMode() {
    if(this.state.buttonIsDisabled === true && this.state.inValidFlightMessage !== "") {
      return "booking-component-pay-button-invalid-flight";
    }
    if(this.state.buttonIsDisabled) {
      return "booking-component-pay-button-disabled";
    }
    else {
      return "booking-component-pay-button";
    }
    
  }

  async testGetUpdatedUser() {
    if (this.state.userId === "") {
      const userProfile = await UserApi.getCurrentProfile();
      this.setState({ userId: userProfile.userProfile.id });

      this.forceUpdate();
    }

    const updatedUser = await UserApi.getUpdateCurrentProfile(
      this.state.userId
    );
  }

  async openReceiptComponent() {
    if (this.state.buttonIsDisabled) return;

    this.setState({
      priceLoading:true,
      buttonIsDisabled:true
    })

    if (this.state.userId === "") {
      const userProfile = await UserApi.getCurrentProfile();
      this.setState({ userId: userProfile.userProfile.id });

      this.forceUpdate();
    }

    if (
      this.state.fromValid === true &&
      this.state.toValid === true &&
      this.state.getPriceRunning === false
    ) {
      this.setState({
        priceLoading: true,
        buttonIsDisabled: true,
      });


      // DateTime
      const dateTimeString = this.state.date + "T" + this.state.time;
      const dateTimeFormated = momenttz(dateTimeString).tz("Europe/Stockholm",true).format();
      let dateTimeFormatedString = dateTimeFormated.toString() || undefined;

      if(this.state.travelNow === true)
      {
        dateTimeFormatedString = this.state.current?.pickUpTime;
      }
      
      let pickup : Place = {
        title: this.state.getPriceRequestModel.pickup.title,
        lat: "",
        long: "",
        GooglePlaceId: this.state.getPriceRequestModel.pickup.GooglePlaceId,
      };
      let dropoff : Place = {
        title: this.state.getPriceRequestModel.dropOff.title,
        lat: "",
        long: "",
        GooglePlaceId: this.state.getPriceRequestModel.dropOff.GooglePlaceId,
      };

      let data : CreateBookingRequest = {
        bookingId: "",
        userId: this.state.userId,
        pickUpTime: dateTimeFormatedString, //this.state.current?.pickUpTime,
        pickup: pickup,
        dropOff: dropoff,
        numberOfPassengers: this.state.numberOfPassengers,
        via: [],
        carType: this.state.carTypeIndex,
        extraServices: this.state.selectedExtraServices,
        message: this.state.messageToDriver,
        pickupTimeASAP: this.state.travelNow,
        offeringId: this.state.offering,
        flightId: this.state.flightNumber,
        bonusPoints: 0,
        isWebCheckout: true,
      };

      const activeExtraServices = this.state.extraServices.filter((service) => this.state.selectedExtraServices.includes(service.id));
      let extraServicesPrice =0;
      for (const extraService of activeExtraServices) {
        extraServicesPrice += extraService.price;
      }

      const travelPrice = this.state.originalPrice - extraServicesPrice - this.state.administrationFee - this.state.airportFee;
      const administrationFee = this.state.administrationFee;
      
      let booking : BookingReceiptData = {
        data: data,
        price: this.state.current?.price,
        offeringTitle: this.state.offeringTitle,
        extraServiceNames: this.state.extraServiceNames,
        numberOfPersons: this.state.numberOfPersons,
        userId: this.state.userId,
        originalPrice: this.state.originalPrice,
        showOriginalPrice: this.state.showOriginalPrice, 
        priceSummaryData: {
          travelPrice,
          extraServicesPrice,
          administrationFee,
          airportFee : this.state.airportFee
        }
      };
      
      App.publish("bookingReceiptInCarPayment", booking);
      // App.publish("bookingReceipt", booking);
      this.setState({
        priceLoading: false,
        buttonIsDisabled: false,
      })
    }
  }

  bookAndPay() {
    throw new Error("Method not implemented.");
  }

  toggleSelectedExtraService = (
    serviceId: string,
    cost: number,
    serviceName: string
  ) => {
    const exists = this.state.selectedExtraServices.includes(serviceId);
    if (exists) {
      this.setState({
        selectedExtraServices: this.state.selectedExtraServices.filter(
          (service) => service !== serviceId
        ),
        extraServiceNames: this.state.extraServiceNames?.filter(
          (service) => service !== serviceName
        ),
      });
      if (cost !== null || cost !== 0) {
        // var data = this.state.current;
        // if (data === undefined) return;
        // if (data.price === undefined) return;
        // data.price = data.price - cost;
        // this.setState({
        //   current: data,
        // });
        if (serviceName === "Bilbarnstol") {
          this.setState({
            childSeatCost: 0,
          });
        }
        if (serviceName === "Babyskydd") {
          this.setState({ babySeatCost: 0 });
        }

        this.forceUpdate();

        this.getPrice(
          this.state.offering,
          this.state.fromValid,
          this.state.toValid,
          this.state.getPriceRequestModel.pickup.GooglePlaceId,
          this.state.getPriceRequestModel.dropOff.GooglePlaceId,
          this.state.date,
          this.state.time,
          this.state.numberOfPassengers,
          this.state.carType,
          this.state.travelNow
        );
      }
    } else {
      this.setState({
        selectedExtraServices: [...this.state.selectedExtraServices, serviceId],
        extraServiceNames: [...this.state.extraServiceNames, serviceName],
      });
      if (cost !== null || cost !== 0) {
        // var data = this.state.current;
        // if (data === undefined) return;
        // if (data.price === undefined) return;
        // data.price = data.price + cost;
        // this.setState({
        //   current: data,
        // });

        if (serviceName === "Bilbarnstol") {
          this.setState({ childSeatCost: cost });
        }
        if (serviceName === "Babyskydd") {
          this.setState({ babySeatCost: cost });
        }

        this.forceUpdate();

        this.getPrice(
          this.state.offering,
          this.state.fromValid,
          this.state.toValid,
          this.state.getPriceRequestModel.pickup.GooglePlaceId,
          this.state.getPriceRequestModel.dropOff.GooglePlaceId,
          this.state.date,
          this.state.time,
          this.state.numberOfPassengers,
          this.state.carType,
          this.state.travelNow
        );
      }
    }
  };

  getOptions(current: BookingRequest | undefined): CollapseListItem[] {
    var list: CollapseListItem[] = new Array<CollapseListItem>();

    enum Categories {
      Children = "Children",
      Pets = "Pets",
    }

    interface ExtraServiceWithSelected extends ExtraService {
      selected: boolean;
    }

    const [childrenExtraService, petsExtraService] = this.state.extraServices

      .map<ExtraServiceWithSelected>((service) => ({
        ...service,
        selected: this.state.selectedExtraServices.includes(service.id),
      }))
      .reduce<[ExtraServiceWithSelected[], ExtraServiceWithSelected[]]>(
        (acc, service) => {
          switch (service.category) {
            case Categories.Children:
              acc[0].push(service);
              break;
            case Categories.Pets:
              acc[1].push(service);
              break;
          }

          return acc;
        },
        [[], []]
      );

    list.push({
      title: <p className="extra-service-title">Typ av bil</p>,
      isOpen: true,
      content: (
        <div className="extra-service-container">
          {addonsCarType.map((item) => {
            return (
              <ExtraServiceOption
                key={item.carTypeName}
                label={item.carTypeName}
                selected={this.state.carTypeName === item.carTypeName}
                isCarOption
                iconId={item.carTypeName}
                description={item.description}
                onPress={() => this.setCarType(item.carTypeName)}
              />
            );
          })}
        </div>
      ),
    });
    list.push({
      title: <p className="extra-service-title">Tillval barn</p>,
      isOpen: true,
      content: (
        <div className="extra-service-container">
          {childrenExtraService.map((item) => {
            return (
              <ExtraServiceOption
                key={item.id}
                selected={item.selected}
                iconId={item.iconId as ExtraServiceIcons}
                label={item.name}
                price={item.price}
                description={item.description}
                onPress={() =>
                  this.toggleSelectedExtraService(
                    item.id,
                    item.price,
                    item.name
                  )
                }
              />
            );
          })}
        </div>
      ),
    });
    list.push({
      title: <p className="extra-service-title">Tillval djur</p>,
      isOpen: true,
      content: (
        <div className="extra-service-container">
          {petsExtraService.map((item) => {
            return (
              <ExtraServiceOption
                key={item.id}
                selected={item.selected}
                iconId={item.iconId as ExtraServiceIcons}
                label={item.name}
                price={item.price}
                description="  "
                onPress={() =>
                  this.toggleSelectedExtraService(
                    item.id,
                    item.price,
                    item.name
                  )
                }
              />
            );
          })}
        </div>
      ),
    });

    return list;
  }
}
