import { ContentItem } from '../../api/Content';
import { GlobalAppsettings } from '../../backbone/GlobalSettings';
import { UserProfileResult } from '../types/UserProfile';

export interface UpdateUserRequest
{
    userId : string | undefined
}

export class UserApi
{
    public static async getCurrentProfile()
    {
        const response = await fetch(GlobalAppsettings.BackEndBaseUrl + 'api/profile/CurrentProfile',
             GlobalAppsettings.GetPostInit(null))

        const data = (await response.json()) as UserProfileResult;

        return data

    }

    // backened get user profile with updates
    public static async getUserProfileWithUpdate(userId: string)
    {
        const request = {
        userId : userId
        } as UpdateUserRequest;

        const response = await fetch(GlobalAppsettings.BackEndBaseUrl + 'api/profile/UserProfileWithUpdate',
             GlobalAppsettings.GetPostInit(request))

        const data = (await response.json()) as UserProfileResult;

        return data
    }

    // temporary
    public static async getUpdateCurrentProfile(userId: string)
    {
        const request:UpdateUserRequest = {
            userId : userId
        } 

        //const response = await fetch('https://taxi-gbg-mobile-prod.azurewebsites.net/api/profile/UserProfile',
        const response = await fetch(GlobalAppsettings.BackEndBaseUrl + 'api/profile/UserProfile',
            {
                method: "POST",
                headers: {
                    "Content-Type" : "application/json",
                    "Authorization" : "Basic QVBJLVRPS0VOOnRlc3Q="
                },
                body: JSON.stringify(request)
            })
        const data = (await response.json()) as UserProfileResult;

        return data
    }

    public static async SendReceipt(
        request : string
    )
    {
        const response = await fetch(GlobalAppsettings.BackEndBaseUrl + 'api/profile/SendReceipt',
             GlobalAppsettings.GetPostInit(request))

        const data = (await response.json()) as boolean

        return data
    }

}