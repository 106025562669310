import classNames from "classnames";
import moment from "moment";
import React from "react";
import { Col, Row } from "reactstrap";
import "../webparts/styles/Offering.css";
import { Offering } from "../webparts/types/UserProfile";

interface Props {
  priceLoading: boolean;
  pickUpTime:  string | undefined;
  offering: Offering;
  selected: boolean;
  onClick: () => void;
}

const OfferTile: React.FC<Props> = ({
  priceLoading,
  pickUpTime,
  offering: { title, description, endDate, pickUpStartDate, pickUpEndDate },
  selected,
  onClick  
}) => {
  const translateToSwe = (month: string) => {
    if (month === "Jan") return "januari";
    if (month === "Feb") return "februari";
    if (month === "Mar") return "mars";
    if (month === "Apr") return "april";
    if (month === "May") return "maj";
    if (month === "Jun") return "juni";
    if (month === "Jul") return "juli";
    if (month === "Aug") return "augusti";
    if (month === "Sep") return "september";
    if (month === "Oct") return "oktober";
    if (month === "Nov") return "november";
    if (month === "Dec") return "december";
    else {
      return month;
    }
  };

  const cleanDateTime = (endDate: string) => {
    // if (endDate) return;

    var dateTime = new Date(Date.parse(endDate));
    dateTime.setDate(dateTime.getDate() - 1);

    const timeString = dateTime.toString();
    var timeSplit = timeString.split(" ");

    var month = timeSplit[1];
    const translatedMonth = translateToSwe(month);

    const day = timeSplit[2];

    const year = timeSplit[3];

    var hourMin = timeSplit[4].split(":");
    const hourMinString = hourMin[0] + ":" + hourMin[1];

    const dateTimeString =
      "Giltigt t.o.m. den " + day + " " + translatedMonth + " " + year;

    return dateTimeString;
  };

  const checkIfDisabled = (): boolean => {
    if (priceLoading)
      return true;

    if (pickUpTime === undefined)
      return false;      
    
    return !(moment(pickUpStartDate).isSameOrBefore(pickUpTime)
      && moment(pickUpTime).isSameOrBefore(pickUpEndDate));
  };

  const isDisabled = checkIfDisabled();
  return (
    <div
      onClick={(isDisabled) ? undefined : onClick}      
      className={
        isDisabled ? "offering-container-disabled" : "offering-container"
      }
    >
      <Row className="offering-row">
        <div className="offering-text_area">
          <h1 className="offering-title">{title}</h1>
          <div className="offering-description">{description}</div>
          <div className="offering-selected_container">
            <div
              className={
                selected
                  ? "offering-selected_icon-selected"
                  : "offering-selected_icon"
              }
            >
              {selected ? "Aktiverad" : "Använd erbjudande"}
            </div>
          </div>
          <div className="offering-avaible-date">{cleanDateTime(endDate)}</div>
        </div>
      </Row>
    </div>
  );
};

export default OfferTile;
