import React from "react";

import "../webparts/styles/InfoPanel.css";

interface Props {
  name: string;
  onClick: () => Promise<void> | void;
}

const ModalBackButton: React.FC<Props> = ({ name, onClick }) => {
  return (
    <div className="custom-modal-back-button" onClick={onClick}>
			<div className="custom-modal-back-button-text">
    	  {name}
			</div>
    </div>
  );
};

export default ModalBackButton;
