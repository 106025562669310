import { GlobalAppsettings } from '../../backbone/GlobalSettings';
import { GetPrice } from '../types/GetPrice';

export class GetPriceApi
{
    public static async getPriceRequest(
        
            userId: string | null,
            pickUpTime: string,
            pickup: {
              title: string | null,
              lat: string | null,
              long: string | null,
              GooglePlaceId: string
            },
            dropOff: {
              title: string | null,
              lat: string | null,
              long: string | null,
              GooglePlaceId: string
            },
            numberOfPassengers: number | null,
            via: [
              {
                title: string | null,
                lat: string | null,
                long: string | null,
                GooglePlaceId: string | null
              }
            ] | null,
            carType: number,
            extraServices: [
              string | null
            ] | null,
            message: string | null,
            pickupTimeASAP: boolean | null,
            offeringId: string | null       
    )
    {
        const request = {
            userId : userId,
            pickUpTime: pickUpTime,
            pickup: pickup,
            dropOff: dropOff,
            numberOfPassengers: numberOfPassengers,
            via: via,
            carType: carType,
            extraServices: extraServices,
            message: message,
            pickupTimeASAP: pickupTimeASAP,
            offeringId: offeringId
        }

        const response = await fetch(GlobalAppsettings.BackEndBaseUrl + 'api/booking/GetPrice',
        GlobalAppsettings.GetPostInit(request))

        const data = (await response.json()) as GetPrice

        return data
    }
}