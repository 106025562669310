import React, { Component, useReducer } from 'react';
import { Container, Button, Fade, Row, Col } from 'reactstrap';

import * as App from 'pubsub-js';
import { LanguageContext, Language } from '../backbone/Language';
import { GlobalAppsettings } from '../backbone/GlobalSettings';
import { Loading } from '../components/base/Loading';
import { InfoPanel } from '../panels/InfoPanel';
import { ContentPanel } from '../panels/ContentPanel';

export interface GlobalProps {

}

export interface GlobalState {
    loading: boolean
    locale: string
}

export class Global extends React.Component<GlobalProps,GlobalState> {
  static displayName = Global.name;
  static contextType = LanguageContext;

  constructor(props:GlobalProps) {
    super(props);

    this.state= {
      loading: false,
      locale: Language.GetLocale()
    }

  }

  componentDidMount() {
      
  }

  render () {

    return ( <React.Fragment>
    <LanguageContext.Consumer>
    {lang => <React.Fragment>
      
      <div id="TaG.web.flow"/>

      <InfoPanel title={lang.Text('Global.test')} cancelButtonLabel={lang.Text('btn.close')} 
          modalPanelOpen="panel.open"
          position="modal-panel-right">
          <div className="yc-panel-xl">
          <Container>
            <Loading style={{height: '400px'}} loading={true}>
            <Row>
              <Col>
                
              </Col>
            </Row>      
            </Loading>
          </Container>
          </div>
      </InfoPanel>


    </React.Fragment>}
    </LanguageContext.Consumer> 
    </React.Fragment>);
  }
}
