import React, { useEffect, useState, useCallback } from "react";
import App from "pubsub-js";
import { LanguageContext } from "../../backbone/Language";
import { LoginComponent } from "./LoginComponent";
import {
  BookingReceiptData,
  CreateBookingRequest,
} from "../types/CreateBookingRequest";
import {
  TaxinetBookingValidation,
  TaxinetBookingValidationResponse,
} from "../types/TaxinetBookingValidation";

import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { BonusRequest, BonusResponse } from "../types/Bonus";
import NetsPaymentComponent from "./NetsPaymentComponent";
import { GlobalAppsettings } from "../../backbone/GlobalSettings";
import { UserProfileResult } from "../types/UserProfile";
import { UserApi } from "../api/GetUserApi";
import { BonusApi } from "../api/BonusApi";
import { BookingApi } from "../api/BookingApi";
import "../styles/BookingReceipt.css";
import { Loading } from "../../components/base/Loading";
import { CreateBookingResponse } from "../types/CreateBookingResponse";
import { getFocusedRouteNameFromRoute } from "@react-navigation/native";

const BookingReceiptComponent = () => {
  const [booking, setBooking] = useState<BookingReceiptData>();
  const [createBookingReq, setCreateBookingReq] =
    useState<CreateBookingRequest>();
  const [user, setUser] = useState<string>();
  const [paymentId, setPaymentId] = useState<string>("");
  const [extraServices, setExtraServices] = useState<string[]>([]);
  const [offeringTitle, setOfferingTitle] = useState<string>("");
  const [nrOfPersons, setNrOfPersons] = useState<string>("");
  const [preliminaryIsOpen, setPreliminaryIsOpen] = useState<boolean>(false);
  const [bookingClosedIsOpen, setBookingClosedIsOpen] =
    useState<boolean>(false);
  const [confirmBookingIsOpen, setConfirmBookingIsOpen] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pickupTime, setPickupTime] = useState<string>("");
  const [pickupASAP, setPickupASAP] = useState<boolean>(false);
  const [bookingSent, setbookingSent] = useState<boolean>(false);
  const [skipPayment, setSkipPayment] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [bonusPoints, setBonusPoints] = useState<number>(0);
  const [showBonus, setShowBonus] = useState<boolean>(false);
  const [bonusDisabled, setBonusDisabled] = useState<boolean>(false);
  const [basePrice, setBasePrice] = useState<number>(0);
  const [bookingId, setBookingId] = useState<string>("");
  const [originalPrice, setOriginalPrice] = useState<number>(0);
  const [showOriginalPrice, setShowOriginalPrice] = useState<boolean>(false);
  const [showExtraServiceCost, setShowExtraServiceCost] =
    useState<boolean>(false);
  const [showAirportFee, setShowAirportFee] = useState<boolean>(false);
  const [originShowOriginalPrice, setOriginShowOriginalPrice] =
    useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");
  const [airportFee, setAirportFee] = useState<number>(0);
  const [bookingButtonDisabled, setBookingButtonDisabled] =
    useState<boolean>(false);

  const init = () => {
    App.subscribe("bookingReceiptInCarPayment", (message: any, data: any) =>
      open(message, data)
    );
  };

  const open = (message: "", data: BookingReceiptData) => {
    if (data !== undefined) {
      if (!data.data) return;
      if (data.data.pickUpTime === undefined) return;
      if (data.data.pickupTimeASAP === undefined) return;
      setBooking(data);
      setCreateBookingReq(data.data);
      setExtraServices(data.extraServiceNames);
      setOfferingTitle(data.offeringTitle);
      setNrOfPersons(data.numberOfPersons);
      setPickupTime(data.data.pickUpTime);
      setPickupASAP(data.data.pickupTimeASAP);
      setbookingSent(false);
      setSelected(false);
      setOriginalPrice(data.originalPrice);
      setShowOriginalPrice(data.showOriginalPrice);
      setOriginShowOriginalPrice(data.showOriginalPrice);
      setUserId(data.userId);

      if (data.extraServiceNames.length > 0) {
        setShowExtraServiceCost(true);
      }

      if (data.priceSummaryData.airportFee != 0) {
        setAirportFee(data.priceSummaryData.airportFee);
        setShowAirportFee(true);
      }

      // if (data.paymentId != null) {
      //   setPaymentId(data.paymentId);
      // }

      if (data.price != undefined) {
        setBasePrice(data.price);
        getBonus(data.userId, data.price);
      }

      App.publish("bookingReceiptModal", null);
    }
  };

  // const userHandler = () => {
  //   fetch(
  //     GlobalAppsettings.BackEndBaseUrl + "api/profile/CurrentProfile",
  //     GlobalAppsettings.GetPostInit(null)
  //   )
  //     .then((response) => response.json() as Promise<UserProfileResult>)
  //     .then((data) => {
  //       if (data.success) {
  //       } else {
  //       }
  //     });
  // };

  // const userProfileHandler = async () => {
  //   const profileResult = await UserApi.getCurrentProfile();
  // };

  useEffect(() => {
    init();
  }, []);

  const getBonus = async (userId: string, cost: number) => {
    var request: BonusRequest = {
      userId: userId,
      showAll: false,
    };

    var bonus = await BonusApi.getBonusData(request);

    //Got no bonus or cost is 0
    if (bonus.data.bonusTickets.length < 1 || cost == 0) {
      setShowBonus(false);
    } else {
      // calculate bonus
      var ticketBonusPoints: number = 0;
      var ticketConsumedPoints: number = 0;

      bonus.data.bonusTickets.forEach((ticket) => {
        ticketBonusPoints += ticket.bonusPoints;
        ticketConsumedPoints += ticket.consumedPoints;
      });

      var bonusPoints = ticketBonusPoints - ticketConsumedPoints;
      const administrationFee =
        booking?.priceSummaryData.administrationFee || 0;
      if (bonusPoints > cost + administrationFee) {
        bonusPoints = cost + administrationFee;
      }

      setBonusPoints(bonusPoints);
      setShowBonus(true);
    }
  };

  const openNetsPaymentComponent = async () => {
    setIsLoading(true);

    var paymentID: string = paymentId;
    var skipPaymentOk: boolean = skipPayment;


    if (!bookingSent) {
      await fetch(
        GlobalAppsettings.BackEndBaseUrl + "api/booking/CreateBooking",
        GlobalAppsettings.GetPostInit(createBookingReq)
      )
        .then((response) => {
          return response.json() as Promise<CreateBookingResponse>;
        })
        .then((data) => {
          if (data.success) {
            // addBookingId
            var updBookingReq = createBookingReq;
            if (updBookingReq == undefined) return;
            updBookingReq.bookingId = data.booking.id;
            setCreateBookingReq(updBookingReq);

            if (data.skipPayment) {
              skipPaymentOk = true;
              // paymentID = "";
              // setPaymentId("");
              setSkipPayment(true);
              setbookingSent(true);
            } else if (!data.paymentId) {
              console.error("Error: Check output from create-payment");
              return;
            } else {
              paymentID = data.paymentId;
              setPaymentId(data.paymentId);
              setbookingSent(true);
            }
          } else if (!data.success && data.skipPayment) {
            skipPaymentOk = true;
            // paymentID = "";
            // setPaymentId("");
            setSkipPayment(true);
            setbookingSent(true);
          } else {
            setIsLoading(false);
            return;
          }
        })
        .catch(() => {
          setIsLoading(false);
          return;
        });
    } else {
      await fetch(
        GlobalAppsettings.BackEndBaseUrl + "api/booking/UpdateBooking",
        GlobalAppsettings.GetPostInit(createBookingReq)
      )
        .then((response) => {
          return response.json() as Promise<CreateBookingResponse>;
        })
        .then((data) => {
          if (data.success) {
            if (data.skipPayment) {
              skipPaymentOk = true;
              // paymentID = "";
              // setPaymentId("");
              setSkipPayment(true);
              setbookingSent(true);
            } else if (!data.paymentId) {
              return;
            } else {
              paymentID = data.paymentId;
              setPaymentId(data.paymentId);
              setbookingSent(true);
            }
          } else if (!data.success && data.skipPayment) {
            skipPaymentOk = true;
            // paymentID = "";
            // setPaymentId("");
            setSkipPayment(true);
            setbookingSent(true);
          } else {
            setIsLoading(false);
            return;
          }
        })
        .catch(() => {
          setIsLoading(false);
          return;
        });
    }
    //Validate if u can do booking
    var request = {
      pickupASAP: pickupASAP,
      pickupTime: pickupTime,
    } as TaxinetBookingValidation;
    const bookingValidation = await BookingApi.CheckTaxinetBookingStatus(
      request
    );
    setAlertMessage(bookingValidation.statusMessage);

    if (bookingValidation.bookingStatus === "Preliminary") {
      setConfirmBookingIsOpen(false);
      setPreliminaryIsOpen(true);
      setIsLoading(false);
      return;
    }

    if (bookingValidation.bookingStatus === "Closed") {
      setConfirmBookingIsOpen(false);
      setBookingClosedIsOpen(true);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);

    let data = { paymentId: paymentID };

    // if(skipPaymentOk === true)
    // {
    //   window.location = "https://taxi-gbg-homepage.webflow.io/?screen=mypages" as any; //DEV
    //   // window.location = "https://www.taxigoteborg.se/?screen=mypages" as any; //PROD
    // }
    // else
    // {
    //   App.publish("netsPayment", data);
    // }
  };

  const goToNets = () => {
    setPreliminaryIsOpen(false);
    let data = { paymentId: paymentId };

    // if(skipPayment === true)
    // {
    //   window.location = "https://taxi-gbg-homepage.webflow.io/?screen=mypages" as any; //DEV
    //   // window.location = "https://www.taxigoteborg.se/?screen=mypages" as any; //PROD
    // }
    // else
    // {
    //   App.publish("netsPayment", data);
    // }

    // App.publish("netsPayment", data);
  };

  const confirmBookingHandler = async () => {
    setBookingButtonDisabled(true);

    //Validate if u can do booking
    var request = {
      pickupASAP: pickupASAP,
      pickupTime: pickupTime,
    } as TaxinetBookingValidation;
    const bookingValidation = await BookingApi.CheckTaxinetBookingStatus(
      request
    );
    setAlertMessage(bookingValidation.statusMessage);

    if (bookingValidation.bookingStatus === "Closed") {
      setConfirmBookingIsOpen(false);
      setBookingClosedIsOpen(true);
      setBookingButtonDisabled(false);
      setIsLoading(false);
      return;
    } else {
      let success: boolean = false;

      setPreliminaryIsOpen(false);
      setBookingButtonDisabled(true);

      await fetch(
        GlobalAppsettings.BackEndBaseUrl +
          "api/booking/CreateBookingInCarPayment",
        GlobalAppsettings.GetPostInit(createBookingReq)
      )
        .then((response) => {
          return response.json() as Promise<CreateBookingResponse>;
        })
        .then((data) => {
          if (data.success) {
            success = true;
          } else {
            setIsLoading(false);
            setBookingButtonDisabled(false);
            return;
          }
        })
        .catch(() => {
          setIsLoading(false);
          setBookingButtonDisabled(false);
          return;
        });

      if (success) {
        window.location =
          // "https://taxi-gbg-homepage.webflow.io/?screen=mypages" as any; //DEV
        window.location = "https://www.taxigoteborg.se/?screen=mypages" as any; //PROD
      }
    }
  };

  const abortBookingHandler = () => {
    setConfirmBookingIsOpen(false);
    setBookingButtonDisabled(false);
  };

  const translateToSwe = (month: string) => {
    if (month === "01") return "januari";
    if (month === "02") return "februari";
    if (month === "03") return "mars";
    if (month === "04") return "april";
    if (month === "05") return "maj";
    if (month === "06") return "juni";
    if (month === "07") return "juli";
    if (month === "08") return "augusti";
    if (month === "09") return "september";
    if (month === "10") return "oktober";
    if (month === "11") return "november";
    if (month === "12") return "december";
    else {
      return month;
    }
  };

  const cleanDateTime = () => {
    if (!booking?.data?.pickUpTime) return;

    const pickuptime: string = booking?.data?.pickUpTime;

    const [date, time] = pickuptime.split("T");

    const [hour, minute] = time.split(":");
    const [, month, day] = date.split("-");

    const monthString = translateToSwe(month);

    return `${day} ${monthString} ${hour}:${minute}`;
  };

  const activateBonus = () => {
    if (!selected) {
      setSelected(true);

      var updBookingReq = createBookingReq;
      var updBooking = booking;
      if (updBookingReq == undefined) return;
      if (updBooking == undefined) return;
      updBookingReq.bonusPoints = bonusPoints;
      updBooking.price = basePrice - bonusPoints;

      setBooking(updBooking);
      setShowOriginalPrice(true);
    } else {
      setSelected(false);

      var updBookingReq = createBookingReq;
      var updBooking = booking;
      if (updBookingReq == undefined) return;
      if (updBooking == undefined) return;
      updBookingReq.bonusPoints = 0;
      updBooking.price = basePrice;

      setBooking(updBooking);
      setShowOriginalPrice(originShowOriginalPrice);
    }
  };

  const renderBonusContainer = () => {
    if (showBonus) {
      return (
        <div
          className={
            selected
              ? "receipt-bonus-container-selected"
              : "receipt-bonus-container"
          }
        >
          <div
            className="receipt-bonus-card"
            onClick={isLoading ? undefined : () => activateBonus()}
          >
            <div className="receipt-bonus-title">Bonus</div>
            <div className="receipt-bonus-description-container">
              <div className="receipt-bonus-description">
                Aktivera resebonus på
              </div>
              <div className="receipt-bonus-description-cash">
                {bonusCashString()}
              </div>
            </div>
            <div className="receipt-bonus-button-container">
              <div
                className={
                  selected
                    ? "receipt-bonus-button-selected"
                    : "receipt-bonus-button"
                }
              >
                {selected ? "Aktiverad" : "Använd bonus"}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return;
    }
  };

  const bonusCashString = () => {
    const cashString: string = bonusPoints + " " + "kr";
    return cashString;
  };

  const renderBooking = () => {
    const discount = (booking?.price || 0) - originalPrice;
    return (
      <div data-back_state="cancel_booking">
        <div className="booking-receipt-container">
          <div
            className="receipt-Title"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2 style={{}}>Sammanställning</h2>
          </div>
          <div>
            <div>
              <h5 className="receipt-information-title">Tid för avresa</h5>
              <h4
                className="receipt-information-details"
                style={{ fontWeight: "normal" }}
              >
                {cleanDateTime()}
              </h4>
            </div>
            <div>
              <h5 className="receipt-information-title">Från</h5>
              <h4
                className="receipt-information-details"
                style={{ fontWeight: "normal" }}
              >
                {booking?.data?.pickup?.title}
              </h4>
            </div>
            <div>
              <h5 className="receipt-information-title">Till</h5>
              <h4
                className="receipt-information-details"
                style={{ fontWeight: "normal" }}
              >
                {booking?.data?.dropOff?.title}
              </h4>
            </div>
            <div>
              <h5 className="receipt-information-title">Antal passagerare</h5>
              <h4
                className="receipt-information-details"
                style={{ fontWeight: "normal" }}
              >
                {booking?.numberOfPersons}
              </h4>
            </div>
            <div
              className={
                booking?.data?.message !== ""
                  ? "receipt-details-exist-true"
                  : "receipt-details-exist-false"
              }
            >
              <h5 className="receipt-information-title">Meddelande</h5>
              <h4
                className="receipt-information-details"
                style={{ fontWeight: "normal" }}
              >
                {booking?.data?.message}
              </h4>
            </div>
            <div
              className={
                extraServices.length > 0
                  ? "receipt-details-exist-true"
                  : "receipt-details-exist-false"
              }
            >
              <h5 className="receipt-information-title">Tillval</h5>
              {extraServices.map((item, i) => (
                <h4
                  className="receipt-information-details"
                  style={{ fontWeight: "normal" }}
                >
                  {item}
                </h4>
              ))}
              {/* <h4
                  className="receipt-information-details"
                  style={{ fontWeight: "normal" }}
                >
                  1-4 personer
                </h4> */}
            </div>
            {/* <div
              className={
                offeringTitle !== ""
                  ? "receipt-details-exist-true"
                  : "receipt-details-exist-false"
              }
            >
              <h5 className="receipt-information-title">Erbjudande</h5>
              <h4
                className="receipt-information-details"
                style={{ fontWeight: "normal" }}
              >
                {offeringTitle}
              </h4>
            </div> */}
          </div>
          <div>
            {renderBonusContainer()}
            {/* <div
              className={
                selected
                  ? "receipt-bonus-container-selected"
                  : "receipt-bonus-container"
              }
            >
              <div
                className="receipt-bonus-card"
                onClick={isLoading ? undefined : () => activateBonus()}
              >
                <div className="receipt-bonus-title">Bonus</div>
                <div className="receipt-bonus-description-container">
                  <div className="receipt-bonus-description">
                    Vill du sänka resekostnaden med
                  </div>
                  <div className="receipt-bonus-description-cash">
                    {bonusCashString()}
                  </div>
                </div>
                <div className="receipt-bonus-button-container">
                  <div
                    className={
                      selected
                        ? "receipt-bonus-button-selected"
                        : "receipt-bonus-button"
                    }
                  >
                    {selected ? "Aktiverad" : "Använd bonus"}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <hr className="receipt-line-seperator" />

        <div className="booking-receipt-container">
          <div className="receipt-administration-row">
            <h6>Resekostnad:</h6>
            <h6>{booking?.priceSummaryData.travelPrice}</h6>
          </div>
          {showExtraServiceCost && (
            <div className="receipt-administration-row">
              <h6>Tillval:</h6>
              <h6>{booking?.priceSummaryData.extraServicesPrice}</h6>
            </div>
          )}
          <div className="receipt-administration-row">
            <h6>Administrationsavgift:</h6>
            <h6>{booking?.priceSummaryData.administrationFee}</h6>
          </div>
          {showAirportFee && (
            <div className="receipt-administration-row">
              <h6>Tillägg LDV:</h6>
              <h6>{booking?.priceSummaryData.airportFee}</h6>
            </div>
          )}
          {showOriginalPrice && (
            <div className="receipt-administration-row">
              <h6>Rabatt</h6>
              <h6>-{originalPrice}</h6>
            </div>
          )}
        </div>

        <hr className="receipt-line-seperator" />

        <Form className="m-3">
          <Row className="m-4">
            <Col className="fixed-price-col">
              <div>
                <div className="fixed-price-container">
                  <p className="fixed-price-container-text">Fastpris:</p>
                  <h1 className="fixed-price-container-price">
                    {booking?.price || 0} kr
                  </h1>
                </div>
                <div
                  className={
                    showOriginalPrice
                      ? "original-price-container"
                      : "original-price-container-hide"
                  }
                >
                  <div className="original-price">{originalPrice} kr</div>
                </div>
              </div>
            </Col>
            <Col className="p-3 d-flex flex-row-reverse">
              <Button
                className="booking-receipt-component-pay-button"
                color="dark"
                size="lg"
                //   onClick={() => this.bookAndPay()}
                onClick={() => setConfirmBookingIsOpen(true)}
              >
                <Loading loading={isLoading} white>
                  Boka
                </Loading>
              </Button>
            </Col>
          </Row>
          <NetsPaymentComponent netsId={paymentId} />
        </Form>
        <Modal isOpen={preliminaryIsOpen}>
          <ModalBody>{alertMessage}</ModalBody>
          <ModalFooter>
            <Button
              className="receipt-component-ok-button"
              color="primary"
              onClick={() => setConfirmBookingIsOpen(true)}
            >
              Boka
            </Button>
            <Button
              className="receipt-component-cancel-button"
              onClick={() => setPreliminaryIsOpen(false)}
            >
              Avbryt
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={bookingClosedIsOpen}>
          <ModalBody>"{alertMessage}"</ModalBody>
          <ModalFooter>
            <Button
              className="receipt-component-cancel-button"
              onClick={() => setBookingClosedIsOpen(false)}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={confirmBookingIsOpen}>
          <ModalBody className="receipt-confirm-booking-modal-body">
            Bokning med avresa {cleanDateTime()}. Du har väll inte missat att
            betalning nu sker i bilen?
          </ModalBody>
          <ModalFooter className="receipt-confirm-booking-modal-footer">
            <Button
              className="receipt-confirm-booking-component-ok-button"
              color="primary"
              onClick={() => confirmBookingHandler()}
              disabled={bookingButtonDisabled}
            >
              Boka taxi
            </Button>
            <Button
              className="receipt-confirm-booking-component-cancel-button"
              onClick={() => abortBookingHandler()}
              disabled={bookingButtonDisabled}
            >
              Avbryt
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  return (
    <React.Fragment>
      <LanguageContext.Consumer>
        {(lang) => (
          <div>
            <LoginComponent modalOpen="bookingReceiptModal">
              {renderBooking()}
            </LoginComponent>
          </div>
        )}
      </LanguageContext.Consumer>
    </React.Fragment>
  );
};

export default BookingReceiptComponent;
